import { Box, Collapse, Grid, Typography, styled } from "@mui/material"
import { BUILDING_TYPES_POTENTIAL } from "../../../constants/potentialTab";
import { PotentialTabResult } from "../../sharedComponents/PotentialTab";
import CustomBarChart from "../../charts/d3/CustomBarChart";

const Title = styled(Typography)({
    font: "normal normal bold 18px/34px Barlow",
    color: "#66B654",
})

function GraphResultContainer({ potentialTabState, data }) {

    return (
        Object.entries(potentialTabState).map(([key, values]) => (
            <Collapse in={values.active} key={key}>
                <Box sx={{ boxShadow: "0px 0px 6px #00000029", paddingRight: 4, paddingBottom: 2, marginBottom: 4 }}>
                    <Grid container spacing={1}>
                        <Grid item xs={4} >
                            <Box padding={2} height="100%" >
                                <Graph values={data[key]} />
                            </Box>
                        </Grid>
                        <Grid item xs={8}>
                            <Grid container spacing={1} marginBottom={3} height="100%">
                                <Grid item xs={12} >
                                    <Title variant="body1" textAlign="center" marginLeft="-45%">{values.value}</Title>
                                </Grid>
                                <p className="description-text" style={{marginTop: "-4px"}}>{values.extraText}</p>
                                <Grid container spacing={1}>
                                    <PotentialResults values={data[key]} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Collapse>
        ))
    )
}

export default GraphResultContainer

function PotentialResults({ values }) {
    return Object.keys(BUILDING_TYPES_POTENTIAL).map((buildingType) => {
        // filter value
        let value = values?.filter((item) => item.building_type === buildingType) || []
        if (value.length < 1) return <></>

        value = value[0]

        // temp fix for 'pack' of 'current situation' -> set its values to 0 (zero)
        if(value?.pack === "current situation"){
            value = {
                ...value,
                total_subsidies_sum: 0,
                total_investment_cost_sum: 0,
                yearly_savings_sum: 0,
                co2_savings_sum: 0,
                potential: 0,
            }
        }

        return (
            <Grid item xs={3} key={buildingType}>
                <PotentialTabResult contentObject={value} buildingType={value.building_type} />
            </Grid>
        )
    })
}

function Graph({ values }) {
    // console.log(scene, values)

    /* 
    {
        building_type: "APT",

        total_subsidies_sum: 0, // result subsidies
        total_investment_cost_sum: 109871510.1, // in result
        yearly_savings_sum: 964923.4453, // result
        co2_savings_sum: 4947.136548, // result and graph -> x-value -> also determinw the width
        potential: 545.256877, // graph -> y-axis
        
        pack: 'window_wallExt_roofL_HPAW_PVmax', // in result as param

        number_of_buildings: 200, // ignore
        total_co2_sum: -180178.3478, //ignore
        tco_sum: 2697460.224, //ignore
        pv_production_sum: 6432625.08, // //ignore
        delta_tco: 2697460.224, //ignore
        delta_co2: 4947.136548, // //ignore
    },
    */

   // sort values before ploting
   const formattedValues = []
    Object.keys(BUILDING_TYPES_POTENTIAL).forEach((buildingType) => {
        // filter value
        let value = values?.filter((item) => item.building_type === buildingType) || []
        if (value.length === 1){
            formattedValues.push(value[0])
        } 
    })

    let xAxisValues = []
    let yAxisValues = []

    formattedValues.forEach(({ potential, co2_savings_sum }) => {
        xAxisValues.push(co2_savings_sum)
        yAxisValues.push(Math.abs(potential))
    });

    return <>
        <CustomBarChart
            data={formattedValues}
            xAxisValues={xAxisValues}
            yAxisValues={yAxisValues}
        />
    </>
}