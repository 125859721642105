import { useEffect } from "react";
import {useSelector} from "react-redux";
import {MAP_LAYERS} from "../../../constants/map";
import {SIMULATION_CLICK_STATE} from "../../../constants/simulation";

const SimulationComparison = ({
        mymap,
        mapView,
        mapData,
        streetsSimulatedHeatStateLayer,
        streetsCurrentHeatStateLayer,
        sectsSimulatedHeatStateLayer,
        sectsCurrentHeatStateLayer,
        streetsInvestmentLayer,
        sectsInvestmentLayer,
        streetsPotentialLayer,
        streetsSearchDirectionLayer
    }) => {

    const selectedStreetLayer = useSelector((state) => state.map.selectedStreetLayer);
    const simulationViewState = useSelector((state) => state.simulation.clickState);
    const selectedSectorLayer = useSelector((state) => state.map.selectedSectorLayer);
    let x = 0;
    let y = 0;

    useEffect(() => {
        if (mymap) {            
            // if (mymap.hasLayer(streetsSearchDirectionLayer)) {
            //     mymap.removeLayer(streetsSearchDirectionLayer);
            // }
            if (mymap.hasLayer(streetsPotentialLayer)) {
                mymap.removeLayer(streetsPotentialLayer);
            }
            if (simulationViewState === SIMULATION_CLICK_STATE.BEFORE) {
                if (
                    selectedStreetLayer === MAP_LAYERS.CURRENT_HEAT_STATE_STREETS && streetsCurrentHeatStateLayer
                ) {
                    if (mymap.hasLayer(streetsCurrentHeatStateLayer)) {
                        mymap.removeLayer(streetsCurrentHeatStateLayer);
                    }
                    if (mymap.hasLayer(streetsSimulatedHeatStateLayer)) {
                        mymap.removeLayer(streetsSimulatedHeatStateLayer);
                    }
                    if (mapView.zoom < 14) {
                        streetsCurrentHeatStateLayer.setStyle({
                            weight: 2,
                        })
                    }
                    streetsCurrentHeatStateLayer.addTo(mymap);
                    streetsCurrentHeatStateLayer.bringToFront();
                }
                if (
                    selectedSectorLayer === MAP_LAYERS.CURRENT_HEAT_STATE_SECTORS &&
                    sectsCurrentHeatStateLayer
                ) {
                    if (mymap.hasLayer(sectsCurrentHeatStateLayer)) {
                        mymap.removeLayer(sectsCurrentHeatStateLayer);
                    }
                    if (mymap.hasLayer(sectsSimulatedHeatStateLayer)) {
                        mymap.removeLayer(sectsSimulatedHeatStateLayer);
                    }
                    if (selectedStreetLayer.length > 0) {
                        sectsCurrentHeatStateLayer.setStyle({
                            fillOpacity: 0.2
                        })
                    }
                    sectsCurrentHeatStateLayer.addTo(mymap);
                    sectsCurrentHeatStateLayer.bringToBack();
                }
            } else {
                if (
                    selectedStreetLayer === MAP_LAYERS.CURRENT_HEAT_STATE_STREETS && streetsSimulatedHeatStateLayer
                ) {
                    if (mymap.hasLayer(streetsCurrentHeatStateLayer)) {
                        mymap.removeLayer(streetsCurrentHeatStateLayer);
                    }
                    if (mymap.hasLayer(streetsSimulatedHeatStateLayer)) {
                        mymap.removeLayer(streetsSimulatedHeatStateLayer);
                    }
                    if (mapView.zoom < 14) {
                        streetsSimulatedHeatStateLayer.setStyle({
                            weight: 2,
                        })
                    }
                    streetsSimulatedHeatStateLayer.addTo(mymap);
                    streetsSimulatedHeatStateLayer.bringToFront();
                }
                if (
                    selectedSectorLayer === MAP_LAYERS.CURRENT_HEAT_STATE_SECTORS && sectsSimulatedHeatStateLayer
                ) {
                    if (mymap.hasLayer(sectsCurrentHeatStateLayer)) {
                        mymap.removeLayer(sectsCurrentHeatStateLayer);
                    }
                    if (mymap.hasLayer(sectsSimulatedHeatStateLayer)) {
                        mymap.removeLayer(sectsSimulatedHeatStateLayer);
                    }
                    if (selectedStreetLayer) {
                       sectsSimulatedHeatStateLayer.setStyle({
                            fillOpacity: 0.4,
                            zIndex: 0
                        })
                    }
                    sectsSimulatedHeatStateLayer.addTo(mymap);
                    sectsSimulatedHeatStateLayer.bringToBack();
                }
                if (
                    selectedStreetLayer === MAP_LAYERS.INVESTMENT_STREETS && streetsInvestmentLayer
                ) {
                    if (mymap.hasLayer(streetsInvestmentLayer)) {
                        mymap.removeLayer(streetsInvestmentLayer);
                    }
                    if (mapView.zoom < 14) {
                        streetsInvestmentLayer.setStyle({
                            weight: 2,
                        })
                    }
                    streetsInvestmentLayer.addTo(mymap);
                    streetsInvestmentLayer.bringToFront();
                }
                if (
                    selectedSectorLayer === MAP_LAYERS.INVESTMENT_SECTORS && sectsInvestmentLayer
                ) {
                    if (mymap.hasLayer(sectsInvestmentLayer)) {
                        mymap.removeLayer(sectsInvestmentLayer);
                    }
                    if (selectedStreetLayer.length > 0) {
                        sectsInvestmentLayer.setStyle({
                            fillOpacity: 0.2
                        })
                    }
                    sectsInvestmentLayer.addTo(mymap);
                    sectsInvestmentLayer.bringToBack();
                }
            }
            if (selectedStreetLayer === MAP_LAYERS.POTENTIAL_STREETS && streetsPotentialLayer && 
                selectedSectorLayer !== MAP_LAYERS.POTENTIAL_SECTORS) {
                if (mapView.zoom < 14) {
                    streetsPotentialLayer.setStyle({
                        weight: 2,
                    })
                    streetsPotentialLayer.addTo(mymap);
                    streetsPotentialLayer.bringToFront();
                }
            }
            if (
                selectedStreetLayer === MAP_LAYERS.INVESTMENT_STREETS && streetsInvestmentLayer
            ) {
                if (mymap.hasLayer(streetsInvestmentLayer)) {
                    mymap.removeLayer(streetsInvestmentLayer);
                }
                if (mapView.zoom < 14) {
                    streetsInvestmentLayer.setStyle({
                        weight: 2,
                    })
                }
                streetsInvestmentLayer.addTo(mymap);
                streetsInvestmentLayer.bringToFront();
            }
            if (
                    selectedSectorLayer === MAP_LAYERS.INVESTMENT_SECTORS && sectsInvestmentLayer
                ) {
                    if (mymap.hasLayer(sectsInvestmentLayer)) {
                        mymap.removeLayer(sectsInvestmentLayer);
                    }
                    if (selectedStreetLayer.length > 0) {
                        sectsInvestmentLayer.setStyle({
                            fillOpacity: 0.2
                        })
                    }
                    sectsInvestmentLayer.addTo(mymap);
                    sectsInvestmentLayer.bringToBack();
                }
            if (selectedStreetLayer === MAP_LAYERS.DIRECTION_STREETS && streetsSearchDirectionLayer) {
                if (mapView.zoom < 14) {
                    streetsSearchDirectionLayer.setStyle({
                        weight: 2,
                    })
                }
                streetsSearchDirectionLayer.addTo(mymap);
                streetsSearchDirectionLayer.bringToFront();
            }
        }
    }, [simulationViewState, sectsCurrentHeatStateLayer, streetsInvestmentLayer, streetsCurrentHeatStateLayer, streetsPotentialLayer, sectsInvestmentLayer,
        streetsSimulatedHeatStateLayer, sectsSimulatedHeatStateLayer, streetsSearchDirectionLayer, //mymap
    ]);

    return null;
}

export default SimulationComparison