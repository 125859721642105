import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import LandingPage from "./landingPage";
import Containers from "./containers";
import { connect } from "react-redux";
import { CookiesProvider } from "react-cookie";
import { getContentByModuleLocation } from "../../redux/actions/content";


function Index(props) {
  const [bannerContent, setBannerContent] = useState(undefined);
  const [containerContent, setContainerContent] = useState(undefined);
  const [faqContent, setFAQContent] = useState(undefined);
  const [faqTitle, setFaqTitle] = useState(undefined);

  useEffect(() => {
    //Load data in local state
    async function fetchContent() {
      const data = await props.getContentByModuleLocation("landingPage");
      const { banner, faq, sectionFAQ, ...sections } = data;
      setBannerContent(banner);
      setContainerContent(sections);
      setFAQContent(faq.attributes.QnA);
      setFaqTitle(sectionFAQ.attributes.title);
    }

    fetchContent();
  }, []);
  if (bannerContent)
    return (
      <CookiesProvider>
         <Grid container style={{ height: '100%' }}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
               <LandingPage lpContent={bannerContent}/>
               <Containers containerContent={containerContent}/>
               {/*<FaqPart */}
               {/*   faqContent={faqContent}*/}
               {/*   title={faqTitle}              */}
               {/*/>*/}
            </Grid>
         </Grid>
      </CookiesProvider>
    );
  else return <></>;
}

const mapStateToProps = ({ content }) => ({ content });

export default connect(mapStateToProps, { getContentByModuleLocation })(Index);
