import {
    SEARCH_LEVEL, SELECTED_AREA,
    SELECTED_STREET_LAYER,
  SELECTED_SECTOR_LAYER,
  CHANGE_SELECTED_STATISTICAL_SECTOR, CHANGE_STATISTICAL_SECTOR_SELECTION_STATE, SET_ALERT
} from "../constants/constants";
import {DEFAULT_SELECTED_AREA } from "../../constants/map";

export const setSelectedArea = (value = {}) => {
    return {
        type: SELECTED_AREA,
        payload: {
            ...DEFAULT_SELECTED_AREA,
            ...value
        }
    }
}

export const setSearchLevel = (value = "") => {
    return {
        type: SEARCH_LEVEL,
        payload: value
    }
}

export const setSelectedStreetLayer = (value) => {
  return {
    type: SELECTED_STREET_LAYER,
    payload: value,
  };
};

export const setSelectedSectorLayer = (value) => {
  return {
    type: SELECTED_SECTOR_LAYER,
    payload: value,
  };
};

export const setSelectedStatisticalSector = (value) => {
  return {
    type: CHANGE_SELECTED_STATISTICAL_SECTOR,
    payload: value,
  };
};

export const setStatisticalSectorSelectionState = (value) => {
  return {
    type: CHANGE_STATISTICAL_SECTOR_SELECTION_STATE,
    payload: value,
  };
};

export const setStatisticalSectorAlert = (isShow, msg, type = 'info') => {
  return {
    type: SET_ALERT,
    payload: {
      showAlert: isShow,
      alertMsg: msg,
      alertType: type,
    }
  }
}