import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import Grid from '@material-ui/core/Grid';
import {HashLink} from 'react-router-hash-link';
import measure from '../../assets/used/mesure.svg';
import house from '../../assets/used/house.svg';
import rightline from "../../assets/used/line-image.svg";
import antwerpCityscape from "../../assets/used/antwerp-belgium-cityscape.png";
import antwerpCityscapeLogo from "../../assets/used/antwerpCityscapeLogo.png";
import footerImg2 from "../../assets/used/footer-img-2.png";
import footerImg2Top from "../../assets/used/footer-img-2-top.png";
import footerImg3 from "../../assets/used/footer-img-3.png";
import footerImg3Top from "../../assets/used/footer-img-3-top.png";
import { Box, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

function Containers({ containerContent }) {
  const [collectief, setCollectief] = useState([]);
  const [imgs, setImg] = useState({});
  const theme = useTheme()

  console.log(theme.breakpoints.between("xs", "sm"))

  const isMobileAndTab = useMediaQuery(theme.breakpoints.between("xs", "md"))
  const isTab = useMediaQuery("(max-width: 900px)")
  const isLaptop = useMediaQuery("(max-width: 1200px)")

  const getActiveImg = ({ attributes }) => {
    try {
      return Object.values(attributes.img).filter((img) => img.is_active)[0]
        .attribute_value;
    } catch (err) {
      console.log("error is ", err);
      return "";
    }
  };

    if (containerContent)
        return (
            <Grid container>
                <Grid container className="position-relative">
                    <Grid container className='pb-4 section2-lp'>
                        <Grid item xs={12} className="d-flex " style={{justifyContent: "flex-start !important"}}>
                            <h2 className="text-center container-heading my-5">
                                {containerContent?.section1?.attributes?.title}
                            </h2>
                        </Grid>
                        <Grid item xs={12} className="mb-0 d-flex justify-content-center align-content-center">
                            <p className="text-center container-content">
                                {containerContent?.section1?.attributes?.text}
                            </p>
                        </Grid>
                        <Grid item xs={12} className="mb-5 d-flex justify-content-center align-content-center">
                            <p className="text-center container-content">
                                {containerContent?.section1?.attributes?.text2}
                            </p>
                        </Grid>
                    </Grid>
                    
                    <Grid container className='pt-4 pb-4 conatiner-bg-grey'>
                        <Grid container className="section2-lp" justifyContent="center">
                            <Grid item xs={12} >
                                <img className="trace-right-line"
                                    src={rightline}
                                    alt={'rightline'}
                                />
                            </Grid>
                            <Grid item xs={12}>
                            <Grid container spacing={5} className="w-80">
                                <Grid item xs={12}>
                                    <h2 className="mb-3 container-heading" style={{ textAlign: "center" }}>
                                        {containerContent?.section1?.attributes?.subSection1Title}
                                    </h2>
                                    <p className="container-content center-text">
                                        {containerContent?.section1?.attributes?.subSection1Text}
                                    </p>
                                </Grid>
                                <Grid item xs={12} lg={11} xl={11}>
                                    <h3 className="mb-3 container-heading-2">
                                        {containerContent?.section1?.attributes?.subSection2Title}
                                    </h3>
                                    <p className="container-content" style={{paddingRight: "0%"}}>
                                        {containerContent?.section1?.attributes?.subSection2Text}
                                    </p>
                                </Grid>

                                <Grid item xs={12} className="d-flex justify-content-start align-content-center pb-0">
                                    <h2 className="container-heading-2">
                                        {containerContent?.section2?.attributes?.title}
                                    </h2>
                                </Grid>

                                <Grid item xs={12} className='pt-0 pb-0'>
                                    {/* <Stack direction="row" justifyContent="space-between">
                                        <Box>
                                            <p className="container-text">
                                                {containerContent?.section2?.attributes?.text1}
                                            </p>
                                            <p className="container-text">
                                                {containerContent?.section2?.attributes?.text2}
                                            </p>
                                        </Box>
                                        <img
                                            src={measure}
                                            alt="measure-img"
                                        />
                                    </Stack> */}
                                    <Grid container alignItems='flex-start' justifyContent="space-between">
                                        <Grid item xs={12} lg={6} >
                                            <p className="container-text">
                                                {containerContent?.section2?.attributes?.text1}
                                            </p>
                                            <p className="container-text">
                                                {containerContent?.section2?.attributes?.text2}
                                            </p>
                                        </Grid>
                                        <Grid item xs={12} lg={5} className="mb-3">
                                            <img
                                                src={measure}
                                                style={{width: "80%"}}
                                                alt="measure-img"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} xl={12}>
                                    <h3 className="container-heading-2">
                                        {containerContent?.section1?.attributes?.subSection3Title}
                                    </h3>
                                    <p className="container-content">
                                        {containerContent?.section1?.attributes?.subSection3Text}
                                    </p>
                                </Grid>
                                
                            </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    
                    <Grid container className='pt-4 pb-4'>
                        <div  className="section2-lp" style={{position: "relative"}}>

                            <Grid container>
                                <Grid item xs={12} lg={6} className="mb-3">
                                    <img
                                        className="house-img"
                                        src={house}
                                        alt="house-img"
                                    />
                                </Grid>

                                <Grid item xs={12} lg={6} className="mb-3 mt-5">
                                    <h2 className="container-heading">
                                        {containerContent?.section3?.attributes?.title}
                                    </h2>
                                    <p className="container-text">
                                        {containerContent?.section3?.attributes?.text1}
                                    </p>
                                    <p className="container-text">
                                        {containerContent?.section3?.attributes?.text2}
                                    </p>
                                    <p className="container-text">
                                        {containerContent?.section3?.attributes?.text3}
                                    </p>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    {/* </Grid> */}

                    <Grid container className='mt-5 pt-4 pb-4 conatiner-bg-grey'>
                        <Grid container className="section2-lp" justifyContent="center">
                            <Grid item xs={12}>
                                <h2 className="container-heading">
                                    {containerContent?.section4?.attributes?.title}
                                </h2>
                                {/* <p className="mt-4">
                                    {containerContent?.section4?.attributes?.text1}
                                </p>
                                <p>
                                    {containerContent?.section4?.attributes?.text2}
                                </p> */}
                            </Grid>
                            <Grid container >
                                <Grid container justifyContent='space-between' alignItems='center' className='mb-4' direction={isMobileAndTab ? 'column': 'inherit'}>
                                    <Grid item>
                                        <img src={antwerpCityscape} alt="Antwerp Cityscape" style={{height: isMobileAndTab ? "225px" : "inherit"}} />
                                        <Box position="relative">
                                            <img src={antwerpCityscapeLogo} alt="Antwerp Cityscape" className='example-top-image-right' style={{ height: isMobileAndTab ? "100px": 'inherit' }} />
                                        </Box>
                                    </Grid>
                                    <Grid item md={6} lg={7} xl={8}>
                                        <p>{containerContent?.section4?.attributes?.example1text1}</p>
                                        <ul class="green-list">
                                            <li><p style={{color: "#525252", margin: 0}}>{containerContent?.section4?.attributes?.example1ListItem1}</p></li>
                                            <li><p style={{color: "#525252", margin: 0}}>{containerContent?.section4?.attributes?.example1ListItem2}</p></li>
                                            <li><p style={{color: "#525252", margin: 0}}>{containerContent?.section4?.attributes?.example1ListItem3}</p></li>
                                        </ul>
                                        <p>{containerContent?.section4?.attributes?.example1Text2}</p>
                                    </Grid>
                                </Grid>
                                <Grid container justifyContent='space-between' alignItems='center' className='mb-4' direction={isMobileAndTab ? 'column-reverse': 'inherit'}>
                                    <Grid item md={6} lg={7} xl={8}>
                                        <p>{containerContent?.section4?.attributes?.example2text1}</p>
                                        <ul class="green-list">
                                            <li><p style={{color: "#525252", margin: 0}}>{containerContent?.section4?.attributes?.example2ListItem1}</p></li>
                                            <li><p style={{color: "#525252", margin: 0}}>{containerContent?.section4?.attributes?.example2ListItem2}</p></li>
                                            <li><p style={{color: "#525252", margin: 0}}>{containerContent?.section4?.attributes?.example2ListItem3}</p></li>
                                        </ul>
                                        <p>{containerContent?.section4?.attributes?.example2text2}</p>
                                        <p>{containerContent?.section4?.attributes?.example2text3}</p>
                                    </Grid>
                                    <Grid item className='ml-5'>
                                        <img src={footerImg2} alt="Antwerp Cityscape" style={{height: isMobileAndTab ? "225px" : "inherit"}}/>
                                        <Box position="relative">
                                            <img src={footerImg2Top} alt="Antwerp Cityscape" className='example-top-image-left' style={{ height: isMobileAndTab ? "100px": 'inherit' }} />
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid container justifyContent='space-between' alignItems='center' className='mb-4' direction={isMobileAndTab ? 'column': 'inherit'}>
                                    <Grid item>
                                        <img src={footerImg3} alt="Antwerp Cityscape" style={{height: isMobileAndTab ? "225px" : "inherit"}}/>
                                        <Box position="relative">
                                            <img src={footerImg3Top} alt="Antwerp Cityscape" className='example-top-image-right' style={{ height: isMobileAndTab ? "50px": 'inherit' }}/>
                                        </Box>
                                    </Grid>
                                    <Grid item md={6} lg={7} xl={8}>
                                    <p>{containerContent?.section4?.attributes?.example3text1}</p>
                                    <p>{containerContent?.section4?.attributes?.example3text2}</p>
                                    <p>{containerContent?.section4?.attributes?.example3text3}</p>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
              </Grid>
   );
   return <></>
}

const mapStateToProps = ({content, loader}) => ({content, loader})
export default connect(mapStateToProps, null)(Containers);
