import React from "react"
import { useSelector } from "react-redux";

import Grid from "@material-ui/core/Grid";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import CutomPaper from "../sharedComponents/cutomPaper";
import chartApiService from "../../services/chartApiService";
import TabPanelContainer from "../sharedComponents/TabPanelContainer";
import TabsInfoMessage from "../sharedComponents/TabsInfoMessage";
import { BUILDING_TYPES_ENG } from "../../constants/simulation";
import { RenovationKeyToValue } from "../../constants/renovation";
import ChartApiService from "../../services/chartApiService";

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#002E55",
        color: "white !important",
        fontSize: "12px !important",
        textTransform: "capitalize",
        borderRight: "1px solid white",

        "&:last-child": {
            borderRight: 0,
        }
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        border: "1px solid white",
        padding: "6px"
    },
    textAlign: "center"
}));

const StyledTableRow = styled(TableRow)(() => ({
    '&:nth-of-type(even)': {
        backgroundColor: "#F0F0F1",
    },
    '&:nth-of-type(odd)': {
        backgroundColor: "#CACDD1",
    },

}));


const primaryColumn = {
    "geboutype": "Gebouwtype",   // primary field
}

const secondaryColumns = {
    "name_y": "Renovatie",
    "total_investment_cost_sum": "Investering",
    "yearly_savings_sum": "Jaarlijkse energiekost",
    "heating_consumption_sum": "TAK",
    // "total_co2_sum_cur": "Efficiëntie (TAK/kWh) of (TAK/CO₂)",
    // "total_co2_sum": "Absolute CO₂-besparing",
    "ventilation_investment_cost_sum": "Investeringskost ventilatie",
    "roof_investment_cost_sum": "Investeringskost dakisolatie",
    "wall_investment_cost_sum": "Investeringskost gevelisolatie",
    "total_subsidies_sum": "Totaal subsidiebedrag",
    "tco_sum": "Total Cost of Ownership",
    "co2_abt_cost": "C0₂-abatement cost",
}

const dataSkelton = {
    "Rijwoning": [
        {
            "renovatie": "Huiding",
            "inverstring": "0",
            "jaarlijkse": "100",
            "tak": "3000",
            "efficientie": "",
            "absoluteCO2": "10",
        },
        {
            "renovatie": "Maatregel 1",
            "inverstring": "100",
            "jaarlijkse": "1500",
            "tak": "2900",
            "efficientie": "10",
            "absoluteCO2": "5",
        },
        {
            "renovatie": "Maatregel 1",
            "inverstring": "100",
            "jaarlijkse": "1500",
            "tak": "2900",
            "efficientie": "10",
            "absoluteCO2": "5",
        },
    ],
    "Open bebouwing": [
        {
            "renovatie": "Huiding",
            "inverstring": "0",
            "jaarlijkse": "100",
            "tak": "3000",
            "efficientie": "",
            "absoluteCO2": "10",
        },
        {
            "renovatie": "Maatregel 1",
            "inverstring": "100",
            "jaarlijkse": "1500",
            "tak": "2900",
            "efficientie": "10",
            "absoluteCO2": "5",
        },
    ],
}

const Investment = ({ handleTabData, data }) => {
    const { municipalityName = null } = useSelector(state => state?.map?.selectedArea)
    const { areaName: statisticalSectorArea, loading: sectorLoading } = useSelector(state => state?.statisticalSector)
    const [loading, setLoading] = React.useState(false)
    const [sectLoading, setSectLoading] = React.useState(false)

    React.useEffect(async () => {
        if (["", null, undefined].includes(municipalityName)) {
            handleTabData("investment", null)
            return
        }
        if (![null, undefined].includes(data?.municipality) && (statisticalSectorArea === null || data?.sector !== null)) return
        const finalData = {
            municipality: null,
            sector: null
        }
        setLoading(true)

        finalData.municipality = await chartApiService.getInvestmentDataOfMunicipality(municipalityName)
        if (statisticalSectorArea) {
            finalData.sector = await ChartApiService.getInvestmentDataOfMunicipality(statisticalSectorArea)
        }
        handleTabData("investment", finalData)
        setLoading(false)
    }, [municipalityName, data]);

    React.useEffect(async () => {
        if (municipalityName.length) {
            setSectLoading(true)
            const finalData = {
                municipality: null,
                sector: null
            }
            if (statisticalSectorArea) {
                finalData.sector = await ChartApiService.getInvestmentDataOfMunicipality(statisticalSectorArea)
            }
            handleTabData("investment", finalData)
            setSectLoading(false)
        }
    }, [statisticalSectorArea]);

    const Head = ({ primaryColumn, secondaryColumns }) => (
        <TableHead>
            {Object.values(primaryColumn).map((head) => (
                <StyledTableCell key={head}>{head}</StyledTableCell>
            ))}
            {Object.entries(secondaryColumns).map(([key, value]) => (
                <StyledTableCell key={key}>{value}</StyledTableCell>
            ))}
        </TableHead>
    )

    const Body = ({ chartData, secondaryColumns }) => (
        <TableBody>
            {Object.entries(chartData).map(([primaryValue, values]) => (
                <>
                    <StyledTableRow>
                        <StyledTableCell align="left" rowSpan={values?.length} sx={{ minWidth: 150 }}>{BUILDING_TYPES_ENG[primaryValue] || primaryValue}</StyledTableCell>
                        {
                            // only render first child from "values"
                            Object.keys(secondaryColumns).map((secondaryColumnKey) => {
                                let val = values?.[0][secondaryColumnKey]
                                val = typeof val === "number" ? Number(val).toLocaleString("es-ES", { minimumFractionDigits: 0 }) : val
                                if (typeof val === "undefined" || val === null) val = 0

                                return (
                                    <StyledTableCell
                                        key={secondaryColumnKey}
                                        sx={{ minWidth: secondaryColumnKey === "name_y" ? "200px" : "inherit" }}
                                    >{RenovationKeyToValue(val)}</StyledTableCell>
                                )
                            })
                        }
                    </StyledTableRow>
                    {/* don't render first child from "values" */}
                    {values?.map((childRow, idx) => (
                        idx > 0 && (
                            <StyledTableRow key={idx} > {
                                // 
                                Object.keys(secondaryColumns).map((secondaryColumnKey) => {
                                    let val = childRow[secondaryColumnKey]
                                    val = typeof val === "number" ? Number(val).toLocaleString("es-ES", { maximumFractionDigits: 0 }) : val
                                    return (
                                        <StyledTableCell key={secondaryColumnKey}>{RenovationKeyToValue(val)}</StyledTableCell>
                                    )
                                })
                            }
                            </StyledTableRow>
                        )
                    ))}
                </>
            ))}
        </TableBody>
    )

    const NormalTable = () => (
        <Grid container style={{ overflow: "auto" }}>
            <CutomPaper elevation={2} sx={{ margin: "5px", padding: 0 }}>
                <TableContainer>
                    <Table sixe="small" sx={{ minWidth: 100, width: "100%" }}>
                        <Head primaryColumn={primaryColumn} secondaryColumns={secondaryColumns} />
                        <Body chartData={data?.municipality || {}} secondaryColumns={secondaryColumns} />
                    </Table>
                </TableContainer>
            </CutomPaper>
        </Grid>
    )

    const StatisticalTable = () => (
        <Grid container>
            <CutomPaper elevation={2}>
                <TableContainer>
                    <Table sixe="small" sx={{ minWidth: 100, width: "100%" }}>
                        <Head primaryColumn={primaryColumn} secondaryColumns={secondaryColumns} />
                        <Body chartData={data?.sector || {}} secondaryColumns={secondaryColumns} />
                    </Table>
                </TableContainer>
            </CutomPaper>
        </Grid>
    )

    return (
        <TabPanelContainer
            title="INVESTERING"
            description="De tabel op dit tabblad geeft per gebouwtypologie een overzicht van de 
            belangrijkste cijfers weer voor enkele renovatiescenario’s. De investeringskosten en
            subsidiebedragen, de energiekost na implementatie, de impact op CO₂-uitstoot en 
            afgeleide waardes zoals de total cost of ownership en de kost van de CO₂-reductie."
            showStatisticalSector={![null, "", undefined].includes(statisticalSectorArea)}
            statisticalSectorAreaName={statisticalSectorArea}
            render={data?.municipality ? <NormalTable /> : <TabsInfoMessage {...loading ? { message: "Loading..." } : null} />}
            statisticalSectorRender={data?.sector ? <StatisticalTable /> : <TabsInfoMessage {...sectLoading ? { message: "Loading..." } : null} />}
        />
    )
}

export default React.memo(Investment)