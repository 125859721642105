import {
    simulation,
} from "../redux/constants/ApiEndPoints";
import {ApiService} from "./ApiService";


class SimulationAPIService extends ApiService{
    async getUserSimulations() {
        return await this.apiWrapper("Could not get simulations", simulation, "get",
            true)
    }

    async saveSimulation(data) {
        return await this.apiWrapper("Could not get simulations", simulation, "post",
            true, data)
    }

    async deleteSimulation(simulationId) {
        return await this.apiWrapper("Could not delete simulations", `${simulation}/${simulationId}`, "delete",
            true)
    }

    async getSimulation(simulationId) {
        return await this.apiWrapper("Could not get simulation", `${simulation}/${simulationId}`, "get",
            true)
    }

    async updateSimulation(simulationId, data) {
        return await this.apiWrapper("Could not update simulation", `${simulation}/${simulationId}`, "put",
            true, data)
    }
}

export default new SimulationAPIService()