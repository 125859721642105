import { Box, Button, Stack, Typography, styled } from "@mui/material";

import { BUILDING_TYPES, categoriesIcons } from "../../../constants/simulation";

const TypologyItemContainer = styled(Button)({
    borderRadius: "5px",
    cursor: "pointer",
    paddingY: "10px",
    paddingX: 1,
    margin: "2px",
    width: "100%",
    marginLeft: "2px",
    marginRight: "2px",
    paddingTop: "2px",
    paddingBottom: "2px",
    paddingRight: "8px",
    paddingLeft: "8px",
    minHeight: "40px",

    ":disabled": {
        opacity: 0.5
    }
})

const TypologyItem = ({ item, toggleCategory, activeCategoryIds, isDisabled }) => {
    let { percentageText, key } = item
    const itemIcon = categoriesIcons[key]
    const isActive = activeCategoryIds?.includes(key)

    const title = BUILDING_TYPES[key] || ""

    return (
        <TypologyItemContainer
            onClick={() => toggleCategory(key)}
            disabled={isDisabled}
            sx={{
                background: isActive ? "#0080003d" : "white",
                border: isActive ? "2px solid #43b02a" : "1px solid gray",
            }}
            disableRipple
        >
            <Stack direction='row' alignItems="center" justifyContent="space-between" width="100%">
                <img height={15} src={itemIcon} alt="" />
                <Typography variant='body2' color={isActive ? "#43b02a" : "gray"} fontSize="10px" sx={{ marginLeft: "8px", marginRight: "auto", }}>{title}</Typography>
                <Box sx={{ flexGrow: "auto" }} />
                <Typography variant='caption' color={isActive ? "#43b02a" : "#dadada"} sx={{ marginRight: 0 }}>{percentageText}</Typography>
            </Stack>
        </TypologyItemContainer>
    )
}

export default TypologyItem
