import React, {useEffect, useState} from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import {GEOSERVER_STORE, GEOSERVER_URL} from "../../redux/constants/ApiEndPoints";
import {EPC_LABEL_TO_DB_MAPPING_NEW, MAP_LAYERS, SEARCH_LEVELS} from "../../constants/map";
import mapApiService from "../../services/mapApiService";
import {
    convertCQLObjToGeoServerCQL,
    getMunicipalityLayer, getSectorAgeLayer,
    getSectorHeatLayer, getSectorIncomeLayer, getSectorInvestmentLayer, getSectorPotentialLayer, getSectorTenantLayer,
    getStreetHeatLayer, getStreetInvestmentLayer, getStreetPotentialLayer, getStreetSearchDirectionLayer
} from "../../helper/mapHelpers";
import L from "leaflet";
import {PopupContent} from "../../helper/popup";
import {ToggleButton} from "../../helper/buttons";
import SidePanel from "../sidepanel/sidePanel";
import { SIMULATION_CLICK_STATE } from "../../constants/simulation";
import {onChangeSimulationVisualizationState} from '../../redux/actions/advancedMap';
import MapSearchBar from "../sidepanel/mapSearchBar";
import {
    setSelectedStreetLayer
} from "../../redux/actions/map";
import {Box, Stack} from "@mui/material";

const BasicMap = ({
    openToggler = () => { },
    popupContentData,
    setPopupContentData,
    streetName,
    epc,
    potential,
    investment,
    buildingTypes,
    setStreetName,
    setEpc,
    setPotential,
    setInvestment,
    setBuildingTypes,
    ...props
}) => {

    const [mounted, setMounted] = useState(false);
    const [searchBtnClick, setSearchBtnClick] = useState(false);
    const [municipalityLayer, setMunicipalityLayer] = useState(null);

    const [sectTenantMap, setSectTenantMap] = useState(null);
    const [sectAgeMap, setSectAgeMap] = useState(null);
    const [sectIncomeMap, setSectIncomeMap] = useState(null);
    const [sectPotentialMap, setSectPotentialMap] = useState(null);
    const [sectInvestmentMap, setSectInvestmentMap] = useState(null);
    const [sectCurrentHeatStateMap, setSectCurrentHeatStateMap] = useState(null);

    const [streetsPotentialMap, setStreetsPotentialMap] = useState(null);
    const [streetsInvestmentMap, setStreetsInvestmentMap] = useState(null);
    const [streetsSearchDirectionMap, setStreetsSearchDirectionMap] = useState(null);
    const [streetsCurrentHeatStateMap, setStreetsCurrentHeatStateMap] = useState(null);

    const [
        layersToShowInCurrentHeatStateStreets,
        setLayersToShowInCurrentHeatStateStreets
    ] = useState([`${GEOSERVER_STORE}:hStreet`]);

    const [
        additionalLayersToShow,
        setAdditionalLayersToShow,
    ] = useState([]);

    const [
        layersToShowInCurrentHeatStateSectors,
        setLayersToShowInCurrentHeatStateSectors,
    ] = useState([`${GEOSERVER_STORE}:hSect`]);

    const [layersToShowInPotentialStreets, setlayersToShowInPotntialStreets] =
        useState([`${GEOSERVER_STORE}:PStreet`]);
    const [layersToShowInPotentialSectors, setlayersToShowInPotntialSectors] =
        useState([`${GEOSERVER_STORE}:PSect`]);

    const [layersToShowInInvestmentStreets, setlayersToShowInInvestmentStreets] =
        useState([`${GEOSERVER_STORE}:IStreet`]);
    const [layersToShowInInvestmentSectors, setlayersToShowInInvestmentSectors] =
        useState([`${GEOSERVER_STORE}:ISect`]);

    const [
        layersToShowInSearchDirectionStreets,
        setLayersToShowInSearchDirectionStreets,
    ] = useState([`${GEOSERVER_STORE}:cluster_streets`]);

    const [
        layersToShowInIncomeSectors,
        setLayersToShowInIncomeSectors,
    ] = useState([`${GEOSERVER_STORE}:income`]);

    const [
        layersToShowInAgeSectors,
        setLayersToShowInAgeSectors,
    ] = useState([`${GEOSERVER_STORE}:age`]);

    const [
        layersToShowInTenantsSectors,
        setLayersToShowInTenantsSectors,
    ] = useState([`${GEOSERVER_STORE}:tenant`]);

    //TODO: remove this after checking carefully
    const [layerCurrentHeatStateStreetsCQL, setLayerCurrentHeatStateStreetsCQL] =
        useState({
            streets: "INCLUDE",
            mun_boundary: null
        });

    const [layersCQL, setLayersCQL] =
        useState({
            mun_boundary: null,
            mun_streets: null
        });
    const [wmsInAction, setWmsInAction] = useState(true);
    const selectedArea = useSelector((state) => state.map.selectedArea);
    const selectedAreaLevel = useSelector((state) => state.map.searchLevel);
    const selectedStreetLayer = useSelector((state) => state.map.selectedStreetLayer);
    const selectedSectorLayer = useSelector((state) => state.map.selectedSectorLayer);
    const simulationViewState = useSelector((state) => state.simulation.clickState);
    const simulationData = useSelector((state) => state.simulation.mapData);
    
    const dispatch = useDispatch();

    useEffect(() => {
        if (!props.simulationenv && sessionStorage.clickcount === undefined) {
            if (selectedStreetLayer === null && selectedSectorLayer === null) {
                props.setStreetLayer(MAP_LAYERS.CURRENT_HEAT_STATE_STREETS);
            }
        }
    }, []);

    useEffect(() => {
        if (props.simulationenv && sessionStorage.clickcount === undefined) {
            if (selectedStreetLayer === null && selectedSectorLayer === null) {
                props.setStreetLayer(MAP_LAYERS.CURRENT_HEAT_STATE_STREETS);
            }
        }
    }, []);
    
    useEffect(() => {
        if (wmsInAction && props.layerClick === true) {
            props.popupToggler();
        }
    }, [wmsInAction]);

    useEffect(() => {
        if (!props.simulationenv) {
            dispatch(onChangeSimulationVisualizationState(SIMULATION_CLICK_STATE.BEFORE))
        } else {
            dispatch(onChangeSimulationVisualizationState(SIMULATION_CLICK_STATE.AFTER))
        }
    }, [props.simulationenv])

    useEffect(() => {
        if (!props.simulationenv) {
            setLayersCQL(prevState => {
                return {
                    ...prevState,
                    mun_streets: null
                }
            })
        } else if (props.simulationenv) {
            if (simulationData) {
                if (simulationViewState === SIMULATION_CLICK_STATE.AFTER) {
                    setLayersCQL(prevState => {
                        return {
                            ...prevState,
                            mun_streets: `municipality<>'${selectedArea.municipalityName}'`
                        }
                    })
                } else if (simulationViewState === SIMULATION_CLICK_STATE.BEFORE) {
                    if (selectedSectorLayer === MAP_LAYERS.CURRENT_HEAT_STATE_SECTORS ||
                        selectedStreetLayer === MAP_LAYERS.CURRENT_HEAT_STATE_STREETS ||
                        selectedSectorLayer === MAP_LAYERS.POTENTIAL_SECTORS ||
                        selectedStreetLayer === MAP_LAYERS.POTENTIAL_STREETS ||
                        selectedSectorLayer === MAP_LAYERS.INVESTMENT_SECTORS ||
                        selectedStreetLayer === MAP_LAYERS.INVESTMENT_STREETS ||
                        selectedStreetLayer === MAP_LAYERS.DIRECTION_STREETS
                    ) {
                        setLayersCQL(prevState => {
                            return {
                                ...prevState,
                                mun_streets: `municipality<>'${selectedArea.municipalityName}'`
                            }
                        })
                    } else {
                        setLayersCQL(prevState => {
                            return {
                                ...prevState,
                                mun_streets: null
                            }
                        })
                    }
                } 
            } else {
                setLayersCQL(prevState => {
                    return {
                        ...prevState,
                        mun_streets: null
                    }
                })
            }
        }
    }, [
        props.simulationenv,
        simulationViewState,
        simulationData,
        selectedArea
    ]);

    useEffect(async () => {
        if (props.mymap) {
            await reloadMapLayers();
            props.setMapReloads(prevValue=> prevValue+1)
        }
    }, [
        selectedAreaLevel,
        sectCurrentHeatStateMap,
        streetsCurrentHeatStateMap,
        sectIncomeMap,
        sectInvestmentMap,
        streetsInvestmentMap,
        sectPotentialMap,
        streetsPotentialMap,
        streetsSearchDirectionMap,
        sectAgeMap,
        sectTenantMap
    ]);

    useEffect(() => {
        if (props.mymap && ![0, undefined].includes(props.mapView.lat)) {
            if (
                props.mymap.getCenter().lat !== props.mapView.lat ||
                props.mymap.getCenter().lng !== props.mapView.long ||
                props.mymap.getZoom() !== props.mapView.zoom
            ) {
                props.mymap.setView([props.mapView.lat, props.mapView.long], props.mapView.zoom);
            }
        }
        if (props.mapView.zoom >= 14) {
            if (wmsInAction) {
                setWmsInAction(false);
            }
            (async () => await handleWFSMapLoading())();
        } else {
            if (!wmsInAction) {
                setWmsInAction(true);
            }
            (async () => await handleWMSMapLoading(props.mapUpdated === 1))();
        }
        let i = 0;
        props.mymap?.eachLayer(function(){ i += 1; });
        return () => {
            setMounted(false);
        }
    }, [
        props.mapView,
        props.mapUpdated,
        selectedStreetLayer,
        selectedSectorLayer,
        selectedArea,
        layerCurrentHeatStateStreetsCQL,
        layersCQL
        ]
    );

    useEffect(() => {
        if (selectedAreaLevel === SEARCH_LEVELS.MUNICIPALITY && selectedArea.municipalityId) {
            setMunicipalityWithStreetsActive()
        } else {
            setMunicipalityInActive()
        }
    }, [selectedAreaLevel, selectedArea.municipalityId, selectedArea.municipalityName])

    useEffect(async () => {
        if (!selectedSectorLayer && !selectedStreetLayer && additionalLayersToShow.length) {
            await handleExtraLayersLoadingWFS()
        }
    }, [additionalLayersToShow, selectedSectorLayer, selectedStreetLayer, props.mapView])

    // set opacity of sectors to make visuals better
    useEffect( () => {
        if (selectedSectorLayer) {
            const mapping = {
                [MAP_LAYERS.AGE_SECTORS]: sectAgeMap,
                [MAP_LAYERS.CURRENT_HEAT_STATE_SECTORS]: sectCurrentHeatStateMap,
                [MAP_LAYERS.INCOME_SECTORS]: sectIncomeMap,
                [MAP_LAYERS.INVESTMENT_SECTORS]: sectInvestmentMap,
                [MAP_LAYERS.POTENTIAL_SECTORS]: sectPotentialMap,
                [MAP_LAYERS.TENANT_SECTORS]: sectTenantMap
            }
            const sectorMap = mapping[selectedSectorLayer]
            if (props.mymap && sectorMap) {
                if (wmsInAction && typeof sectorMap.setOpacity === "function") {
                    if (selectedStreetLayer) {
                        sectorMap?.setOpacity(0.4)
                    } else {
                        sectorMap?.setOpacity(1.0)
                    }
                }
            }
        }
    }, [selectedStreetLayer, selectedSectorLayer, sectAgeMap, sectCurrentHeatStateMap, sectIncomeMap, sectInvestmentMap,
        sectPotentialMap, sectTenantMap, props.mapView])

    const setMunicipalityInActive = () => {
        setAdditionalLayersToShow([])
        setLayersCQL((prevState) => {
            return {
                ...prevState,
                mun_boundary: null
            }
        })
    }

    const setMunicipalityWithStreetsActive = () => {
        setAdditionalLayersToShow([`${GEOSERVER_STORE}:municipalities`])
        setLayersCQL((prevState) => {
            return {
                ...prevState,
                mun_boundary: `name='${selectedArea.municipalityName}'`
            }
        })
    }

    useEffect(() => {
        ;(async () => {
            if (selectedAreaLevel === SEARCH_LEVELS.MUNICIPALITY && selectedArea.municipalityId) {
                const municipalityOutlineRes = await mapApiService.getRequestedMuniOutline(null, `${GEOSERVER_STORE}:municipalities`, 'geom', `index=${selectedArea.municipalityId}`);
                if (municipalityOutlineRes) {
                    setMunicipalityLayer(getMunicipalityLayer(municipalityOutlineRes.data, wmsInAction))
                }
            } else {
                setMunicipalityInActive()
            }
        })()
    }, [selectedAreaLevel, selectedArea.municipalityId])

    useEffect(() => {
        if (props.mymap) {
            props.mymap.fitBounds(municipalityLayer?.getBounds());
        }
    }, [municipalityLayer])

    const removeLayers = async (excludedLayersFromDeletion = ['selected_sector'],
        removeAllLayers = false) => {
        if (props.mymap) {
            props.mymap.eachLayer(function (layer) {
                if (
                    !removeAllLayers &&
                    (!layer.options ||
                        !["basemap", ...excludedLayersFromDeletion].includes(
                            layer.options.title
                        ))
                ) {

                    props.mymap.removeLayer(layer);
                }
            });
            if (
                streetsCurrentHeatStateMap &&
                props.mymap.hasLayer(streetsCurrentHeatStateMap)
            ) {
                props.mymap.removeLayer(streetsCurrentHeatStateMap);
            }
            if (
                sectCurrentHeatStateMap &&
                props.mymap.hasLayer(sectCurrentHeatStateMap)
            ) {
                props.mymap.removeLayer(sectCurrentHeatStateMap);
            }
            if (
                streetsPotentialMap &&
                props.mymap.hasLayer(streetsPotentialMap)
            ) {
                props.mymap.removeLayer(streetsPotentialMap);
            }
            if (
                sectPotentialMap &&
                props.mymap.hasLayer(sectPotentialMap)
            ) {
                props.mymap.removeLayer(sectPotentialMap);
            }
            if (
                streetsInvestmentMap &&
                props.mymap.hasLayer(streetsInvestmentMap)
            ) {
                props.mymap.removeLayer(streetsInvestmentMap);
            }
            if (
                sectInvestmentMap &&
                props.mymap.hasLayer(sectInvestmentMap)
            ) {
                props.mymap.removeLayer(sectInvestmentMap);
            }
            if (
                sectIncomeMap &&
                props.mymap.hasLayer(sectIncomeMap)
            ) {
                props.mymap.removeLayer(sectIncomeMap);
            }
            if (
                streetsSearchDirectionMap &&
                props.mymap.hasLayer(streetsSearchDirectionMap)
            ) {
                props.mymap.removeLayer(streetsSearchDirectionMap);
            }
            if (
                sectAgeMap &&
                props.mymap.hasLayer(sectAgeMap)
            ) {
                props.mymap.removeLayer(sectAgeMap);
            }
            if (
                sectTenantMap &&
                props.mymap.hasLayer(sectTenantMap)
            ) {
                props.mymap.removeLayer(sectTenantMap);
            }
        }
    };

    const handleWMSMapLoading = async (resetBounds = false) => {
        await removeLayers();
        try {
            let streetCurrentHeatStateOptions = {
                title: "currentHeatStateStreets",
                layers: [...layersToShowInCurrentHeatStateStreets, ...additionalLayersToShow],
                format: "image/png",
                transparent: true,
                zIndex: 100,
                attribution: "Agifly",
            };
            let sectCurrentHeatStateOptions = {
                title: "currentHeatStateSectors",
                layers: [...layersToShowInCurrentHeatStateSectors, ...additionalLayersToShow],
                format: "image/png",
                transparent: true,
                zIndex: 100,
                attribution: "Agifly",
            };
            let streetPotentialOptions = {
                title: "PotentialStreets",
                layers: [...layersToShowInPotentialStreets, ...additionalLayersToShow],
                format: "image/png",
                transparent: true,
                zIndex: 100,
                attribution: "Agifly",
            };
            let sectPotentialOptions = {
                title: "PotentialSectors",
                layers: [...layersToShowInPotentialSectors, ...additionalLayersToShow],
                format: "image/png",
                transparent: true,
                zIndex: 100,
                attribution: "Agifly",
            };
            let streetInvestmentOptions = {
                title: "InvestmentStreets",
                layers: [...layersToShowInInvestmentStreets, ...additionalLayersToShow],
                format: "image/png",
                transparent: true,
                zIndex: 100,
                attribution: "Agifly",
            };
            let sectInvestmentOptions = {
                title: "InvestmentSectors",
                layers: [...layersToShowInInvestmentSectors, ...additionalLayersToShow],
                format: "image/png",
                transparent: true,
                zIndex: 100,
                attribution: "Agifly",
            };
            let streetSearchDirectionOptions = {
                title: "searchDirectionStreets",
                layers: [...layersToShowInSearchDirectionStreets, ...additionalLayersToShow],
                format: "image/png",
                transparent: true,
                zIndex: 100,
                attribution: "Agifly",
            };
            let sectIncomeOptions = {
                title: "IncomeSectors",
                layers: [...layersToShowInIncomeSectors, ...additionalLayersToShow],
                format: "image/png",
                transparent: true,
                zIndex: 100,
                attribution: "Agifly",
            };
            let ageOptions = {
                title: "AgeSectors",
                layers: [...layersToShowInAgeSectors, ...additionalLayersToShow],
                format: "image/png",
                transparent: true,
                zIndex: 100,
                attribution: "Agifly",
            };
            let tenantOptions = {
                title: "TenantsSectors",
                layers: [...layersToShowInTenantsSectors, ...additionalLayersToShow],
                format: "image/png",
                transparent: true,
                zIndex: 100,
                attribution: "Agifly",
            };
            if (layersCQL.mun_boundary || layersCQL.mun_streets) {
                let cqlArrayForSimulationLayers = convertCQLObjToGeoServerCQL([layersCQL.mun_streets || 'INCLUDE',
                    layersCQL.mun_boundary || "INCLUDE"])
                let cqlArray = convertCQLObjToGeoServerCQL(['INCLUDE', layersCQL.mun_boundary || "INCLUDE"])
                streetCurrentHeatStateOptions["CQL_FILTER"] = cqlArrayForSimulationLayers
                sectCurrentHeatStateOptions["CQL_FILTER"] = cqlArrayForSimulationLayers
                streetPotentialOptions["CQL_FILTER"] = cqlArrayForSimulationLayers
                sectPotentialOptions["CQL_FILTER"] = cqlArray
                streetInvestmentOptions["CQL_FILTER"] = cqlArrayForSimulationLayers
                sectInvestmentOptions["CQL_FILTER"] = cqlArrayForSimulationLayers
                sectIncomeOptions["CQL_FILTER"] = cqlArray
                streetSearchDirectionOptions["CQL_FILTER"] = cqlArrayForSimulationLayers
                ageOptions["CQL_FILTER"] = cqlArray
                tenantOptions["CQL_FILTER"] = cqlArray
            }
            if (selectedStreetLayer === MAP_LAYERS.CURRENT_HEAT_STATE_STREETS) {
                let streetCurrentHeatState = L.tileLayer.wms(`${GEOSERVER_URL}/wms`, {
                    ...streetCurrentHeatStateOptions,
                });
                setStreetsCurrentHeatStateMap(streetCurrentHeatState);
            }
            if (selectedSectorLayer === MAP_LAYERS.CURRENT_HEAT_STATE_SECTORS) {
                let sectCurrentHeatState = L.tileLayer.wms(`${GEOSERVER_URL}/wms`, {
                ...sectCurrentHeatStateOptions,
            });
            setSectCurrentHeatStateMap(sectCurrentHeatState);
            }
            if (selectedStreetLayer === MAP_LAYERS.POTENTIAL_STREETS) {
                let streetPotential = L.tileLayer.wms(`${GEOSERVER_URL}/wms`, {
                    ...streetPotentialOptions,
                });
                setStreetsPotentialMap(streetPotential);
            }
            if (selectedSectorLayer === MAP_LAYERS.POTENTIAL_SECTORS) {
                let sectPotential = L.tileLayer.wms(`${GEOSERVER_URL}/wms`, {
                    ...sectPotentialOptions,
                });
                setSectPotentialMap(sectPotential);
            }          
            if (selectedStreetLayer === MAP_LAYERS.INVESTMENT_STREETS) {
                let streetInvestment = L.tileLayer.wms(`${GEOSERVER_URL}/wms`, {
                    ...streetInvestmentOptions,
                });
                setStreetsInvestmentMap(streetInvestment);
            }
            if (selectedSectorLayer === MAP_LAYERS.INVESTMENT_SECTORS) {
                let sectInvestment = L.tileLayer.wms(`${GEOSERVER_URL}/wms`, {
                    ...sectInvestmentOptions,
                });
                setSectInvestmentMap(sectInvestment);
            }
            if (selectedStreetLayer === MAP_LAYERS.DIRECTION_STREETS) {
                let streetSearchDirection = L.tileLayer.wms(`${GEOSERVER_URL}/wms`, {
                    ...streetSearchDirectionOptions,
                });
                setStreetsSearchDirectionMap(streetSearchDirection);
            }
            if (selectedSectorLayer === MAP_LAYERS.INCOME_SECTORS) {
                let sectIncome = L.tileLayer.wms(`${GEOSERVER_URL}/wms`, {
                    ...sectIncomeOptions,
                });
                setSectIncomeMap(sectIncome);
            }        
            if (selectedSectorLayer === MAP_LAYERS.AGE_SECTORS) {
                let ageSectors = L.tileLayer.wms(`${GEOSERVER_URL}/wms`, {
                    ...ageOptions,
                });
                setSectAgeMap(ageSectors);
            }        
            if (selectedSectorLayer === MAP_LAYERS.TENANT_SECTORS) {
                let tenantSectors = L.tileLayer.wms(`${GEOSERVER_URL}/wms`, {
                    ...tenantOptions,
                });
                setSectTenantMap(tenantSectors);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const handleExtraLayersLoadingWFS = async () => {
        additionalLayersToShow.map(async (layer) => {
            if (layer.includes('municipalities')) {
                const munGeometry = await mapApiService.getRequestedMuniOutline(
                    null,
                    `${GEOSERVER_STORE}:municipalities`,
                    "geom",
                    `index=${selectedArea.municipalityId}`
                );
                if (munGeometry.status === 200) {
                    const munLayer = getMunicipalityLayer(munGeometry.data, wmsInAction)
                    munLayer.addTo(props.mymap)
                    munLayer.off('click');
                }
            }
        })
    };

    const handleWFSMapLoading = async () => {
        await removeLayers();
        try {
            let bounds = props.mymap.getBounds();
            let streetCurrentHeatRes, sectCurrentHeatRes, streetPotentialRes, sectPotentialRes, streetInvestmentRes, sectInvestmentRes, streetSearchDirectionRes, sectIncomeRes, sectAgeRes, sectTenantRes;
            let cql = null;
            if (layersCQL.mun_boundary || layersCQL.mun_streets) {
                cql = convertCQLObjToGeoServerCQL([layersCQL.mun_streets || 'INCLUDE'])
            }
            if (selectedStreetLayer === MAP_LAYERS.CURRENT_HEAT_STATE_STREETS) {
                streetCurrentHeatRes = await mapApiService.getRequestedMapArea(
                    bounds,
                    layersToShowInCurrentHeatStateStreets[0],
                    "geom",
                    cql
                );
                if (!streetCurrentHeatRes?.errors?.length) {
                    setLayerInState(
                        getStreetHeatLayer(
                            streetCurrentHeatRes.data
                        ),
                        MAP_LAYERS.CURRENT_HEAT_STATE_STREETS,
                        false,
                        true
                    );
                } else {
                    props.showPopupOnMapHandler(
                        streetCurrentHeatRes && streetCurrentHeatRes.errors
                            ? streetCurrentHeatRes.errors[0]
                            : "Unexpected Error in loading Heat Street WFS Layer",
                        "error"
                    );
                }
            }
            if (selectedSectorLayer === MAP_LAYERS.CURRENT_HEAT_STATE_SECTORS) {
                sectCurrentHeatRes = await mapApiService.getRequestedMapArea(
                    bounds,
                    layersToShowInCurrentHeatStateSectors[0],
                    "geom",
                    cql
                );
                if (!sectCurrentHeatRes.errors?.length) {
                    setLayerInState(
                        getSectorHeatLayer(
                            sectCurrentHeatRes.data,
                            selectedStreetLayer
                        ),
                        MAP_LAYERS.CURRENT_HEAT_STATE_SECTORS,
                        false
                    );
                } else {
                    props.showPopupOnMapHandler(
                        sectCurrentHeatRes && sectCurrentHeatRes.errors
                            ? sectCurrentHeatRes.errors[0]
                            : "Unexpected Error in loading Heat Sector WFS Layer",
                        "error"
                    );
                }
            }
            if (selectedStreetLayer === MAP_LAYERS.POTENTIAL_STREETS) {
                streetPotentialRes = await mapApiService.getRequestedMapArea(
                    bounds,
                    layersToShowInPotentialStreets[0],
                    "geom",
                    cql
                );
                if (!streetPotentialRes.errors?.length) {
                    setLayerInState(
                        getStreetPotentialLayer(
                            streetPotentialRes.data
                        ),
                        MAP_LAYERS.POTENTIAL_STREETS,
                        false,
                        true
                    );
                } else {
                    props.showPopupOnMapHandler(
                        streetPotentialRes && streetPotentialRes.errors
                            ? streetPotentialRes.errors[0]
                            : "Unexpected Error in loading Potential Street WFS Layer",
                        "error"
                    );
                }
            }
            if (selectedSectorLayer === MAP_LAYERS.POTENTIAL_SECTORS) {
                sectPotentialRes = await mapApiService.getRequestedMapArea(
                    bounds,
                    layersToShowInPotentialSectors[0],
                    "geom",
                    cql
                );
                if (!sectPotentialRes.errors?.length) {
                    setLayerInState(
                        getSectorPotentialLayer(
                            sectPotentialRes.data,
                            selectedStreetLayer
                        ),
                        MAP_LAYERS.POTENTIAL_SECTORS,
                        false
                    );
                } else {
                    props.showPopupOnMapHandler(
                        sectPotentialRes && sectPotentialRes.errors
                            ? sectPotentialRes.errors[0]
                            : "Unexpected Error in loading Potential Sector WFS Layer",
                        "error"
                    );
                }
            }
            if (selectedStreetLayer === MAP_LAYERS.INVESTMENT_STREETS) {
                streetInvestmentRes = await mapApiService.getRequestedMapArea(
                    bounds,
                    layersToShowInInvestmentStreets[0],
                    "geom",
                    cql
                );
                if (!streetInvestmentRes.errors?.length) {
                    setLayerInState(
                        getStreetInvestmentLayer(
                            streetInvestmentRes.data
                        ),
                        MAP_LAYERS.INVESTMENT_STREETS,
                        false,
                        true
                    );
                } else {
                    props.showPopupOnMapHandler(
                        streetInvestmentRes && streetInvestmentRes.errors
                            ? streetInvestmentRes.errors[0]
                            : "Unexpected Error in loading Investment Street WFS Layer",
                        "error"
                    );
                }
            }
            if (selectedSectorLayer === MAP_LAYERS.INVESTMENT_SECTORS) {
                sectInvestmentRes = await mapApiService.getRequestedMapArea(
                    bounds,
                    layersToShowInInvestmentSectors[0],
                    "geom",
                    cql
                );
                if (!sectInvestmentRes.errors?.length) {
                    setLayerInState(
                        getSectorInvestmentLayer(
                            sectInvestmentRes.data,
                            selectedStreetLayer
                        ),
                        MAP_LAYERS.INVESTMENT_SECTORS,
                        false
                    );
                } else {
                    props.showPopupOnMapHandler(
                        sectInvestmentRes && sectInvestmentRes.errors
                            ? sectInvestmentRes.errors[0]
                            : "Unexpected Error in loading Investment Sector WFS Layer",
                        "error"
                    );
                }
            }
            if (selectedStreetLayer === MAP_LAYERS.DIRECTION_STREETS) {
                streetSearchDirectionRes = await mapApiService.getRequestedMapArea(
                    bounds,
                    layersToShowInSearchDirectionStreets[0],
                    "geom",
                    cql
                );
                if (!streetSearchDirectionRes.errors?.length) {
                    setLayerInState(
                        getStreetSearchDirectionLayer(
                            streetSearchDirectionRes.data
                        ),
                        MAP_LAYERS.DIRECTION_STREETS,
                        false,
                        true
                    );
                } else {
                    props.showPopupOnMapHandler(
                        streetSearchDirectionRes && streetSearchDirectionRes.errors
                            ? streetSearchDirectionRes.errors[0]
                            : "Unexpected Error in loading Direction Street WFS Layer",
                        "error"
                    );
                }
            }
            if (selectedSectorLayer === MAP_LAYERS.INCOME_SECTORS) {
                sectIncomeRes = await mapApiService.getRequestedMapArea(
                    bounds,
                    layersToShowInIncomeSectors[0],
                    "geom"
                );
                if (!sectIncomeRes.errors?.length) {
                    setLayerInState(
                        getSectorIncomeLayer(
                            sectIncomeRes.data,
                            selectedStreetLayer
                        ),
                        MAP_LAYERS.INCOME_SECTORS,
                        false
                    );
                } else {
                    props.showPopupOnMapHandler(
                        sectIncomeRes && sectIncomeRes.errors
                            ? sectIncomeRes.errors[0]
                            : "Unexpected Error in loading Income Sector WFS Layer",
                        "error"
                    );
                }
            }
            if (selectedSectorLayer === MAP_LAYERS.AGE_SECTORS) {
                sectAgeRes = await mapApiService.getRequestedMapArea(
                    bounds,
                    layersToShowInAgeSectors[0],
                    "geom"
                );
                if (!sectAgeRes.errors?.length) {
                    setLayerInState(
                        getSectorAgeLayer(
                            sectAgeRes.data,
                            selectedStreetLayer
                        ),
                        MAP_LAYERS.AGE_SECTORS,
                        false
                    );
                } else {
                    props.showPopupOnMapHandler(
                        sectAgeRes && sectAgeRes.errors
                            ? sectAgeRes.errors[0]
                            : "Unexpected Error in loading Age Sector WFS Layer",
                        "error"
                    );
                }
            }
            if (selectedSectorLayer === MAP_LAYERS.TENANT_SECTORS) {
                sectTenantRes = await mapApiService.getRequestedMapArea(
                    bounds,
                    layersToShowInTenantsSectors[0],
                    "geom"
                );
                if (!sectTenantRes.errors?.length) {

                    setLayerInState(
                        getSectorTenantLayer(
                            sectTenantRes.data,
                            selectedStreetLayer
                        ),
                        MAP_LAYERS.TENANT_SECTORS,
                        false
                    );
                } else {
                    props.showPopupOnMapHandler(
                        sectTenantRes && sectTenantRes.errors
                            ? sectTenantRes.errors[0]
                            : "Unexpected Error in loading Tenant Sector WFS Layer",
                        "error"
                    );
                }
            }
        } catch (err) {
            console.log("err in map loading is ", err);
            props.showPopupOnMapHandler("Kaart kon niet worden geladen", "error");
        }
    };

    const setLayerInState = (
        selectedLayer,
        mapLayer,
        fitToBounds = true,
        addPopupClickHandler = false
    ) => {
        if (addPopupClickHandler) {
            selectedLayer.on('click', async (e) => {
                const streetId = e.layer.feature.properties.segment_id;
                if (!streetId) {
                    console.log('Faulty street click', e)
                    return
                }
                const streetInfo = await mapApiService.getStreetInfo(streetId)
                if (streetInfo?.data) {
                    setPopupContentData({
                        streetName: streetInfo?.data?.name,
                        epc: EPC_LABEL_TO_DB_MAPPING_NEW[Math.ceil(streetInfo?.data?.epc_label/ 100) * 100] || ((Math.ceil((streetInfo?.data?.epc_label/ 100) * 100) < 100) ? 'A' : 'F'),
                        potential: streetInfo?.data?.potential,
                        investment: streetInfo?.data.investment_cost,
                        buildingTypes: streetInfo?.data?.building_types_ratio
                    })
                } else {
                    setPopupContentData({});
                }
                openToggler();
            })
        }
        if (mapLayer === MAP_LAYERS.CURRENT_HEAT_STATE_STREETS) {
            setStreetsCurrentHeatStateMap(selectedLayer);
        } else if (mapLayer === MAP_LAYERS.CURRENT_HEAT_STATE_SECTORS) {
            setSectCurrentHeatStateMap(selectedLayer);
        } else if (mapLayer === MAP_LAYERS.POTENTIAL_STREETS) {
            setStreetsPotentialMap(selectedLayer);
        } else if (mapLayer === MAP_LAYERS.POTENTIAL_SECTORS) {
            setSectPotentialMap(selectedLayer);
        } else if (mapLayer === MAP_LAYERS.INVESTMENT_STREETS) {
            setStreetsInvestmentMap(selectedLayer);
        } else if (mapLayer === MAP_LAYERS.INVESTMENT_SECTORS) {
            setSectInvestmentMap(selectedLayer);
        } else if (mapLayer === MAP_LAYERS.DIRECTION_STREETS) {
            setStreetsSearchDirectionMap(selectedLayer);
        } else if (mapLayer === MAP_LAYERS.INCOME_SECTORS) {
            setSectIncomeMap(selectedLayer);
        } else if (mapLayer === MAP_LAYERS.AGE_SECTORS) {
            setSectAgeMap(selectedLayer);
        } else if (mapLayer === MAP_LAYERS.TENANT_SECTORS) {
            setSectTenantMap(selectedLayer);
        }
        if (fitToBounds) props.mymap.fitBounds(selectedLayer.getBounds());
    };

    const handleMapServiceToggle = () => {
        if (wmsInAction) {
            props.setMapView({ lat: props.mymap.getCenter().lat, long: props.mymap.getCenter().lng, zoom: 14 })
        } else {
            props.setMapView({ lat: props.mymap.getCenter().lat, long: props.mymap.getCenter().lng, zoom: 9 })
        }
    };

    const reloadMapLayers = async () => {
        if (!wmsInAction) {
            await handleExtraLayersLoadingWFS();
        }
        
        if (streetsCurrentHeatStateMap && selectedStreetLayer === MAP_LAYERS.CURRENT_HEAT_STATE_STREETS) {
            if (props.mymap.hasLayer(streetsCurrentHeatStateMap)) {
                props.mymap.removeLayer(streetsCurrentHeatStateMap);
            }
            streetsCurrentHeatStateMap.addTo(props.mymap);
        }
        if (sectCurrentHeatStateMap && selectedSectorLayer === MAP_LAYERS.CURRENT_HEAT_STATE_SECTORS) {
            if (props.mymap.hasLayer(sectCurrentHeatStateMap)) {
                props.mymap.removeLayer(sectCurrentHeatStateMap);
            }
            sectCurrentHeatStateMap.addTo(props.mymap);
        }
        if (streetsPotentialMap && selectedStreetLayer === MAP_LAYERS.POTENTIAL_STREETS) {
            if (props.mymap.hasLayer(streetsPotentialMap)) {
                props.mymap.removeLayer(streetsPotentialMap);
            }
            streetsPotentialMap.addTo(props.mymap);
        }
        if (sectPotentialMap && selectedSectorLayer === MAP_LAYERS.POTENTIAL_SECTORS) {
            if (props.mymap.hasLayer(sectPotentialMap)) {
                props.mymap.removeLayer(sectPotentialMap);
            }
            sectPotentialMap.addTo(props.mymap);
        }
        if (streetsInvestmentMap && selectedStreetLayer === MAP_LAYERS.INVESTMENT_STREETS) {
            if (props.mymap.hasLayer(streetsInvestmentMap)) {
                props.mymap.removeLayer(streetsInvestmentMap);
            }
            streetsInvestmentMap.addTo(props.mymap);
        }
        if (sectInvestmentMap && selectedSectorLayer === MAP_LAYERS.INVESTMENT_SECTORS) {
            if (props.mymap.hasLayer(sectInvestmentMap)) {
                props.mymap.removeLayer(sectInvestmentMap);
            }
            sectInvestmentMap.addTo(props.mymap);
        }
        if (streetsSearchDirectionMap && selectedStreetLayer === MAP_LAYERS.DIRECTION_STREETS) {
            if (props.mymap.hasLayer(streetsSearchDirectionMap)) {
                props.mymap.removeLayer(streetsSearchDirectionMap);
            }
            streetsSearchDirectionMap.addTo(props.mymap);
        }
        if (sectIncomeMap && selectedSectorLayer === MAP_LAYERS.INCOME_SECTORS) {
            if (props.mymap.hasLayer(sectIncomeMap)) {
                props.mymap.removeLayer(sectIncomeMap);
            }
            sectIncomeMap.addTo(props.mymap);
        }
        if (sectAgeMap && selectedSectorLayer === MAP_LAYERS.AGE_SECTORS) {
            if (props.mymap.hasLayer(sectAgeMap)) {
                props.mymap.removeLayer(sectAgeMap);
            }
            sectAgeMap.addTo(props.mymap);
        }
        if (sectTenantMap && selectedSectorLayer === MAP_LAYERS.TENANT_SECTORS) {
            if (props.mymap.hasLayer(sectTenantMap)) {
                props.mymap.removeLayer(sectTenantMap);
            }
            sectTenantMap.addTo(props.mymap);
        }
    };

    return (
        <>
            <Stack direction="column" sx={{ width: props.simulationenv? "24%": "100%", float: "right" }}>
                <Stack direction={props.simulationenv? "column": "row-reverse"} sx={{pointerEvents: "none", float: "right"}}>
                    <Box sx={{flex: "1 auto"}} />
                    {!props?.simulationenv ? (
                        <MapSearchBar /> 
                    ): null}
                    {props.layerClick ? (
                        <PopupContent
                            simulationenv={props.simulationenv}
                            onClickHandler={props.popupToggler}
                            popupContentData={popupContentData}
                        />
                    )
                        :
                        (
                            <ToggleButton
                                simulationenv={props.simulationenv}
                                extra_classes={"toggle-service-button"}
                                onClickHandler={handleMapServiceToggle}
                                name="Klik op een straat voor alle informatie"
                            />
                        )}
                </Stack>
                {props.simulationenv? (
                    <Box sx={{ float: "right", pointerEvents: "none", }}>
                    <Box sx={{pointerEvents: "auto", }}>
                        <SidePanel
                            simVizBtn={true}
                            smallScreen={props.smallScreen}
                            wmsInAction={wmsInAction}
                            handleToggleMapService={() => {
                            }}
                            setSearchBtnClick={setSearchBtnClick}
                            sidePanelContent={props.sidePanelContent}
                            searchAreaUpdateHandler={() => handleWMSMapLoading(true)}
                        />
                    </Box>
                </Box>
                ) : null}
            </Stack>
            {!props.simulationenv? (
                    <Box sx={{ pointerEvents: "none", width: "24%", float: "right", }}>
                    <Box sx={{pointerEvents: "auto", }}>
                        <SidePanel
                            simVizBtn={false}
                            smallScreen={props.smallScreen}
                            wmsInAction={wmsInAction}
                            handleToggleMapService={() => {
                            }}
                            setSearchBtnClick={setSearchBtnClick}
                            sidePanelContent={props.sidePanelContent}
                            searchAreaUpdateHandler={() => handleWMSMapLoading(true)}
                        />
                    </Box>
                </Box>
                ) : null}
        </>
    )
};

const mapStateToProps = ({ map, statisticalSector }, ownProps) => ({
    map,
    statisticalSector,
    ...ownProps
});
export default connect(mapStateToProps, {
    setStreetLayer: setSelectedStreetLayer,
})(BasicMap);