import {Grid, Divider} from "@material-ui/core";
import {MultiStepColorBar} from "../components/sharedComponents/multiColorBar";
import {BUILDING_TYPES_COLORS, HEAT_STATES_COLORS} from "../constants/sidepanel";
import {getInvestmentColor, getInvestmentLabel} from "./mapHelpers";
import { Box } from "@mui/material";

export const PopupContent = ({
    simulationenv,
    onClickHandler = () => { },
    popupContentData
}) => {

    let heat_color;
    let investment_color;
    let investment_label;

    const popupStreetName = popupContentData?.streetName || '';
    const popupEpcLabel = popupContentData?.epc || '';
    const popupPotential = popupContentData?.potential?.toFixed(2) || 0;
    const popupInvestment = popupContentData?.investment || 0;
    const popupBuildingTypes = popupContentData?.buildingTypes || [];

    heat_color = HEAT_STATES_COLORS[popupEpcLabel]
    if (popupInvestment <= 50000) {
        investment_label = '<50.000€'
        investment_color = '#D9C9DE'
    } else if (popupInvestment >= 50000) {
        investment_label = '>50.000€'
        investment_color = '#B393BD'
    } else {
        investment_label = '+100.000€'
        investment_color = '#69287E'
    }

    const simulationPopup = (
        <Box sx={{ pointerEvents: "auto" }}>
            <Grid container className="sidepanel-container" justifyContent="flex-end">
                <div className="popup-panel-simulation">
                <div className="popItems">
                    <div
                        className="d-flex justify-content-center align-items-center fs-16 bold"
                        style={{
                            font: "Barlow", minHeight: "17px", color: 'gray'
                        }}
                    >
                        {popupStreetName}
                    </div>
                    <div>
                        <button id='close' onClick={onClickHandler}>close</button>
                    </div>
                </div>
                <Divider />
                <div className="popItems">
                    <div
                        style={{
                            font: "Barlow",
                        }}
                        className="d-flex justify-content-center align-items-center fs-16 bold"
                    >
                        Huidige energieprestatie
                    </div>
                    <div
                        style={{
                            width: "15%",
                            margin: '5px',
                            background: heat_color,
                            textAlign: "center",
                            font: "10px",
                            fontFamily: "Barlow",
                            color: '#FFFFFF',
                        }}
                    >
                        {popupEpcLabel}
                    </div>
                </div>
                <div className="popItems">
                    <div
                        style={{
                            font: "Barlow",
                        }}
                        className="d-flex justify-content-center align-items-center fs-16 bold"
                    >
                        Potentieel
                    </div>
                    <div
                        style={{
                            margin: "5px", font: "Barlow", color: 'gray',
                        }}
                        className="fs-12 bold"
                    >
                        {popupPotential}<span> TCO/CO<sub>2</sub></span>
                    </div>
                </div>
                <div className="popItems">
                    <div
                        style={{
                            font: "Barlow",
                        }}
                        className="d-flex justify-content-center align-items-center fs-16 bold"
                    >
                        Investering
                    </div>
                    <div
                        style={{
                            width: "30%",
                            height: "20%",
                            margin: '5px',
                            background: `${getInvestmentColor(popupInvestment)}`,
                            textAlign: "center",
                            font: "10px",
                            fontFamily: "Barlow",
                            color: '#FFFFFF',
                        }}
                    >
                        {getInvestmentLabel(popupInvestment)}
                    </div>
                </div>
                <div className="popItems">
                    <div
                        style={{
                            font: "Barlow", height: "17px",
                        }}
                        className="fs-16 bold"
                    >
                        Gebouwbestand
                    </div>
                </div>
                <div className="popItems" style={{ margin: '0px' }}>
                    <div className="col-12 pt-2 pb-2">
                        <MultiStepColorBar
                            colors={popupBuildingTypes.map((buildingType, index) => {
                                return {
                                    hex: Object.values(BUILDING_TYPES_COLORS)[index],
                                    percentage: Math.round(buildingType.ratio),
                                    // label: buildingType.label
                                }
                            })}
                            // labels={popupBuildingTypes.map((building) => Math.round(building?.ratio))}
                            radius={"10"}
                            barHeight="10px"
                            labelPostfix="%"
                        />
                    </div>
                </div>
                <Grid container direction="row" className="popItems">
                    {popupBuildingTypes.map((buildingType, index) => {
                        return (<>
                            <Grid item xs={6}>
                                <div className="d-inline-flex justify-content-center align-items-center">
                                    <div className="rounded-corners"
                                        style={{
                                            width: "8px",
                                            height: "8px",
                                            marginTop: "5px",
                                            marginRight: "5px",
                                            background: Object.values(BUILDING_TYPES_COLORS)[index],
                                        }}
                                    >
                                    </div>
                                    <div>
                                        <span className="small text-grey">{buildingType.label} (</span><span className="small text-grey">{Math.round(buildingType.ratio)}%)</span>
                                    </div>
                                </div>
                            </Grid>
                        </>)
                    })}
                </Grid>
            </div>
            </Grid>
        </Box>
    );
    
    const analysisPopup = (
        <Box sx={{ pointerEvents: "auto" }}>
            <Grid container justifyContent="flex-end" alignItems="center" alignContent="center">
                <div className={simulationenv ? "popup-panel-simulation" : "popup-panel"} style={{pointerEvents: "auto"}}>
                    <div className="popItems">
                        <div
                            className="fs-16 bold"
                            style={{
                                font: "Barlow",
                                minHeight: "17px",
                                color: 'gray'
                            }}
                        >
                            {popupStreetName}
                        </div>
                        <div>
                            <button id='close' onClick={onClickHandler}>close</button>
                        </div>
                    </div>
                    <Divider />
                    <div className="popItems">
                        <div
                            className="popLabels barlow fs-16 bold"
                        >
                            Huidige energieprestatie
                        </div>
                        <div
                            style={{
                                width: "15%",
                                margin: '5px',
                                background: heat_color,
                                textAlign: "center",
                                font: "10px",
                                fontFamily: "Barlow",
                                color: '#FFFFFF',
                            }}
                        >
                            {popupEpcLabel}
                        </div>
                    </div>
                    <div className="popItems">
                        <div
                            className="popLabels barlow fs-16 bold"
                        >
                            Potentieel
                        </div>
                        <div
                            style={{
                                margin: "5px", font: "Barlow", color: 'gray',
                            }}
                            className="fs-12 bold"
                        >
                            {popupPotential}<span> TCO/CO<sub>2</sub></span>
                        </div>
                    </div>
                    <div className="popItems">
                        <div
                            className="popLabels barlow fs-16 bold"
                        >
                            Investering
                        </div>
                        <div
                            style={{
                                width: "30%",
                                height: "20%",
                                margin: '5px',
                                background: `${getInvestmentColor(popupInvestment)}`,
                                textAlign: "center",
                                font: "10px",
                                fontFamily: "Barlow",
                                color: '#FFFFFF',
                            }}
                        >
                            {getInvestmentLabel(popupInvestment)}
                        </div>
                    </div>
                    <div className="popItems">
                        <div
                            className="popLabels barlow fs-16 bold"
                        >
                            Gebouwbestand
                        </div>
                    </div>
                    <div className="popItems" style={{ margin: '0px' }}>
                        <div className="col-12 pt-2 pb-2">
                            <MultiStepColorBar
                                colors={popupBuildingTypes.map((buildingType, index) => {
                                    return {
                                        hex: Object.values(BUILDING_TYPES_COLORS)[index],
                                        percentage: Math.round(buildingType.ratio),
                                        // label: buildingType.label
                                    }
                                })}
                                // labels={popupBuildingTypes.map((building) => Math.round(building?.ratio))}
                                radius={"10"}
                                barHeight="10px"
                                // labelPostfix="%"
                            />
                        </div>
                    </div>
                    <Grid container direction="row" className="popItems">
                        {popupBuildingTypes.map((buildingType, index) => {
                            return (<>
                                <Grid item xs={6}>
                                    <div className="d-inline-flex justify-content-center align-items-center">
                                        <div className="rounded-corners"
                                            style={{
                                                width: "8px",
                                                height: "8px",
                                                marginTop: "5px",
                                                marginRight: "5px",
                                                background: Object.values(BUILDING_TYPES_COLORS)[index],
                                            }}
                                        >
                                        </div>
                                        <div>
                                            <span className="small text-grey">{buildingType.label} (</span><span className="small text-grey">{Math.round(buildingType.ratio)}%)</span>
                                        </div>
                                    </div>
                                </Grid>
                            </>)
                        })}
                    </Grid>
                </div>
            </Grid>
        </Box>
        );

    return (
        <>
            {simulationenv ?
                (simulationPopup)
                :
                (analysisPopup)
            }
        </>
    );
};