import React,{useState,useEffect} from "react"
import {connect} from 'react-redux';
import { Link } from "react-router-dom";
import { Row, Col, CardBody, Card, Container } from "reactstrap";
import '../../scss/ikrb/login.scss';
import { AvForm } from "availity-reactstrap-validation";
import profile from "../../assets/used/profile-img.png";
import {userRegistration, forgotPassword} from '../../redux/actions/auth';
import {showErrorMsg, hideMessage} from '../../redux/actions/loader';
import Validation_Icon from '../../icons/bootstrap/validation';
import SuccessMessage  from '../../helper/successMessage';
import {activeView} from '../../redux/actions/auth';
import {getContentByModuleLocation} from '../../redux/actions/content'

const ForgotPassword = (props) => {

  const [isEmailNotValid, setIsEmailNotValid] = useState(false);
  const [ email, setEmail] = useState('');
  const [ password, setPassword] = useState('');
  const [ updates, setUpdates] = useState(false);

  // AFTER API RESPONSE
  const [errorMsg, setErrorMsg] = useState();
  const [invalidEmail, setInvalidEmail] = useState(false);

  function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  useEffect(()=> {
    props.activeView(4);
  },[email,password,updates])
  
  const [content, setContent] = useState(undefined);

  useEffect(()=> {
    async function getContent(){
      const data = await props.getContentByModuleLocation('forgetPasswordPage'); 
      setContent(data?.forgetPassword?.attributes)
    }

    getContent();
  }, []);

  useEffect(()=> {
    setErrorMsg(props.loader.errorMsg)
    if(props.loader.errorMsg.includes('email')) { 
      setInvalidEmail(true)
    }
    else {
      setInvalidEmail(false)
      setErrorMsg()
    }

  },[props.loader.errorMsg])

  const handleForgot = ({target}) => {
    let {value, name} = target;
    setEmail(value)
  }

  const handleSubmit = async (event, errors, values) => {
    if (!validateEmail(email)) setIsEmailNotValid(true);
    if (!validateEmail(email)) return;
    if(errors.length > 0) console.log('submitError',errors) 
    localStorage.setItem('userEmail',email)
    const { data, error } = await props.forgotPassword(email)
    
    if(data){
      setIsEmailNotValid(false)
      props.showErrorMsg('')
    }

    if(error){
      setIsEmailNotValid(true)
      props.showErrorMsg(error)
    }
    setTimeout(()=>
    {
      props.hideMessage()
    },2000)
  }

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>
      <div className="login-height my-5 pt-sm-5 bggg-bg">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="login-bg bg-soft">
                  <Row>
                    <Col className="col-7">
                      <div className="login-text p-4">
                        <h5 className="login-text">{content?.forgetPasswordHeading}</h5>
                        {/* <p>Sign in to continue to Skote.</p> */}
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="auth-logo">
                  </div>
                  <div className="p-2 pt-4">
                    <AvForm className="form-horizontal" onSubmit={handleSubmit}>
                    <label 
                          style={{color: isEmailNotValid? '#dc3545':'#212529'}}
                        >
                          {content?.emailTitle}
                      </label>
                      <div className="mb-3 position-relative">
                        <input
                          name="email"
                          label="E-mail"
                          value={email}
                          onChange={handleForgot}
                          className="form-control shadow-none"
                          placeholder={`E-mail`}
                          style={{borderColor: isEmailNotValid? '#dc3545':'rgb(206, 212, 218)'}}
                          type="email"
                          required
                        />
                        {isEmailNotValid &&  !invalidEmail && 
                        <div>
                          <Validation_Icon />
                          <div className="invalid-field-label">{content?.invalidValue}</div>
                        </div>
                        }
                        {invalidEmail &&  
                        <div>
                          <Validation_Icon />
                          <div className="invalid-field-label">{errorMsg}</div>
                        </div>
                        }
                      </div>
                      {/* <ErrorMessage error={props.loader.showErrorMsg} msg={props.loader.errorMsg} /> */}

                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-block login-btn shadow-none"
                          type="submit"
                        >
                          {content?.submitButtonText} {props.loader.loader?<i className="fa fa-spinner fa-spin"></i>:''} 
                        </button>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <SuccessMessage  success = {props.loader.showSuccessMsg} msg={props.loader.successMsg} />
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = ({auth,loader}) => ({auth,loader})
export default connect(mapStateToProps, {
userRegistration,
activeView,
forgotPassword,
getContentByModuleLocation,
showErrorMsg,
hideMessage
}) ( ForgotPassword );
