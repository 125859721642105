import * as React from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import ConstructionPeriod from "./ConstructionPeriod";
import Investment from "./Investment";
import Potential from "./Potential/index";
import Energy from "./Energy";
import { potentialTabButtonList } from "./Potential/config";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            backgroundColor: "white",
            textColor: "#54565A",
            p: 5,
            width: "100%",
          }}
        >
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function FullWidthTabs() {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const { municipalityName = null } = useSelector(state => state?.map?.selectedArea)
  // state to store data of all tabs so that we don't need to load it again and again
  const initState = {
    energy: null,
    potential: null,
    investment: null,
    construction: null,
  }
  const [allTabsData, setAllTabsData] = React.useState({ ...initState })
  const [potentialTabBtns, setPotentialTabBtns] = React.useState({ ...potentialTabButtonList })

  const handlePotentialTabBtnClick = (group, key, value) => {
    setPotentialTabBtns({
      ...potentialTabBtns,
      [group]: {
        ...potentialTabBtns[group],
        [key]: value,
      }
    })
  }

  const handleTabData = React.useCallback((tabKey, data) => {
    setAllTabsData({
      ...allTabsData,
      [tabKey]: data
    })
  }, [])

  React.useEffect(() => {
    setAllTabsData({ ...initState })
  }, [municipalityName])

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="secondary"
        textColor="secondary"
        fontWeigth="bold"
        variant="fullWidth"
        sx={{
          borderColor: "gray",
          textColor: "#54565A",
          width: "80%",
          paddingLeft: "7%",
          "& button.Mui-selected": { borderColor: "green" },
          "& label.Mui-selected": { fontSize: '22px' },
        }}
      >
        <Tab
          sx={{
            boxShadow: 3,
            borderLeft: 15,
            borderColor: "gray",
            backgroundColor: "white",
            textColor: "#54565A",
            marginLeft: "30px",
          }}
          label="ENERGIE"
          {...a11yProps(0)}
        />
        <Tab
          sx={{
            borderLeft: 15,
            borderColor: "gray",
            backgroundColor: "white",
            textColor: "#54565A",
          }}
          label="POTENTIEEL"
          {...a11yProps(1)}
        />
        <Tab
          sx={{
            borderLeft: 15,
            borderColor: "gray",
            backgroundColor: "white",
            textColor: "#54565A",
          }}
          label="INVESTERING"
          {...a11yProps(2)}
        />
        <Tab
          sx={{
            borderLeft: 15,
            borderColor: "gray",
            backgroundColor: "white",
            textColor: "#54565A",
          }}
          label="GEBOUWBESTAND"
          {...a11yProps(3)}
        />
        {/*<Tab*/}
        {/*  sx={{*/}
        {/*    borderLeft: 15,*/}
        {/*    borderColor: "gray",*/}
        {/*    backgroundColor: "white",*/}
        {/*    textColor: "#54565A",*/}
        {/*  }}*/}
        {/*  label="Socio-demografiek"*/}
        {/*  {...a11yProps(4)}*/}
        {/*/>*/}
      </Tabs>
      <SwipeableViews
        sx={{
          borderLeft: 15,
          borderColor: "gray",
          backgroundColor: "white",
          textColor: "#54565A",
        }}
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <Energy handleTabData={handleTabData} data={allTabsData?.energy} />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <Potential
            handleTabData={handleTabData}
            data={allTabsData?.potential}
            submenuBtnClick={handlePotentialTabBtnClick}
            potentialTabBtnsState={potentialTabBtns}
          />
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <Investment handleTabData={handleTabData} data={allTabsData?.investment} />
        </TabPanel>
        {/* Gebouwbestand */}
        <TabPanel value={value} index={3} dir={theme.direction}>
          <ConstructionPeriod handleTabData={handleTabData} data={allTabsData?.construction} />
        </TabPanel>
        {/*<TabPanel value={value} index={4} dir={theme.direction}>*/}
        {/*  Socio-demografiek*/}
        {/*</TabPanel>*/}
      </SwipeableViews>
    </>
  );
}
