const renovationKeys = {
    'current situation': 'Huidige toestand',
    'ventD_HPAW': 'Ventilatie + warmtepomp',
    'wallCav_roofL_HPAW_PVmax': 'Spouwmuur + dak + warmtepomp + PV',
    'wallExt_roofL_HPAW_PVmax': 'Gevelisolatie + dak + warmtepomp + PV',
    'window_roofL_HPAW_PVmax': 'Ramen + dak + warmtepomp + PV',
    'window_ventD_HPAW': 'Ramen + ventilatie + warmtepomp',
    'window_ventD_HPAW_PVmax': 'Ramen + ventilatie + warmtepomp + PV',
    'window_wallCav_roofL_ventD_HPAW_PVmax': 'Ramen + spouwmuur+ dak + ventilatie + warmtepomp + PV',
    'window_wallExt_ventD_HPAW_PVmax': 'Ramen + gevelisolatie + ventilatie + warmtepomp + PV'
}

const renovationKeysRaw = {
    "window": "Vervanging ramen",
    "wallCav": "Muurisolatie: spouwisolatie",
    "wallExt": "Muurisolatie: buitengevelisolatie",
    "ventD": "Ventilatiesysteem D",
    "PVA": "Zonnepanelen: minimale hoeveelheid om A-label te bereiken",
    "PVmax": "Zonnepanelen: maximale hoeveelheid op dakoppervlakte",
    "roofL": "Dakisolatie: binnenisolatie",
    "roofExt": "Dakisolatie: buitenisolatie",
    "hpaw": "Warmtepomp",
}

function splitRenovationKeyAndJoinValues(key = null) {
    const lowerCaseRenovationKeyValues = {}

    Object.entries(renovationKeysRaw).forEach(([renovationKey, renovationValue]) => {
        lowerCaseRenovationKeyValues[renovationKey.toLowerCase()] = renovationValue
    });

    if (!key) return key
    return key
        .toString()
        .split("_").map(
            (value) => {
                const lowerCaseValue = value.toLowerCase()
                return lowerCaseRenovationKeyValues[lowerCaseValue] || value
            }
        ).join(" + ") || key
}

function RenovationKeyToValue(key) {
    return renovationKeys[key] || splitRenovationKeyAndJoinValues(key) || key
}

export {
    RenovationKeyToValue,
    splitRenovationKeyAndJoinValues,
}