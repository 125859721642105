import React from 'react';

import Chart, {
    ArgumentAxis,
    Legend,
    Series,
    ValueAxis,
    Label,
    Size,
    Tick,
} from 'devextreme-react/chart';

export const HorizontalBar = ({
    title,
    data,
    rotated,
    name,
    size,
    valueField,
    argumentField,
    barColor,
    titleMap={}
                              }) => {

    const customizeText = (e) => {
        return titleMap[e.value] || e.value;
    }

    return (
        <Chart
            title={title}
            dataSource={data}
            rotated={rotated}
            name={name}
            id="chart"
        >
            <Size height={size}/>
            <ArgumentAxis>
                <Label customizeText={customizeText}/>
            </ArgumentAxis>

            <ValueAxis 
                label={{font: {size: 12}}}
            >
                <Tick visible={true}/>
                <Label visible={true}/>
            </ValueAxis>

            <Series
                name={name}
                valueField={valueField}
                argumentField={argumentField}
                type="bar"
                color={barColor}
            >
                <Label className="text-black-50" visible={true}/>
            </Series>

            <Legend
                visible={true}
                verticalAlignment="bottom"
                horizontalAlignment="center"
                itemsAlignment="center"
                paddingLeftRight={0}
                columnCount={5}
                rowCount={0}
                columnItemSpacing={10} // 20 - default
                itemTextPosition="right"
                font={{size: 12}}
            />

            {/*<Export enabled={true} />*/}

        </Chart>
    );
}
