export const incomeColors = ["#c1e1ed",
        "#bddfec",
        "#b9ddeb",
        "#b4dbea",
        "#b0dae9",
        "#acd8e8",
        "#a8d6e7",
        "#a3d4e6",
        "#9fd2e5",
        "#9bd0e4",
        "#97cee3",
        "#92cce2",
        "#8ecbe1",
        "#8ac9e0",
        "#86c7df",
        "#82c5de",
        "#7dc3dd",
        "#79c1dc",
        "#75bfdb",
        "#71bdda",
        "#6cbcd9",
        "#68bad8",
        "#64b8d7",
        "#60b6d6",
        "#5bb4d5",
        "#57b2d4",
        "#53b0d3",
        "#4faed2",
        "#4badd1",
        "#46abd0",
        "#42a9cf",
        "#3ea7ce",
        "#3aa5cd",
        "#35a3cc",
        "#31a1cb",
        "#2d9fca",
        "#299ec9",
        "#249cc8",
        "#209ac7",
        "#1c98c6"]