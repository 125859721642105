import React from 'react'
import {useSelector} from 'react-redux'

import TabPanelContainer from '../sharedComponents/TabPanelContainer'
import ChartApiService from "../../services/chartApiService"
import MultipleCategoriesHorizontalChart from "../charts/multipleCategoriesHorizontalChart";
import {DoubleBarChart} from "../charts/d3/DoubleBarChart";
import {HorizontalBar} from "../charts/horizontalBar";
import {Stack} from '@mui/material';
import TabsInfoMessage from '../sharedComponents/TabsInfoMessage';
import CustomPaper from "../sharedComponents/cutomPaper";

const valueMap = {
    gas: "% Gas",
    electricity: "% Elektriciteit",
    oil: "% Olie",
    others: "% Andere",
}

const Energy = ({ handleTabData, data }) => {
    const { areaName: statisticalSectorArea, loading: sectorLoading } = useSelector(state => state?.statisticalSector)
    const { municipalityName = null } = useSelector(state => state?.map?.selectedArea)
    const [loading, setLoading] = React.useState(false)
    const [sectLoading, setSectLoading] = React.useState(false)

    React.useEffect(async () => {
        if (["", null, undefined].includes(municipalityName)) {
            handleTabData("energy", null)
            return
        }
        if (![null, undefined].includes(data?.municipality) && (statisticalSectorArea === null || data?.sector !== null)) {
            return
        }
        if (municipalityName.length) {
            setLoading(true)
            const finalData = {
                municipality: null,
                sector: null
            }
            finalData.municipality = await ChartApiService.getEnergyTabChartDataOfMunicipality(municipalityName)
            if (statisticalSectorArea) {
                finalData.sector = await ChartApiService.getEnergyTabChartDataOfMunicipality(statisticalSectorArea, true)
            }
            handleTabData("energy", finalData)
            setLoading(false)
        }

    }, [municipalityName, data, statisticalSectorArea]);

    const NormalGraphs = () => (
        <Stack direction="row" gap={1} justifyContent="space-between" overflow="none" padding="3px">
            <CustomPaper elevation={2} sx={{ padding: 2.5 }}>
                <MultipleCategoriesHorizontalChart
                    data={data?.municipality?.energy_producible_chart}
                    // size={500}
                    size={280}
                    // size={350}
                    rotated={true}
                />
            </CustomPaper>
            <CustomPaper elevation={2} sx={{ padding: 2.5 }}>
                <HorizontalBar
                    size={255}
                    // size={310}
                    title={""}
                    data={data?.municipality?.fuel_consumption_chart}
                    rotated={true}
                    name={"% verbruik voor verwarming"}
                    valueField={"value"}
                    argumentField={"title"}
                    barColor={"#702081"}
                    titleMap={valueMap}
                />
            </CustomPaper>
            <CustomPaper elevation={2} sx={{ padding: 2.5 }}>
                <DoubleBarChart
                    chartData={data?.municipality?.percentage_epc_score_chart}
                    height={186}
                    sectorName={false}
                />
            </CustomPaper>
        </Stack>
    )

    const StatisticalGraphs = () => (
        <Stack direction="row" gap={1} justifyContent="space-between" overflow="none" padding="3px">
            <CustomPaper elevation={2} sx={{ padding: 2.5 }}>
                <MultipleCategoriesHorizontalChart
                    data={data?.sector?.energy_producible_chart}
                    // size={500}
                    size={280}
                    // size={350}
                    rotated={true}
                />
            </CustomPaper>
            <CustomPaper elevation={2} sx={{ padding: 2.5 }}>
                <HorizontalBar
                    size={255}
                    // size={310}
                    title={""}
                    data={data?.sector?.fuel_consumption_chart}
                    // data={data?.fuel_consumption_chart}
                    rotated={true}
                    name={"% verbruik voor verwarming"}
                    valueField={"value"}
                    argumentField={"title"}
                    barColor={"#702081"}
                    titleMap={valueMap}
                />
            </CustomPaper>
            <CustomPaper elevation={2} sx={{ padding: 2.5 }}>
                <DoubleBarChart
                    chartData={data?.sector?.percentage_epc_score_chart}
                    height={186}
                    sectorName={true}
                />
            </CustomPaper>
        </Stack>
    )

    return (
        <TabPanelContainer
            title="ENERGIE"
            description="Op dit tabblad worden enkele grafieken gegenereerd die betrekking hebben
            op het energielandschap van de geselecteerde gemeente. Krijg inzicht in de CO₂-impact
            van de verwarming van het gebouwde patrimonium op basis van de verspreiding van de 
            verwarmingsbronnen. Kijk wat de staat is van het gebouwenbestand in de gemeente door
            de spreiding van de EPC-score van het volledige residentiële patrimonium in de
            gemeente te vergelijken met de gemiddelde score van de provincie."
            showStatisticalSector={![null, "", undefined].includes(statisticalSectorArea)}
            statisticalSectorAreaName={statisticalSectorArea}
            render={data ? <NormalGraphs /> : <TabsInfoMessage {...loading ? { message: "Loading..." } : null} />}
            statisticalSectorRender={data?.sector ? <StatisticalGraphs /> : <TabsInfoMessage {...sectLoading ? { message: "Loading..." } : null} />}
        />
    )
}

export default React.memo(Energy)