import L from "leaflet";
import "../../css/map.css";
import "leaflet/dist/leaflet.css";
import FullWidthTabs from "../tabpanel/tabPanel";
import "leaflet/dist/leaflet.css";
import "../../css/map.css";
import React, { useState, useEffect, useRef } from "react";
import question from "../../assets/question-mark-green.png";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import AlertMessage from "../../helper/otherMessages";
import {getContentByModuleLocation} from "../../redux/actions/content";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from '@mui/material/Grid';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import BasicMap from "./basic_map";
import AdvancedMap from "./advanced_map";
import MapInfoCard from './mapLegend';
import mapApiService from "../../services/mapApiService";
import { getSectorBareLayer } from "../../helper/mapHelpers";
import { SET_SELECTION } from "../../redux/constants/constants";
import { setStatisticalSectorAlert } from "../../redux/actions/map";
import { GEOSERVER_STORE } from "../../redux/constants/ApiEndPoints";
import GraphsAndInfoCard from "./graphsAndInfoCard";

function MapController(props) {

  const [map, setMap] = useState(null);
  const [mapLoading, setMapLoading] = useState(false);
  const [layerClick, setLayerClick] = useState(false);
  const [mapReloads, setMapReloads] = useState(0);

  const [mapView, setMapView] = useState({
    lat: 51.095,
    long: 4.4478,
    zoom: 9,
  });

  const [mapContainerClasses, setMapContainerClasses] = useState([
    "map-container",
  ]);
  const [showMapPagePopup, setShowMapPagePopup] = useState(false);
  const [mapPagePopupMessage, setMapPagePopupMessage] = useState(
      "U kunt nu de locatie van de nieuwe warmtevraag op de kaart toevoegen"
  );
  const [mapPagePopupType, setMapPagePopupType] = useState("info");
  const [mapUpdated, setMapUpdated] = useState(1);

  const [bannerContent, setBannerContent] = useState(undefined)
  const [sidePanelContent, setSidePanelContent] = useState(undefined);

  const [infoCardOpen, setInfoCardOpen] = useState(false);
  const [selectedStatisticalSectorLayer, setSelectedStatisticalSectorLayer]= useState(null)
  const [popupContentData, setPopupContentData] = useState({
    streetName: null,
    epc: null,
    potential: null,
    investment: null,
    buildingTypes: null
  });

  const smallScreen = useMediaQuery("(max-width: 800px)");
  const dispatch = useDispatch()

  useEffect(async() => {
        let {sidePanel, ...cardData} = await props.getContentByModuleLocation("analysisPage");
        setBannerContent(cardData);
        setSidePanelContent(sidePanel?.attributes);
    }, []);

  useEffect(() => {
    let baseMap = L.map("map", {
      zoomControl: false,
      maxZoom: 20,
    }).setView([51.048301133312265, 4.253082275390626], 9);
    let backgroundMapLayer = L.tileLayer(
        "https://{s}.basemaps.cartocdn.com/rastertiles/light_all/{z}/{x}/{y}.png",
        {
          title: "basemap",
          maxZoom: 40,
          attribution:
              `<a href="https://carto.com/about-carto/">© Carto</a>` +
              ", " +
              `<a href="http://osm.org/copyright">© OpenStreetMap </a>` +
              "contributors",
        }
    );
    backgroundMapLayer.addTo(baseMap);
    addMapHandlers(baseMap);
    setMap(baseMap);
  }, []);

  useEffect(() => {
    if (map) map.attributionControl.setPosition('bottomright');
  }, [map]);

  // refresh map to adjust to changed dimensions
  useEffect(() => {
    if (map) setTimeout(() => map.invalidateSize(), 400);
  }, [mapContainerClasses]);

  const addMapHandlers = (baseMap) => {
    baseMap.on("zoomend", (zoomEvent) => {
      setMapView({
        lat: baseMap.getCenter().lat,
        long: baseMap.getCenter().lng,
        zoom: zoomEvent.target._zoom,
      });
    });
    baseMap.on("moveend", (event) => {
      let mapCenterLocal;
      if (map) mapCenterLocal = map.getCenter();
      else mapCenterLocal = baseMap.getCenter();
      setMapView({
        lat: mapCenterLocal.lat,
        long: mapCenterLocal.lng,
        zoom: baseMap.getZoom(),
      });
    });
  };

  const ZoomIn = () => {
    map.setZoom(map.getZoom()+1);
  };

  const ZoomOut = () => {
    map.setZoom(map.getZoom()-1);
  };

  const showPopupOnMap = (text, type = "info") => {
    setMapPagePopupMessage(text);
    setMapPagePopupType(type);
    setShowMapPagePopup(true);
    setTimeout(() => {
      setShowMapPagePopup(false);
    }, 2500);
  };
  
  const popupToggler = () => {
      setLayerClick((layerClick) => !layerClick);
  };

  const openToggler = () => {
    if (!layerClick)
        setLayerClick(true)
  };

  const infoCardToggler = () => {
    setInfoCardOpen((infoCardOpen) => !infoCardOpen);
  };

  const addSelectedStatisticalSectorToMap = React.useCallback((fitToBounds=true)=> {
    if (!selectedStatisticalSectorLayer) return
    if (map.hasLayer(selectedStatisticalSectorLayer))
      map.removeLayer(selectedStatisticalSectorLayer)
    selectedStatisticalSectorLayer.addTo(map)
    selectedStatisticalSectorLayer.bringToFront()
    if (fitToBounds)
      map?.fitBounds(selectedStatisticalSectorLayer.getBounds())
  }, [map, selectedStatisticalSectorLayer])

  React.useEffect(()=> {
      addSelectedStatisticalSectorToMap()
  }, [selectedStatisticalSectorLayer, map])

  React.useEffect(()=> {
      addSelectedStatisticalSectorToMap(false)
  }, [mapView, mapReloads])

  React.useEffect(()=> {
    const areaName = props?.statisticalSector?.areaName;
    ;(async ()=>
    {
        if (map && areaName) {
            const areaRes = await mapApiService.getRequestedMapAreaSimple(null,
                `${GEOSERVER_STORE}:hSect`, "geom", `sect_id='${areaName}'`)
            const mapLayer = getSectorBareLayer(areaRes.data, "selected_sector")
            setSelectedStatisticalSectorLayer(mapLayer)
        }
        else if (map && selectedStatisticalSectorLayer && map.hasLayer(selectedStatisticalSectorLayer)) {
            map.removeLayer(selectedStatisticalSectorLayer)
            setSelectedStatisticalSectorLayer(null)
        }
    })()
  }, [props?.statisticalSector?.areaName])

  
  const handleMapClickForCluster = async (event, municipalityName)=> {
    dispatch({
      type: SET_SELECTION,
      payload: {
        loading: true,
      }
    })

    const bds = map.getBounds();
    const mapSize = map.getSize();
    const w = bds.getNorthEast().lng - bds.getSouthWest().lng;
    const h = bds.getNorthEast().lat - bds.getSouthWest().lat;
    const screenPtX= (((event.latlng.lng - bds.getSouthWest().lng) / w) * mapSize.x).toFixed(0);
    const screenPtY = (((bds.getNorthEast().lat - event.latlng.lat) / h) * mapSize.y).toFixed(0);
    const res = await mapApiService.getClickedMapArea(screenPtX, screenPtY, map.getBounds(), `${GEOSERVER_STORE}:hStreet`, mapSize.x, mapSize.y, 10)
    if (res?.data?.features.length) {
      const selectedSegmentProperties = res.data.features[0].properties
      const selectedSectorId = selectedSegmentProperties.cs01012021
      if (selectedSectorId && selectedSegmentProperties.municipality === municipalityName) {
        dispatch(setStatisticalSectorAlert(true, `Statistische sector ${selectedSectorId} geselecteerd`, 'info'))
        dispatch({
          type: SET_SELECTION,
          payload: {
              selection: false,
              areaName: selectedSectorId,
              loading: false,
          }
        })
      } else {
        dispatch(setStatisticalSectorAlert(true, `Klik op een straat binnen de geselecteerde gemeente`, 'info'))
      }
    } else {
      dispatch(setStatisticalSectorAlert(true, `Er is een probleem opgetreden bij het selecteren van de statistische sector`, 'info'))
    }
    setTimeout(() => {
      dispatch(setStatisticalSectorAlert(false, null, 'info'))
    }, 1700)
  }

  const sectorSelectionHandler = async(event)=> {
    await handleMapClickForCluster(event, props?.map?.selectedArea?.municipalityName)
  }

  React.useEffect(()=> {
    if (map) {
      if (!props?.statisticalSector?.selection) {
        map.off('click')
      }
      else if (props?.statisticalSector?.selection) {
        map.on('click', sectorSelectionHandler)
      }
    }
  }, [props?.statisticalSector?.selection, props?.statisticalSector?.areaName, map])

  return (
    <>
      <div
        className={`map-page-container ${mapLoading ? "hidden" : ""}cyan-bg`}
      >
        <div className={`${mapContainerClasses.join(" ")}`}>
          <div id="map" className={`inner-map `} />
        </div>
        <div className="map-controls-container">
          <BasicMap
              setMapReloads={setMapReloads}
              simulationenv={props?.simulation}
              mymap={map}
              mapView={mapView}
              setMapView={setMapView}
              layerClick={layerClick}
              popupToggler={popupToggler}
              openToggler={openToggler}
              mapUpdated={mapUpdated}
              showPopupOnMapHandler={showPopupOnMap}
              sidePanelContent={sidePanelContent}
              smallScreen={smallScreen}
              popupContentData={popupContentData}
              setPopupContentData={setPopupContentData}
          />
          {props?.simulation && (
              <AdvancedMap
                  mapReloads={mapReloads}
                  map={map}
                  mapView={mapView}
                  layerClick={layerClick}
                  popupToggler={popupToggler}
                  openToggler={openToggler}
                  showPopupOnMapHandler={showPopupOnMap}
                  setPopupContentData={setPopupContentData}
              />
          )}
        </div>
        <div>
          <div className="question-mark-map">
            <button className="p-0 border-0 bg-transparent" style={{ margin:'5px' }}>
              <img
                src={question}
                alt="img"
                width="40px"
                height="40px"
                // onClick={handleToggle}
                onClick={() => infoCardToggler()}
              />
            </button>
            <Grid container direction="column" justifyContent="center" alignItems="center">
              <AddIcon sx={{ width: '30px', height: '30px', margin: '2px', backgroundColor: 'white', borderRadius: '4px', boxShadow: '3' }} onClick={() => {ZoomIn();}}/>
              <RemoveIcon sx={{ width: '30px', height: '30px', margin: '2px', backgroundColor: 'white', borderRadius: '4px', boxShadow: '3' }} onClick={() => {ZoomOut();}}/>
            </Grid>
          </div>
          <GraphsAndInfoCard
            key="graphsAndInfoCard"
            setInfoCardOpen={setInfoCardOpen}
            infoCardOpen={infoCardOpen}
            getContentByModuleLocation={props.getContentByModuleLocation}
          />
        </div>
      </div>
      <AlertMessage
        show={showMapPagePopup}
        message={mapPagePopupMessage}
        type={mapPagePopupType}
      />
      {/* Global alert for Statistical sector */}
      <AlertMessage
          show={props?.statisticalSector?.showAlert}
          message={props?.statisticalSector?.alertMsg}
          type={props?.statisticalSector?.alertType}
      />
    </>
  );
}

const mapStateToProps = ({
  auth,
  map,
  main,
  loader,
  content,
  zoning,
  simulation,
  economicViability,
  statisticalSector,
}, ownProps) => ({
  auth,
  map,
  main,
  loader,
  content,
  zoning,
  simulation,
  economicViability,
  statisticalSector,
  ...ownProps
});

export default connect(mapStateToProps, {
  getContentByModuleLocation,
})(withRouter(MapController));
