import {
    ADD_SIMULATION,
    DELETE_SIMULATION,
    SIMULATION_STATUS,
    UPDATE_SIMULATION,
    UPDATE_SIMULATION_STATUS,
    UPDATE_SIMULATIONS_STATUS,
    ON_SUCCESS_SIMULATION_API,
    UPDATE_NEW_SIMULATION_PARAM,
    REMOVE_ALL_SIMULATIONS,
    ON_ERROR_SIMULATION_API,
    ON_CHANGE_SIMULATION_STATE,
    ON_CHANGE_MUNICIPALITY_WFS_DATA,
    UPDATE_SIMULATIONS_NAME,
    ON_CHANGE_SIMULATION_ID,
    CHECK_RERUN_SIMULATION,
    SET_IS_DISABLE_SIMULATION_RERUN,
    ADD_RENOVATION_PLANS,
} from "../constants/constants"
import { SIMULATION_CLICK_STATE } from "../../constants/simulation";


const INIT_STATE = {
    totalResult: null,
    mapData: null,
    clickState: SIMULATION_CLICK_STATE.AFTER,
    municipalityWFSData: {},
    name: null,
    id: null,
    disableSimulationRerun: true,
    renovationPlans: [],
    data: [
        // {
        //     loading: false,
        //     error: null,
        //     status: SIMULATION_STATUS.COMPLETE,
        //     param: {
        //         typology: 'Vrijstaand huis',
        //         renovation_plan: 'package 1'
        //     },
        //     result: {
        //         subsidies: {
        //             value: 340,
        //             unit: '$'
        //         },
        //         investment: {
        //             value: 340,
        //             unit: '$'
        //         },
        //         annual_savings: {
        //             value: 340,
        //             unit: '$'
        //         },
        //         co2_savings: {
        //             value: 340,
        //             unit: 'Ton'
        //         }
        //     }
        // },
    ],
}

const findSimulationIndexFromParam = (simulations, typology, renovationPlan) => {
    let simulationIndex = null
    simulations?.map((simulation, index) => {
        const { param = {} } = simulation
        if (param?.typology === typology && param?.renovation_plan === renovationPlan) {
            simulationIndex = index
        }
    })
    return simulationIndex
}

const updateObject = (oldObject, newValues) => {
    return Object.assign({}, oldObject, newValues)
}

const simulationReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case ADD_SIMULATION:
            // payload has default values for new simulation
            const { payload } = action
            const updatedSimulations = state.data.concat({ ...payload })

            // const newState = updateObject(state, updatedSimulations)
            return {
                ...state,
                data: updatedSimulations
            }

        case REMOVE_ALL_SIMULATIONS:
            return {
                ...state,
                ...INIT_STATE
            }

        case DELETE_SIMULATION:
            const { payload: simulationIndex } = action
            const simulationClone = [...state.data]
            simulationClone.splice(simulationIndex, 1)

            // check to go-back to muincipility search
            if (simulationClone?.length === 0) {
                return {
                    ...state,
                    ...INIT_STATE
                }
            }

            return {
                ...state,
                data: simulationClone,
            }

        case UPDATE_SIMULATIONS_STATUS:
            const { payload: { params, newStatus } } = action
            const updatedState = [...state.data]
            params.map((param) => {
                const { typology, renovation_plan } = param
                const simulationIndex = findSimulationIndexFromParam(state?.data, typology, renovation_plan)
                updatedState[simulationIndex]["status"] = newStatus
            })

            return {
                ...state,
                data: updatedState
            }

        case UPDATE_SIMULATIONS_NAME:
            const { payload: nameData } = action
            return {
                ...state,
                name: nameData.name
            }

        case ON_CHANGE_SIMULATION_ID:
            const { payload: idData } = action
            return {
                ...state,
                id: idData.id
            }

        case UPDATE_NEW_SIMULATION_PARAM:
            const { payload: updatePayload } = action
            // update params of previously added simulation
            // this is important that only one simulation has 'NEW' state
            const { types, plan: selectedPlan } = updatePayload
            const newlyAddedSimulations = []

            let newSimulation = state?.data?.filter((simulation) => simulation.status === SIMULATION_STATUS.NEW)

            if (newSimulation?.length !== 1) return state
            newSimulation = newSimulation[0]

            // create new simulation for each type
            types.map((type) => {
                newSimulation = {
                    ...newSimulation,
                    param: {
                        typology: type,
                        renovation_plan: selectedPlan
                    },
                    status: SIMULATION_STATUS.PARAM_ADDED
                }
                newlyAddedSimulations.push(newSimulation)
            })

            // new simulation is at the last index
            const simulationClone_ = [...state?.data]
            simulationClone_?.pop()

            newlyAddedSimulations?.map((simulation) => {
                simulationClone_.push({ ...simulation })
            })

            return {
                ...state,
                data: simulationClone_
            }

        case ON_SUCCESS_SIMULATION_API:
            const { payload: { data: responsePayload } } = action

            const {
                total_result: totalResult,
                simulation_results: simulationResult = null,
                map_data: mapData = null
            } = responsePayload


            const responseStateClone = [...state?.data]
            if (!simulationResult) return state

            simulationResult?.map((simulation) => {
                const {
                    param: { typology, renovation_plan },
                    result
                } = simulation
                const simulationIndex = findSimulationIndexFromParam(state?.data, typology, renovation_plan)
                responseStateClone[simulationIndex]["result"] = result
            })


            // a function to calculate total results
            // recalculate totalResult

            return {
                ...state,
                data: responseStateClone,
                totalResult,
                mapData
            }

        case ON_ERROR_SIMULATION_API:
            const { onlyParams, response } = action?.payload
            const { message = "Something went wrong." } = response
            const simulationsClone = [...state?.data]

            onlyParams.map((param) => {
                const { typology, renovation_plan } = param
                const simulationIndex = findSimulationIndexFromParam(state?.data, typology, renovation_plan)
                simulationsClone[simulationIndex]["status"] = SIMULATION_STATUS.ERROR
                simulationsClone[simulationIndex]["error"] = message
            })

            return {
                ...state,
                data: simulationsClone
            }

        case ON_CHANGE_SIMULATION_STATE:
            const { clickState } = action?.payload

            return {
                ...state,
                clickState: clickState
            }

        case ON_CHANGE_MUNICIPALITY_WFS_DATA:
            const { wfsData, layerName } = action?.payload

            return {
                ...state,
                municipalityWFSData: {
                    ...state.municipalityWFSData,
                    [layerName]: wfsData
                }
            }

        case CHECK_RERUN_SIMULATION:
            const { currentDeletedSimulation } = action?.payload

            // comparing indexes
            const lastIndexOfSimulation = state.data.length - 1
            if( currentDeletedSimulation === lastIndexOfSimulation ){
                return {
                    ...state,
                    disableSimulationRerun: false,
                }
            }

            return {
                ...state
            }

        case SET_IS_DISABLE_SIMULATION_RERUN:
            const { value } = action?.payload

            return {
                ...state,
                disableSimulationRerun: value,
            }

        case ADD_RENOVATION_PLANS:
            const renovationPlans = action.payload

            return {
                ...state,
                renovationPlans: renovationPlans,
            }


        default:
            return state
    }
}

export default simulationReducer
