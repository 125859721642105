import { Tooltip, styled, tooltipClasses } from '@mui/material';
import React from 'react'

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} arrow={true}/>
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
    //   backgroundColor: '#f5f5f9',
    //   color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }));

export default HtmlTooltip