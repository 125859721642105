import React from 'react'
import { SEARCH_BAR_TYPES } from "../../constants/map";
import { Grid } from "@material-ui/core";
import { SearchBar } from "../homePage/searchBar";
import { ROUTES } from "../../constants/routes";
import { useHistory } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { activeView } from "../../redux/actions/auth";
import { Box } from '@mui/material';
import { SET_SELECTION } from '../../redux/constants/constants';

function MapSearchBar(props) {

    const history = useHistory();
    const dispatch = useDispatch()

    const searchCallBack = () => {
        // reset statistical sector feature
        dispatch({
            type: SET_SELECTION,
            payload: {
                selection: false,
                areaName: null,
                loading: null,
            }
          })
    }

    return (
            <Grid
                container
                className="sidepanel-container"
                justifyContent="flex-end"
            >
                <Grid container justifyContent="flex-end" item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Box style={{pointerEvents: "auto"}}>
                    <SearchBar
                        searchBarType={SEARCH_BAR_TYPES.WITH_SEARCH_ICON}
                        searchBarClickHandler={()=>
                            history.push(ROUTES.MAP_PAGE)
                        }
                        placeHolderText={'Gent'}
                        setActiveView={props.activeView}
                        searchCallBack={searchCallBack}
                    />
                    </Box>
                </Grid>
            </Grid>

  )
}

const mapStateToProps = ({ auth }) => ({
  auth,
});
export default connect(mapStateToProps, {
  activeView,
})(MapSearchBar);