import {useEffect, useState} from "react";
import {connect, useSelector} from "react-redux";
import {GEOSERVER_STORE} from "../../../redux/constants/ApiEndPoints";
import mapApiService from "../../../services/mapApiService";
import {EPC_LABEL_TO_DB_MAPPING_NEW, MAP_LAYERS} from "../../../constants/map";
import {
    getSectorHeatLayer, getSectorInvestmentLayer, getStreetPotentialLayer,
    getStreetHeatLayer, getStreetInvestmentLayer, getStreetSearchDirectionLayer
} from "../../../helper/mapHelpers";
import SimulationComparison from './SimulationComparison';
import {SIMULATION_CLICK_STATE} from "../../../constants/simulation";


const SimulationVisualization = ({
    openToggler = () => { },
    setPopupContentData,
    ...props
}) => {

    const [streetsSimulatedHeatStateLayer, setStreetsSimulatedHeatStateLayer] = useState(null);
    const [streetsCurrentHeatStateLayer, setStreetsCurrentHeatStateLayer] = useState(null);
    const [sectsSimulatedHeatStateLayer, setSectsSimulatedHeatStateLayer] = useState(null);
    const [streetsSearchDirectionLayer, setStreetsSearchDirectionLayer] = useState(null);
    const [sectsCurrentHeatStateLayer, setSectsCurrentHeatStateLayer] = useState(null);
    const [streetsPotentialLayer, setStreetsPotentialLayer] = useState(null);
    const [streetsInvestmentLayer, setStreetsInvestmentLayer] = useState(null);
    const [sectsInvestmentLayer, setSectsInvestmentLayer] = useState(null);


    const selectedStreetLayer = useSelector((state) => state.map.selectedStreetLayer);
    const selectedSectorLayer = useSelector((state) => state.map.selectedSectorLayer);
    const selectedArea = useSelector((state) => state.map.selectedArea);
    const mapData = useSelector((state) => state.simulation.mapData);
    const simulationViewState = useSelector((state) => state.simulation.clickState);

    const getUpdatedHeatStreets = (wfsLayer) => {
        if (mapData) {
            let listId = [];
            wfsLayer.features.forEach((feature) => {
                try {
                    const streetData = mapData.streets[feature.properties.segment_id]
                    feature.properties.epc_label = Math.ceil(streetData.epc / 100) * 100
                }
                catch (err) {
                    listId.push(feature.properties.segment_id)
                }
            });
            console.log('List of missing IDs in Heat Streets:', listId);
        }
        return wfsLayer
    };

    const getUpdatedHeatSects = (wfsLayer) => {
        if (mapData) {
            let listId = [];
            wfsLayer.features.forEach((feature) => {
                try {
                    const sectData = mapData.sectors[feature.properties.sect_id]
                    feature.properties.epc_label = Math.ceil(sectData.epc / 100) * 100
                } catch (err) {
                    listId.push(feature.properties.sect_id)
                }
            });
            console.log('List of missing IDs in Heat Sectors:', listId);
        }
        return wfsLayer
    };

    const getUpdatedInvestmentSects = (wfsLayer) => {
        if (mapData) {
            let listId = [];
            wfsLayer.features.forEach((feature) => {
                try {
                    const sectData = mapData.sectors[feature.properties.sect_id]
                    feature.properties.total_cost = sectData.investment
                } catch (err) {
                    listId.push(feature.properties.sect_id)
                }
            });
            console.log('List of missing IDs in Investment Sectors:', listId);
        }
        return wfsLayer
    };

    const getUpdatedPotentialStreets = (wfsLayer) => {
        return wfsLayer
    };

    // const getUpdatedSearchDirectionStreets = (wfsLayer) => {
    //     return wfsLayer
    // };

    const getUpdatedInvestmentStreets = (wfsLayer) => {
        if (mapData) {
            let listId = [];
            wfsLayer.features.forEach((feature) => {
                try {
                    const streetData = mapData.streets[feature.properties.segment_id]
                    feature.properties.total_cost = streetData.investment
                } catch (err) {
                    listId.push(feature.properties.segment_id)
                }
            });
            console.log('List of missing IDs in Investment Streets:', listId);
        }
        return wfsLayer
    };

    const handleSimulationMapLoading = async (isMounted) => {

        let bounds = props.mymap.getBounds();

        if (selectedStreetLayer === MAP_LAYERS.CURRENT_HEAT_STATE_STREETS) {
            const streetCurrentHeatRes = await mapApiService.getRequestedMapArea(
                bounds,
                `${GEOSERVER_STORE}:hStreet`,
                "geom",
                `municipality=${selectedArea.municipalityName}`
            );
            if (streetCurrentHeatRes) {
                let heatStreets = getStreetHeatLayer(streetCurrentHeatRes.data);
                let newHeatStreets = await getUpdatedHeatStreets(streetCurrentHeatRes.data);
                let updatedHeatStreets = getStreetHeatLayer(newHeatStreets);
                if (heatStreets && simulationViewState === SIMULATION_CLICK_STATE.BEFORE) {
                    setStreetsCurrentHeatStateLayer(heatStreets);
                } else if (updatedHeatStreets && simulationViewState === SIMULATION_CLICK_STATE.AFTER) {
                    setStreetsSimulatedHeatStateLayer(updatedHeatStreets);
                }
                if (updatedHeatStreets) {
                    updatedHeatStreets.on('click', async (e) => {
                        const streetId = e?.layer?.feature?.properties?.segment_id
                        if (!streetId) {
                            console.log('Faulty street click', e)
                            return
                        }
                        const streetInfo = await mapApiService.getStreetInfo(streetId)
                        if (isMounted) {
                            if (streetInfo?.data) {
                                setPopupContentData({
                                    streetName: streetInfo?.data?.name,
                                    epc: EPC_LABEL_TO_DB_MAPPING_NEW[e.layer?.feature.properties.epc_label] || ((e.layer?.feature.properties.epc_label < 100) ? 'A' : 'F'),
                                    potential: streetInfo?.data?.potential,
                                    investment: mapData?.streets[streetId]?.investment,
                                    buildingTypes: streetInfo?.data?.building_types_ratio
                                })
                            } else {
                                setPopupContentData({});
                            }
                            openToggler();
                        }
                    })
                }
                if (heatStreets && mapData) {
                    heatStreets.on('click', async (e) => {
                        const streetId = e.layer.feature.properties.segment_id
                        if (!streetId) {
                            console.log('Faulty street click', e)
                            return
                        }
                        const streetInfo = await mapApiService.getStreetInfo(streetId)
                        if (isMounted) {
                            if (streetInfo?.data) {
                                setPopupContentData({
                                    streetName: streetInfo?.data?.name,
                                    epc: EPC_LABEL_TO_DB_MAPPING_NEW[e.layer.feature.properties.epc_label] || ((e.layer.feature.properties.epc_label < 100) ? 'A' : 'F'),
                                    potential: streetInfo?.data?.potential,
                                    investment: mapData?.streets[streetId]?.investment,
                                    buildingTypes: streetInfo?.data?.building_types_ratio
                                })
                            } else {
                                setPopupContentData({});
                            }
                            openToggler();
                        }
                    })
                }
            }
        }

        if (selectedSectorLayer === MAP_LAYERS.CURRENT_HEAT_STATE_SECTORS) {
            const sectCurrentHeatRes = await mapApiService.getRequestedMapArea(
                bounds,
                `${GEOSERVER_STORE}:hSect`,
                "geom",
                `municipality=${selectedArea.municipalityName}`
            );
            if (sectCurrentHeatRes) {
                let heatSects = getSectorHeatLayer(sectCurrentHeatRes.data, selectedStreetLayer);
                let newHeatSects = await getUpdatedHeatSects(sectCurrentHeatRes.data)
                let updatedHeatSects = getSectorHeatLayer(newHeatSects, selectedStreetLayer);
                if (heatSects && simulationViewState === SIMULATION_CLICK_STATE.BEFORE) {
                    setSectsCurrentHeatStateLayer(heatSects);                   
                } else if (updatedHeatSects && simulationViewState === SIMULATION_CLICK_STATE.AFTER) {
                    setSectsSimulatedHeatStateLayer(updatedHeatSects);
                }
            }
        }

        if (selectedStreetLayer === MAP_LAYERS.INVESTMENT_STREETS) {
            const streetInvestmentRes = await mapApiService.getRequestedMapArea(
                bounds,
                `${GEOSERVER_STORE}:IStreet`,
                "geom",
                `municipality=${selectedArea.municipalityName}`
            );
            if (streetInvestmentRes) {
                let updatedInvestmentStreets = await getUpdatedInvestmentStreets(streetInvestmentRes.data)
                updatedInvestmentStreets = getStreetInvestmentLayer(updatedInvestmentStreets);
                if (updatedInvestmentStreets) {
                    setStreetsInvestmentLayer(updatedInvestmentStreets);
                }
                if (updatedInvestmentStreets) {
                    updatedInvestmentStreets.on('click', async (e) => {
                        const streetId = e.layer.feature.properties.segment_id;
                        if (!streetId) {
                            console.log('Faulty street click', e)
                            return
                        }
                        const epc = Math.ceil(mapData?.streets[streetId].epc / 100) * 100;
                        const streetInfo = await mapApiService.getStreetInfo(streetId);
                        if (streetInfo?.data) {
                                setPopupContentData({
                                    streetName: streetInfo?.data?.name,
                                    epc: EPC_LABEL_TO_DB_MAPPING_NEW[Math.ceil(mapData?.streets[streetId].epc / 100) * 100] || ((Math.ceil((mapData?.streets[streetId].epc / 100) * 100) < 100) ? 'A' : 'F'),
                                    potential: streetInfo?.data?.potential,
                                    investment: mapData?.streets[streetId].investment,
                                    buildingTypes: streetInfo?.data?.building_types_ratio
                                })
                            } else {
                                setPopupContentData({});
                            }
                            openToggler();
                    })
                }
            }
        }

        if (selectedSectorLayer === MAP_LAYERS.INVESTMENT_SECTORS) {
            const sectInvestmentRes = await mapApiService.getRequestedMapArea(
                bounds,
                `${GEOSERVER_STORE}:ISect`,
                "geom",
                `municipality=${selectedArea.municipalityName}`
            );
            if (sectInvestmentRes) {
                let updatedInvestmentSects = await getUpdatedInvestmentSects(sectInvestmentRes.data)
                setSectsInvestmentLayer(getSectorInvestmentLayer(updatedInvestmentSects, selectedStreetLayer))
            }
        }

        if (selectedStreetLayer === MAP_LAYERS.POTENTIAL_STREETS) {
            const streetPotentialRes = await mapApiService.getRequestedMapArea(
                bounds,
                `${GEOSERVER_STORE}:PStreet`,
                "geom",
                `municipality=${selectedArea.municipalityName}`
            );
            if (streetPotentialRes) {
                let updatedPotentialStreets = getStreetPotentialLayer(streetPotentialRes.data)
                if (updatedPotentialStreets) {
                    setStreetsPotentialLayer(updatedPotentialStreets);
                }
                if (updatedPotentialStreets) {
                    updatedPotentialStreets.on('click', async (e) => {
                        const streetId = e.layer.feature.properties.segment_id;
                        if (!streetId) {
                            console.log('Faulty street click', e)
                            return
                        }
                        const epc = Math.ceil(e.layer.feature.properties?.epc_label / 100) * 100;
                        const streetInfo = await mapApiService.getStreetInfo(streetId);
                        if (streetInfo?.data) {
                                setPopupContentData({
                                    streetName: streetInfo?.data?.name,
                                    epc: EPC_LABEL_TO_DB_MAPPING_NEW[e.layer.feature.properties.epc_label] || ((e.layer.feature.properties.epc_label < 100) ? 'A' : 'F'),
                                    potential: streetInfo?.data?.potential,
                                    investment: mapData?.streets[streetId].investment,
                                    buildingTypes: streetInfo?.data?.building_types_ratio
                                })
                            } else {
                                setPopupContentData({});
                            }
                            openToggler();
                    })
                }
            }
        }

        if (selectedStreetLayer === MAP_LAYERS.DIRECTION_STREETS) {
            const streetSearchDirectionRes = await mapApiService.getRequestedMapArea(
                bounds,
                `${GEOSERVER_STORE}:cluster_streets`,
                "geom",
                `municipality=${selectedArea.municipalityName}`
            );
            if (streetSearchDirectionRes) {
                let updatedSearchDirectionStreets = getStreetSearchDirectionLayer(streetSearchDirectionRes.data)
                if (updatedSearchDirectionStreets) {
                    setStreetsSearchDirectionLayer(updatedSearchDirectionStreets);
                }
                if (updatedSearchDirectionStreets) {
                    updatedSearchDirectionStreets.on('click', async (e) => {
                        const streetId = e.layer.feature.properties.segment_id;
                        if (!streetId) {
                            console.log('Faulty street click', e)
                            return
                        }
                        const streetInfo = await mapApiService.getStreetInfo(streetId);
                        if (streetInfo?.data) {
                            setPopupContentData({
                                streetName: streetInfo?.data?.name,
                                epc: EPC_LABEL_TO_DB_MAPPING_NEW[Math.ceil(streetInfo?.data?.epc_label/ 100) * 100] || ((Math.ceil((streetInfo?.data?.epc_label/ 100) * 100) < 100) ? 'A' : 'F'),
                                potential: streetInfo?.data?.potential,
                                investment: mapData?.streets[streetId].investment,
                                buildingTypes: streetInfo?.data?.building_types_ratio
                            })
                        } else {
                            setPopupContentData({});
                        }
                        openToggler();
                    })
                }
            }
        }
    };

    useEffect(async () => {
        let isMounted = true;

        if (props.mymap && mapData) {
            await handleSimulationMapLoading(isMounted);
        };

        return () => {
            isMounted = false;
        };
    }, [
        selectedStreetLayer,
        selectedSectorLayer,
        simulationViewState,
        props.mapView,
        mapData,
    ]);


    return (
        <>
            {!["", null, undefined].includes(mapData) ? (
                <SimulationComparison
                    mymap={props.mymap}
                    mapData={mapData}
                    mapView={props.mapView}
                    streetsSimulatedHeatStateLayer={streetsSimulatedHeatStateLayer}
                    streetsCurrentHeatStateLayer={streetsCurrentHeatStateLayer}
                    sectsSimulatedHeatStateLayer={sectsSimulatedHeatStateLayer}
                    sectsCurrentHeatStateLayer={sectsCurrentHeatStateLayer}
                    streetsInvestmentLayer={streetsInvestmentLayer}
                    sectsInvestmentLayer={sectsInvestmentLayer}
                    streetsPotentialLayer={streetsPotentialLayer}
                    streetsSearchDirectionLayer={streetsSearchDirectionLayer}
                />
            ) : null}
        </>
    )
};

const mapStateToProps = ({map}, ownProps) => ({
    map,
    ...ownProps
});
export default connect(mapStateToProps, {})(SimulationVisualization);