import { SET_PRODUCT_DEMO_STATE } from "../constants/constants"


export const setStoreState = (payload) => {
    return {
        type: SET_PRODUCT_DEMO_STATE,
        payload
    }
}

