

export const getCookieItem = (attrName)=> {
    let nameInCookie = attrName + "=";
    let CookieToSearch = decodeURIComponent(document.cookie);
    let splittedCookieItems = CookieToSearch.split(';');
    for(let i = 0; i <splittedCookieItems.length; i++) {
        let currentCookieItem = splittedCookieItems[i];
        while (currentCookieItem.charAt(0) == ' ') {
            currentCookieItem = currentCookieItem.substring(1);
        }
        if (currentCookieItem.indexOf(nameInCookie) == 0) {
            return currentCookieItem.substring(nameInCookie.length, currentCookieItem.length);
        }
    }
    return "";
}

export const getAuthenticationErrors = (errorsList)=> {
    const emailErrors = []
    const passwordErrors = []
    const accessErrors = []
    for (const error of errorsList) {
        if (error.includes('EMAIL') || error.includes('e-mailadres') || error.includes('email')) {
            emailErrors.push(error)
        } else if (error.includes('no authority')) {
            accessErrors.push(error)
        }
        else {
            passwordErrors.push(error)
        }
    }
    return {emailErrors, passwordErrors, accessErrors}
}
