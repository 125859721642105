import { Button, Grid, styled } from '@mui/material'
import React from 'react'
import { potentialTabButtonList } from './config'

const ButtonStyled = styled(Button)(({ active }) => ({
    padding: "8px 28px",
    boxShadow: '0px 0px 10px #00000029',
    borderRadius: '2px',
    textTransform: "none",
    font: "normal normal 300 14px/24px Barlow",
    color: active ? "#fff !important" : "#707070 !important",
    background: active ? "#66B654 0% 0% no-repeat padding-box" : "#FFFFFF 0% 0% no-repeat padding-box",

    ":hover": {
        background: "#427c35 0% 0% no-repeat padding-box",
        color: active ? "#fff !important" : "#fff !important",
    }
}))

const ButtonItem = ({ _key, value, onClick, potentialTabBtnsState }) => {
    const handleClick = () => {
        // toggle state
        onClick(_key, 'active', !potentialTabBtnsState[_key]?.active)
    }

    return (
        <Grid item >
            <ButtonStyled key={_key} active={potentialTabBtnsState[_key].active} onClick={handleClick}>{value}</ButtonStyled>
        </Grid>
    )
}

const ToggleButtons = ({ submenuBtnClick, potentialTabBtnsState }) => {
    return (
        <Grid container gap={2} justifyContent="center" marginBottom={4}>{
            Object.entries(potentialTabButtonList).map(([key, btn]) => (
                <ButtonItem key={key} _key={key} value={btn.value} onClick={submenuBtnClick} potentialTabBtnsState={potentialTabBtnsState} />
            ))}
        </Grid>
    )
}

export default ToggleButtons