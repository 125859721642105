export const textWithCapitalFirstLetter = (text)=> {
    return text.charAt(0).toUpperCase() + text.toLowerCase().slice(1)
}

export const convertJSListToStringifiedTuple = (inputList)=> {
    return `('${inputList.join("','")}')`
}

export const valueExistsInListOfObjects = (listOfObjects, key, value, parent_key=null)=> {  // parent key in case of nesting
    for (const item of listOfObjects) {
        let valueToCheck = null
        if (parent_key)
            valueToCheck = item[parent_key][key]
        else
            valueToCheck = item[key]
        if (valueToCheck === value)
            return true
    }
    return false
}

export const dutchFormatNumber = (number)=> {
    return new Intl.NumberFormat('de-DE').format(number)
}

export const getKeyAgainstValue = (value, obj)=> {
    return Object.keys(obj)[Object.values(obj).indexOf(value)]
}

export const getCookieValue = (name) => (
    document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || ''
)