import {
    SIMULATION_STATUS,
} from "../../constants/map";
import {
    ADD_RENOVATION_PLANS,
    SELECTED_SIMULATION_DETAILS, SIMULATION_CASE,
    SIMULATION_HEAT_DEMAND,
    SIMULATION_PARAMS,
    SIMULATION_STATUS_TOGGLE
} from "../constants/constants";
import simulationService from "../../services/simulationService";
import SimulationServices from "../../services/advancedMap";

export const setSimulationParams = (value) => {
    return {
        type: SIMULATION_PARAMS,
        payload: {
            ...value
        }
    }
}
export const setSimulationStatus = (simStatus) => {
    return {
        type: SIMULATION_STATUS_TOGGLE,
        payload: simStatus
    }
}
export const setSimulationHeatDemand = (value) => {
    return {
        type: SIMULATION_HEAT_DEMAND,
        payload: value
    }
}
export const setSelectedSimulationDetails = (value) => {
    return {
        type: SELECTED_SIMULATION_DETAILS,
        payload: value
    }
}

export const getRenovationPlans = () => {
    return async (dispatch) => { 
        const { data } = await SimulationServices.getPlans()

        dispatch({
            type: ADD_RENOVATION_PLANS,
            payload: data
        })
    }
}
