import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const TabsInfoMessage = ({
    // Search a municipality to see more information.
    message = "Zoek een gemeente om meer informatie te zien.",
}) => {
    return (
        <Box>
            <Stack direction="row" gap={0.5} alignItems="center">
                <InfoOutlinedIcon fontSize='small' sx={{ fill: "#84c2d9" }} />
                <Typography variant="body1">{message}</Typography>
            </Stack>
        </Box>
    )
}

export default TabsInfoMessage