import React, {createContext, useCallback} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid, IconButton, Stack, Typography, styled } from "@mui/material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SearchContainer from './SearchContainer';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import Collapse from '@mui/material/Collapse';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import close from "../../../assets/used/close.svg";
import Results from './Results';
import SimulationVisualization from './MapDataVisualization';
import {
    addSimulation,
    runSimulations,
    setIsDisableSimulationRerun,
    updateNewSimulationParam, updateSimulationId,
    updateSimulationName
} from '../../../redux/actions/advancedMap';
import {SIMULATION_STATUS} from '../../../redux/constants/constants';
import VerticalRibbon from './VerticalRibbon';
import SimulationPackages from './SimulationPackages';
import SimulationHeaders from './SimulationHeaders';
import TypologyItem from './TypologyItem';
import SimulationServices from "../../../services/advancedMap";
import {setSelectedArea } from '../../../redux/actions/map';
import ProductDemo from '../../ProductDemo';
import { setStoreState } from '../../../redux/actions/productDemo';
import { PopupForm } from "../../sharedComponents/popupForm";
import simulationService from "../../../services/simulationService";
import {
    makePayloadForSimulationAPI
} from "../../../helper/mapHelpers";
import SimulationRun from './SimulationRun';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import HtmlTooltip from '../../sharedComponents/HtmlTooltip';
import { TooltipTitles } from './TooltipsText';
import { getRenovationPlans } from '../../../redux/actions/simulation';
import TabsInfoMessage from '../../sharedComponents/TabsInfoMessage';
import mapApiService from "../../../services/mapApiService";
import { GEOSERVER_STORE } from "../../../redux/constants/ApiEndPoints";
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import DownloadButton from '../../sharedComponents/downloadButton';

export const RemoveLastSimulationHandlerContext = createContext(()=> {})


const Container = styled(Box)({
    zIndex: 1,
    position: "relative",
    float: "left",
    left: "46px",
    top: "24px",
    paddingBottom: "40px"
})

const PlayCircleIconStyled = styled(PlayCircleIcon)({
    fill: "#707070",
    padding: "2px",
    background: "white",
    height: "30px",
    width: "30px",
    borderRadius: "24px",
    boxShadow: "0px 0px 7px 2px #9797979c",
})

function removeItemOnce(arr, value) {
    var index = arr.indexOf(value);
    if (index > -1) {
        arr.splice(index, 1);
    }
    return arr;
}

const AdvancedMap = ({
    map,
    openToggler = () => { },
    showPopupOnMapHandler,
    mapView,
    setPopupContentData
}) => {
    const [activeCategoryTitles, setActiveCategoryTitles] = React.useState([])
    const [showTypologies, setShowTypologies] = React.useState(false)
    const [disableAddNewButton, setDisableAddNewButton] = React.useState(false)
    const [selectedPlan, setSelectedPlan] = React.useState(null)
    // types that are already selected in previous simulations
    const [alreadySelectedIds, setAlreadySelectedIds] = React.useState([])
    const [simulationNameFormOpen, setSimulationNameFormOpen] = React.useState(false);
    const [simulationSaving, setSimulationSaving] = React.useState(false);
    const [resultToggler, setResultToggler] = React.useState(false);
    const [hidePanel, setHidePanel] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const dispatch = useDispatch()
    const { municipalityName = null } = useSelector(state => state?.map?.selectedArea)
    const { productDemo: productDemoState } = useSelector(state => state)
    const { simulation: simulationState } = useSelector(s => s)
    const { disableSimulationRerun, renovationPlans } = useSelector(state => state.simulation)
    const [typologies, setTypologies] = React.useState([])
    const smallWidth = useMediaQuery("(max-width: 1550px)");
    const smallHeight = useMediaQuery("(max-height: 800px)");    

    React.useEffect(()=> {
        if (simulationState?.data?.length)
            setResultToggler(true)
    }, [simulationState?.data])

    React.useEffect(() => {
        // find the simulations with status 'LOADING' and 'COMPLETE'
        const inFocusFilter = [SIMULATION_STATUS.COMPLETE, SIMULATION_STATUS.LOADING, SIMULATION_STATUS.PARAM_ADDED]

        const previousTypologyTitles = []
        simulationState?.data?.map((simulation) => {
            const { status } = simulation

            if (inFocusFilter.includes(status)) {
                // get their 'typology' from 'param'
                const { param = {} } = simulation
                const { typology = null } = param
                if (typology) {
                    previousTypologyTitles.push(typology)
                }
            }
        })
        setAlreadySelectedIds(previousTypologyTitles)

        // find the simulations with status 'NEW', 'PARAM_ADDED', 'LOADING', 'COMPLETE'
        const filters = [SIMULATION_STATUS.NEW, SIMULATION_STATUS.PARAM_ADDED, SIMULATION_STATUS.LOADING, SIMULATION_STATUS.COMPLETE]
        const newSimulations = simulationState?.data?.filter(({ status }) => (filters.includes(status)))
        setShowTypologies(newSimulations?.length > 0)

        // reset 'Selected Area' from 'map' reducer

        // find the simulations with status 'NEW'

        
        let newSims = 0
        let runningSims = 0
        let completedSims = 0
        for (const sim of simulationState.data) {
            if (sim.status === SIMULATION_STATUS.COMPLETE)
                completedSims += 1
            if (sim.status === SIMULATION_STATUS.LOADING)
                runningSims += 1
            if (sim.status === SIMULATION_STATUS.NEW)
                newSims += 1
        }
        setDisableAddNewButton((completedSims === typologies.length) || runningSims || newSims)

        // handle scenario toggle on simulation data change
        // empty activeCategoryTitles when there are no 'NEW' simulation
        const newSimulationsOnly = simulationState.data.filter(({status}) => SIMULATION_STATUS.NEW === status)
        if(newSimulationsOnly.length === 0){
            setActiveCategoryTitles([])
        }

    }, [simulationState])
    
    const getZip = async () => {
        
        const bounds = map.getBounds()
        const zip = new JSZip();

        const urlShp = await mapApiService.getRequestedMapArea(null, `${GEOSERVER_STORE}:shp_dwn`, "geom", `municipality=${municipalityName}`, "shapefile", true, 31370);        
        let shpResponse = await fetch(urlShp);
        let shpData = await shpResponse.blob();

        zip.file(`${municipalityName}.zip`, shpData);         // add into the zip file
        const otherFiles = zip.folder("Additional_files");  // create another folder inside the zip file

        const urlCsv = await mapApiService.getRequestedMapArea(null, `${GEOSERVER_STORE}:csv_dwn`, null, `municipality=${municipalityName}`, "csv", true);        
        let csvResponse = await fetch(urlCsv);
        let csvData = await csvResponse.blob();
        otherFiles.file(`${municipalityName}.csv`, csvData);

        zip.generateAsync({type:"blob"})
        .then(function(content) {
            saveAs(content, `${municipalityName}`);
        });
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }

    const getTypologiesAsync = async () => {
        const { data } = await SimulationServices.getTypologies(municipalityName || 'Antwerpen')
        setTypologies(data)
    }

    const simulationSaveEligible = React.useMemo(()=> {
        return simulationState?.data.filter(simulation=> simulation.status === 'COMPLETE').length
    }, [simulationState?.data])

    React.useEffect(async () => {
        // call typologies API
        getTypologiesAsync()

        // packages/plans API
        if (renovationPlans?.length === 0 && municipalityName.length) {
            dispatch(getRenovationPlans())
        }
        // add simulation when there is a valid 'municipalityName'
        if (!(["", null, undefined].includes(municipalityName)) && !simulationState.data.length) {
            dispatch(addSimulation())
        }
    }, [municipalityName])

    const toggleCategory = (title) => {
        let activeCategoryIdsClone = [...activeCategoryTitles]

        if (activeCategoryTitles.includes(title)) {
            const newActiveCategoryIds = removeItemOnce(activeCategoryIdsClone, title)
            setActiveCategoryTitles(newActiveCategoryIds)
        } else {
            activeCategoryIdsClone.push(title)
            setActiveCategoryTitles(activeCategoryIdsClone)
        }
    }

    const addNewSimulationHandler = React.useCallback(() => {
        if (selectedPlan) {
            dispatch(updateNewSimulationParam(activeCategoryTitles, selectedPlan))
        }
        dispatch(addSimulation())
        dispatch(setIsDisableSimulationRerun(true))
        setSelectedPlan(null)
        setActiveCategoryTitles([])
    }, [selectedPlan, activeCategoryTitles])

    const downloadHandler = React.useCallback(async () => {
        setLoading(true);
        await getZip();
    });
    
    const rightVerticalRibbon = React.useCallback([
        {
            background: "#69287e",
            target: "add-simulation",
            onClick: addNewSimulationHandler,
            disable: disableAddNewButton,
            render: (
                <AddIcon sx={{ fill: "white", paddingX: "2px", }} />
            ),
        },
        {
            background: "#e20000",
            target: "download-simulation",
            onClick: () => {
                downloadHandler();
            },
            disable: loading,
            loading: loading,
            render: (
                <DownloadButton loading={loading} />
            ),
        },
        {
            background: "#66b654",
            target: "save-simulation",
            onClick: () => {
                setSimulationNameFormOpen(true)
            },
            disable: !simulationSaveEligible || simulationSaving,
            render: (
                <SaveIcon sx={{ fill: "white", paddingX: "2px", }} />
            ),
        },
        {
            background: "#d0d0d0",
            onClick: () => {
                setHidePanel(!hidePanel);
                // hide simulation result panel
                setResultToggler(false)
            },
            render: (
                <ArrowBackIosNewIcon fontSize="small" sx={{ fill: "white", paddingX: "2px", marginLeft: "2px", }} />
            ),
        },
    ], [disableAddNewButton, simulationSaveEligible, simulationSaving, loading])

    const selectPlanHandler = ({ target }) => {
        const { value: planName } = target
        setSelectedPlan(["", null, undefined].includes(planName) ? null : planName)
    }

    const runSimulationCallBack = () => {
        setActiveCategoryTitles([])
        setSelectedPlan(null)
        if (simulationState?.data?.length < typologies.length) {
            setDisableAddNewButton(false);
        }
    }

    const runSimulaitonHandler = React.useCallback(() => {
        setResultToggler(true);
        dispatch(updateNewSimulationParam(activeCategoryTitles, selectedPlan))
        setDisableAddNewButton(true);
        dispatch(runSimulations({ runSimulationCallBack }))
    }, [setActiveCategoryTitles, activeCategoryTitles, selectedPlan])

    const cancelSimulationNameForm = () => {
        setSimulationNameFormOpen(false)
    }

    const submitSimulation = React.useCallback(async (data) => {
        setSimulationNameFormOpen(false)
        dispatch(updateSimulationName(data[0]))
        setSimulationSaving(true)
        const paramUpdatedSimulations = simulationState?.data?.filter((simulation) => {
            return [
                SIMULATION_STATUS.PARAM_ADDED,
                SIMULATION_STATUS.COMPLETE,
                SIMULATION_STATUS.ERROR,
                SIMULATION_STATUS.LOADING,
            ].includes(simulation?.status)
        })
        const payload = makePayloadForSimulationAPI(
            paramUpdatedSimulations,
            municipalityName,
            data[0]
        )
        let submitSimulationRes
        if (simulationState.id) {
            submitSimulationRes = await simulationService.updateSimulation(simulationState.id, payload)
            showPopupOnMapHandler(`Simulation updated successfully`, 'success')
            // dispatch(updateSimulationId(simulationState.id))
        }
        else {
            submitSimulationRes = await simulationService.saveSimulation(payload)
            showPopupOnMapHandler(`Simulation saved successfully`, 'success')
            dispatch(updateSimulationId(submitSimulationRes.data.id))
        }
        if (submitSimulationRes?.errors?.length) {
            showPopupOnMapHandler(submitSimulationRes.errors[0], 'error')
        }
        setSimulationSaving(false)
    }, [simulationState])

    const playDemoHandler = () => {
        dispatch(setStoreState({
            run: true,
            stepIndex: 0,
        }))
    }

    const removeLastSimulationAfterEffects = useCallback(() => {
        dispatch(setSelectedArea({
            addressId: "",
            houseNo: "",
            streetId: "",
            streetName: "",
            municipalityName: "",
            municipalityId: "",
            postCode: "",
            level: "",
        }))
    }, [])

    return (
        <>
            <PopupForm
                open={simulationNameFormOpen}
                fieldDefaultValues={[simulationState.name]}
                onCancelHandler={cancelSimulationNameForm}
                onSubmitHandler={submitSimulation}
                title="Naam van de simulatie"
                fieldNames={["Naam van de simulatie"]}
            />
            {!hidePanel ? (
                <Container>
                    {(simulationState?.data?.length === 0) || (productDemoState.stepIndex === 0 && productDemoState.run) ? (
                        <Box width="250px">
                            <SearchContainer />
                            <Box marginTop={1}>
                                <Stack direction="row" justifyContent="flex-end" alignItems="center" gap="2px">
                                    <Typography variant='body1' fontSize="11px" fontWeight="bold">Klik hier om de demo te starten</Typography>
                                    <ArrowRightAltIcon fontSize='small' />
                                    <IconButton onClick={playDemoHandler} sx={{ padding: 0, alignItems: "center" }}>
                                        <PlayCircleIconStyled />
                                    </IconButton>
                                </Stack>
                            </Box>
                        </Box>
                    ): null} 
                    
                    { (( productDemoState.run && productDemoState.stepIndex > 0) ? true : (simulationState?.data?.length > 0 && productDemoState.stepIndex !== 0) ) ? (
                        <Stack direction="row" sx={{ marginTop: "-10px" }}>
                            <Box width="340px" marginTop={2} id="simulation-typologies">
                                <div className = {`sidepanel-panel shadow p-0 ${
                                        smallHeight ? "simulationpanel-small-screens" : ""
                                    }`}
                                    // style={{ height: "40vh", overflowY: 'scroll' }}
                                >
                                    <Stack direction="column" gap={1} paddingTop={1} paddingBottom={2}>
                                        {/* Municipality Name */}
                                        <Box paddingX={2}>
                                            <Typography variant='body' fontWeight="bold" >{`<${productDemoState?.run ? "Antwerpen" : municipalityName}>`}</Typography>
                                        </Box>
                                        <RemoveLastSimulationHandlerContext.Provider value={removeLastSimulationAfterEffects}>
                                            <SimulationHeaders/>
                                        </RemoveLastSimulationHandlerContext.Provider>
                                        <Collapse in ={!disableSimulationRerun}>
                                            <Box marginTop={1} paddingX={2}>
                                                <SimulationRun
                                                    runSimulaitonHandler={runSimulaitonHandler}
                                                    isButtonDisabled={disableSimulationRerun}
                                                    // isButtonDisabled={selectedPlan === null ? true : !disableAddNewButton}
                                                />
                                                <TabsInfoMessage message={
                                                    <Typography color="#b9b9b9" variant="caption">Voer simulaties opnieuw uit voor bijgewerkte resultaten.</Typography>
                                                } />
                                            </Box>
                                        </Collapse>
                                        <Box>
                                            {/* Typologies */}
                                            {(showTypologies && disableSimulationRerun) ? (
                                                <>
                                                    <Stack direction="row" gap={1} paddingX={2}>
                                                        <Typography color="gray" fontWeight="bold" variant="body2" fontSize="12px">{"Kies typologie"}</Typography>
                                                        <HtmlTooltip title={TooltipTitles["typologies"]} placement="right">
                                                            <InfoOutlinedIcon fontSize='small' sx={{ fill: "#84c2d9" }} />
                                                        </HtmlTooltip>
                                                    </Stack>
                                                    <Grid container rowSpacing={"2px"} columnSpacing={"4px"} paddingX={1}>
                                                        {typologies?.map((typology, idx) => (
                                                            <Grid key={idx} item md={6} lg={6}>
                                                                <TypologyItem
                                                                    item={typology}
                                                                    isDisabled={alreadySelectedIds?.includes(typology?.key)}
                                                                    toggleCategory={toggleCategory}
                                                                    activeCategoryIds={activeCategoryTitles}
                                                                />
                                                            </Grid>
                                                        ))}
                                                    </Grid>
                                                </>
                                            ) : null}
                                            {(activeCategoryTitles?.length > 0 || productDemoState?.run) ? (
                                                <>
                                                    <hr style={{ height: "0.1px", margin: 0, marginTop: "6px", marginBottom: "6px", }} />
                                                    <SimulationPackages
                                                        plans={renovationPlans}
                                                            selectPlanHandler={selectPlanHandler}
                                                            selectedPlan={selectedPlan}
                                                    />
                                                    <Box marginTop={1}>
                                                    <SimulationRun
                                                        runSimulaitonHandler={runSimulaitonHandler}
                                                        isButtonDisabled={selectedPlan === null ? true : !disableAddNewButton}
                                                    />
                                                </Box>
                                            </>
                                            ) : null}
                                        </Box>
                                    </Stack>
                                </div>
                            </Box>
                            {/* right side vertical bar */}
                            <VerticalRibbon items={rightVerticalRibbon} />
                        </Stack>
                    ): null }
                </Container>
            ) : (
                <div
                    className={"simulationpanel-hide-panel"}
                    >
                    <img
                        className="ml-2 pointer light-grey"
                        src={close}
                        onClick={() => {
                            setHidePanel(!hidePanel)
                            // show simulation result panel
                            setResultToggler(true)
                        }}
                        alt="close"
                        width="50px"
                        height="50px"
                    />
                </div>
            )
            }
            {resultToggler ? (
                <RemoveLastSimulationHandlerContext.Provider value={removeLastSimulationAfterEffects}>
                    <Results
                        smallScreen={smallWidth}
                    />
                </RemoveLastSimulationHandlerContext.Provider>
            ) : ""}
            <SimulationVisualization
                mymap={map}
                mapView={mapView}
                openToggler={openToggler}
                setPopupContentData={setPopupContentData}
            />
            <ProductDemo />
        </>
    )
}

export default AdvancedMap
