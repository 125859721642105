import { Grid } from "@material-ui/core";

export const GradientBar = ({
  radius,
  leftColor,
  rightColor,
  height = 14,
  labels = ["-500", "4000"],
}) => {
  return (
    <>
      <div
        style={{
          backgroundImage: `linear-gradient(to right, ${leftColor}, ${rightColor})`,
          height: `${height}px`,
          width: "98%",
          marginLeft: '1%',
          marginRight: '1%',
          borderRadius: radius ? radius + "px" : "",
        }}
      />
      <Grid
        className=""
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        {labels.map((label, index) => {
          return (
            <span key={index} className="small text-grey">
              {label}
            </span>
          );
        })}
      </Grid>
    </>
  );
};
