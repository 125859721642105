import { ApiService } from "./ApiService";
import {
    renScenarioChartDetail,
    constructioperiosChartDetails,
    investmentDataDetails,
    energyChartDetail,
    potentialDataDetails, constructioperiosChartDetailsSector,
} from "../redux/constants/ApiEndPoints";


class ChartAPIService extends ApiService {
    async getEnergyTabChartDataOfMunicipality(municipalityName, isSector=false) {
        let url = `${energyChartDetail}?municipality_name=${municipalityName}`
        if (isSector)
            url += `&is_sector=true`
        const {data} = await this.apiWrapper("Could not get data", url, "get", false);
        return data;
    }

    async getConstructionPeriodChartDataOfMunicipality(municipalityName) {
        const urlWithQueryParam = `${constructioperiosChartDetails}?municipality_name=${municipalityName}`
        const { data } = await this.apiWrapper("Could not get data.", urlWithQueryParam, "get", false, {});
        return data;
    }

    async getConstructionPeriodChartDataOfSector(sectorId) {
        const urlWithQueryParam = `${constructioperiosChartDetailsSector}?sector_id=${sectorId}`
        const { data } = await this.apiWrapper("Could not get data.", urlWithQueryParam, "get", false, {});
        return data;
    }

    async getInvestmentDataOfMunicipality(municipalityName) {
        const urlAlongQueryParam = `${investmentDataDetails}?municipality_name=${municipalityName}`
        const { data } = await this.apiWrapper("Could not get data.", urlAlongQueryParam, "get", false, {})
        return data
    }

    async getPotentialDataOfMunicipality(municipalityName) {
        const urlAlongQueryParam = `${potentialDataDetails}?municipality_name=${municipalityName}`
        const { data } = await this.apiWrapper("Could not get data.", urlAlongQueryParam, "get", false, {})
        return data
    }

}

export default new ChartAPIService()