import React from "react";
import { connect } from "react-redux";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { ROUTES } from "../constants/routes";
import Header from "../components/header/header";
import Footer from "../components/footer/footer";

// AUTH
import LoginPage from "../components/auth/login";
import RegistrationPage from "../components/auth/registration";
import ForgotPassword from "../components/auth/forgot";
import ChangePassword from "../components/auth/changePassword";

// HOMEPAGE
import LandingPage from "../components/homePage/index";
import SmoothScroll from '../components/homePage/smoothScroll';

// MAPCONTROLLER
import MapController from "../components/MapController/index";

// ERROR PAGE
import ErrorPage from "../components/pages/Error403";
import NotFoundPage from "../components/pages/Error404";
import SimulationOverview from "../components/profileMenu/simulation_overview";

function MainRouter(props) {
  let checkAuth = document.cookie.indexOf("access_token") == -1 ? false : true;
  return (
    <Router>
      <div className="app-content-wrapper">
        <Header />
        {checkAuth ? (
          <Switch>
            <Route exact path={ROUTES.HOME_PAGE}>
              <SmoothScroll><LandingPage /></SmoothScroll>
            </Route>
            <Route path={ROUTES.MAP_PAGE}>
              <SmoothScroll><MapController simulation={false} /></SmoothScroll>
            </Route>
            <Route path={ROUTES.MAP_PAGE_SIMULATION}>
              <SmoothScroll><MapController simulation={true} /></SmoothScroll>
            </Route>
            <Route path={ROUTES.SIMULATION_OVERVIEW}>
              <SmoothScroll><SimulationOverview/></SmoothScroll>
            </Route>
            <Route exact path={ROUTES.CHANGE_PASS}>
              <SmoothScroll><ChangePassword /></SmoothScroll>
            </Route>
            <Route path="*">
              <SmoothScroll><NotFoundPage /></SmoothScroll>
            </Route>
          </Switch>
        ) : (
          <Switch>
            <Route exact path={ROUTES.HOME_PAGE}>
              <SmoothScroll><LandingPage /></SmoothScroll>
            </Route>
            <Route path={ROUTES.MAP_PAGE}>
              <SmoothScroll><MapController simulation={false} /></SmoothScroll>
            </Route>
            <Route path={ROUTES.LOGIN_PAGE}>
              <SmoothScroll><LoginPage /></SmoothScroll>
            </Route>
            <Route path={ROUTES.REGISTRATION}>
              <SmoothScroll><RegistrationPage /></SmoothScroll>
            </Route>
            <Route path={ROUTES.FORGOT_PASS}>
              <SmoothScroll><ForgotPassword /></SmoothScroll>
            </Route>
            <Route path={ROUTES.FORGOT_PASS_CHANGE}>
              <SmoothScroll><ChangePassword /></SmoothScroll>
            </Route>
            <Route path="*">
              <SmoothScroll><ErrorPage /></SmoothScroll>
            </Route>
          </Switch>
        )}
      <Footer />
      </div>
    </Router>
  );
}

const mapStateToProps = ({ auth, content, map, loader }) => ({
  auth,
  content,
  map,
  loader,
});
export default connect(mapStateToProps, null)(MainRouter);