export const validatePassword = (password,setPasswordValidateText) => {
  
    let errors = [];

    if (password.length < 8)  errors.push('8 character');

    // if (!/[A-Z]/. test(password)) errors.push(' 1 uppercase');   

    // if (!/[a-z]/. test(password)) errors.push(' 1 lowercase');
    
    // if (!/\d/. test(password)) errors.push(' 1 number');

    // if (!/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/. test(password)) errors.push(' 1 special')

    let txt = `Password should have at least ${errors.join(',')}`;

    if (errors[errors.length - 1] !== '8 character long') txt = `${txt} character`     

    txt = `${txt}.`

    
    const re = /^.{8,}$/
    const status = re.test(String(password))
    if(!status){
      setPasswordValidateText(txt);
    }
    return status;
  }