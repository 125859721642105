import React from 'react';
import {CloseableCard} from "../sharedComponents/closableCard";
import {Grid} from '@material-ui/core';
import "../../css/map.css";
import {connect} from 'react-redux';
import { MultiColorBar, TriColorBar, StepGradientBar, MultiStepColorBar } from "../sharedComponents/multiColorBar";
import {GradientBar} from "../sharedComponents/gradientBar";
import parse from 'html-react-parser';
import {
  HEAT_STATES_COLORS,
  POTENTIAL_COLORS,
  INVESTMENT_COLORS,
  SEARCH_DIRECTION_COLORS,
  TENANT_COLORS,
  INCOME_GRADIENT_COLORS,
  AGE_GRADIENT_COLORS,
} from "../../constants/sidepanel";

const MapInfoCard = ({
        onCloseClicked = () => {},
        width = "60",
        height = "80",
        bannerContent
    }) => {
    if (bannerContent) {
        var {
            section1Title,
            section2Title,
            section3Title,
            section1Text,
            section2Text,
            section3Text
        } = {
            ...bannerContent?.section1.attributes,
            ...bannerContent?.section2.attributes,
            ...bannerContent?.section3.attributes
        };
    }

    const highlight = location.pathname.includes('map')

    return (
        <Grid container direction="row"
              justifyContent="center"
              alignItems="center"
        >
            <Grid item xs={12}>
                <CloseableCard
                    // title={section1Title}
                    title= "Hoe gebruik je de Inspiratiekaart Renovatie?"
                    width={width}
                    height={height}
                    onClose={onCloseClicked}
                >
                    <Grid
                        container
                        alignContent="flex-start"
                        className="pt-2"
                    >
                        <div className="h-full col-12 pl-0">
                            {/* <span className='fs-12 clr-text d-block'>
                                {parse(`${section1Text}`)}
                            </span><br/>
                            <span className='fs-15 bold d-block pb-3'>
                                {section2Title}
                            </span>
                            <MultiColorBar
                                colors={[
                                    {hex: COLLECTIVE_HEATING_PROBABILITY[1], percentage: 20},
                                    {hex: COLLECTIVE_HEATING_PROBABILITY[2], percentage: 20},
                                    {hex: COLLECTIVE_HEATING_PROBABILITY[3], percentage: 20},
                                    {hex: COLLECTIVE_HEATING_PROBABILITY[4], percentage: 20},
                                    {hex: COLLECTIVE_HEATING_PROBABILITY[5], percentage: 20}
                                ]}
                                labels={[
                                    "Collectief", "Onbeslist", "Individueel"
                                ]}
                            />
                            <br/>
                            <span className='fs-12 clr-text d-block'>
                                {parse(`${section2Text}`)}
                            </span>
                            <span className='fs-15 bold pt-4 position-relative d-block mb-3'>
                                {section3Title}
                            </span>
                            <GradientBar
                                leftColor={HEAT_DEMAND_COLORS.left}
                                rightColor={HEAT_DEMAND_COLORS.right}
                                radius={'30'}
                            />
                            <span className="fs-12 clr-text pt-4 d-block">
                                {parse(`${section3Text}`)}
                            </span>
                            {auth.active_view == 1 && highlight ?
                                <>
                                    <br/>
                                    <span className='fs-15 bold'>
                                        Warmtebronnen
                                    </span>
                                    <span className="fs-12 pt-3 clr-text d-block">
                                        In de simulatieomgeving is het mogelijk om warmtebronnen als puntbronnen toe te voegen.
                                        Merk daarbij op dat in het zoneringsalgoritme geen expliciete rekening wordt gehouden met
                                        de aanwezigheid van (potentiële) bronnen. Deze berekening gebeurt puur vanuit de warmtevraag. 
                                        Voor een zoekzone die als ‘collectief’ wordt ingekleurd zal de aanwezigheid van warmtebronnen 
                                        echter een cruciale factor zijn in de effectieve slaagkansen van een warmtenet. Het identificeren 
                                        van (potentiële) warmtebronnen zal daarom een belangrijke vervolgstap zijn. Met deze feature laten
                                        we alvast toe om geïdentificeerde warmtebronnen mee te visualiseren op kaart en om een aantal 
                                        relevante eigenschappen te documenteren.

                                    </span><br/>

                                    <span className='fs-15 bold'>
                                        Renovatiegraad
                                    </span>
                                    <span className="fs-12 pt-3 clr-text d-block">
                                        Bij het opstellen van de inspiratiekaart warmte is uitgegaan van één centraal renovatiescenario, 
                                        in lijn met de LTRD van de Vlaamse overheid. Aangezien de rendabiliteit van collectieve systemen,
                                        zoals warmtenetten, afneemt naarmate de warmtevraagdensiteit daalt, heeft deze renovatiegraad 
                                        uiteraard een belangrijke invloed op de uiteindelijke zonering. De gesimuleerde warmtevraag voor
                                        2050 kan daarom geïnterpreteerd worden als een best-case scenario in termen van energiebesparing
                                        aangezien in dit scenario alle gebouwen voldoen aan de opgelegde doelstellingen voor 2050. 
                                        In tegenstelling kan de huidige toestand die als worst-case gezien kan worden, aangezien zelfs
                                        bij business as usual renovaties ervoor zullen zorgen dat de warmtevraag zal dalen. Bijkomend 
                                        zijn nog 2 tussenliggende scenario’s berekend waarbij respectievelijk 33% en 66% van het 
                                        renovatiepotentieel werd gerealiseerd. Deze kunnen dus geïnterpreteerd worden als een 2030 en
                                        2040 scenario, uitgaande van een constante renovatiegraad. <br/>
                                        In de simulatieomgeving kan – afzonderlijk voor residentiële en niet-residentiële gebouwen
                                        – de renovatiegraad worden ingesteld volgens de scenario’s 2020-2030-2040-2050. Door deze 
                                        parameters te wijzigen kan bvb. de impact van het verwachtte succes van bepaalde beleidskeuzes
                                        worden ingeschat.


                                    </span><br/>
                                    <span className='fs-15 bold'>
                                        Warmtenetkosten
                                    </span>
                                    <span className="fs-12 pt-3 clr-text d-block">
                                        In het zoneringsalgoritme wordt via een vereenvoudigd economisch model de systeemkost van een
                                        collectieve oplossing – op basis van warmtenetten – vergeleken met de kostprijs van individuele
                                        oplossingen. In die berekening zijn 2 parameters belangrijk:<br/>
                                        De aansluitkost: dit is de kost van leidingen en aansluitingen die gemaakt moet worden om de 
                                        gebouwen in dat straatsegment aan te sluiten op een warmtenet. Standaard (medium) wordt deze kost
                                        berekend in functie van het aansluitvermogen en de afstand tussen de gebouwen op basis van gemiddelde
                                        waarden uit praktijkstudies. In het ‘laag’ en ‘hoog’ scenario worden deze kosten respectievelijk 
                                        verlaagd en verhoogd met 10%.<br/>
                                        De warmteprijs: deze fictieve prijs in €/MWh voor de warmteproductie. Standaard wordt hier gewerkt 
                                        met 25€/MWh. In het ‘laag’ scenario met 10€/MWh, overeenkomstig een goedkope restwarmtebron. 
                                        In het ‘hoog’ scenario met 50€/MWh, overeenkomstig een collectief warmtepomp systeem.<br/>
                                        Merk op dat op indirecte manier, deze parameters ook kunnen gebruikt worden om de impact van 
                                        prijsevoluties in technologieën of in elektriciteitsprijs (voor individuele warmtepompen) te evalueren.
                                    </span>
                                </>
                                : ''} */}
                        </div>
                        <div className='h-full col-12 pl-0'>
                            <span className='fs-12 clr-text d-block'>
                                Deze ’inspiratiekaart renovatiebeleid’ is voor steden en gemeenten een nieuw instrument waarmee zij inzicht krijgen in de huidige toestand van hun gebouwbestand. Het laat hen bovendien toe om renovatiepakketten samen te stellen, deze te simuleren en de resulterende impact te visualiseren. Die impact wordt uitgedrukt in enerzijds energie- en CO<sub>2</sub>-besparing en anderzijds in economische parameters als investering en totale actuele kost (TAK). Hiertoe is met een uitgebreid simulatiemodel - op basis van data over de huidige energetische toestand van woningen - voor iedere woning een berekening gemaakt voor verschillende energetische renovatiemaatregelen en -pakketten. 
                            De publiek beschikbare informatie is niet altijd volledig. Om deze reden zijn er geavanceerde algoritmes gebruikt die toelaten om de gaten zo waarheidsgetrouw mogelijk op te vullen. Denk hierbij aan de gemiddelde kwaliteit van de isolatie in een bepaalde woonwijk of het gebruik van gas of elektriciteit voor verwarming. Door de gesimuleerde data zo dicht mogelijk bij de echte situatie te brengen, kan men de huidige energievraag bepalen, maar ook hoe deze in de toekomst zal evolueren.
                            </span><br/>
                            <span className='fs-15 bold d-block pb-1'>
                                Toolbox visualisaties
                            </span>
                            
                            <span className='fs-12 clr-text d-block'>
                                Maak een selectie uit onderstaande kaartlagen om de huidige situatie weer te geven. Vergelijk techno-economische factoren met de socio-economische situatie door gegevens op straatsegmentniveau van één onderwerp gelijktijdig te activeren met de visualisatie op statistische sectorniveau van een ander.
                            </span><br/>
                            <span className='fs-15 bold position-relative d-block'>
                                Huidige Energieprestatie
                            </span>
                            <MultiColorBar
                                colors={[
                                {
                                    hex: HEAT_STATES_COLORS.A,
                                    percentage: 16.6,
                                    label: "A",
                                },
                                {
                                    hex: HEAT_STATES_COLORS.B,
                                    percentage: 16.6,
                                    label: "B",
                                },
                                {
                                    hex: HEAT_STATES_COLORS.C,
                                    percentage: 16.6,
                                    label: "C",
                                },
                                {
                                    hex: HEAT_STATES_COLORS.D,
                                    percentage: 16.6,
                                    label: "D",
                                },
                                {
                                    hex: HEAT_STATES_COLORS.E,
                                    percentage: 16.6,
                                    label: "E",
                                },
                                {
                                    hex: HEAT_STATES_COLORS.F,
                                    percentage: 16.6,
                                    label: "F",
                                },
                                ]}
                                labels={["A", "B", "C", "D", "E", "F"]}
                            />
                            <span className="fs-12 clr-text d-block">
                                Deze kaartlaag toont het gemiddelde energielabel voor alle aanwezige typologieën van gebouwen per straatsegment of per statistische sector.
                            Merk op dat een straatsegment niet ingekleurd wordt indien er te weinig woningen zijn (minder dan vijf) om de data op een GDPR-conforme manier te delen.
                            </span><br/>
                            <span className='fs-15 bold position-relative d-block mb-2'>
                                Potentieel
                            </span>
                            <GradientBar
                                leftColor={POTENTIAL_COLORS.left}
                                rightColor={POTENTIAL_COLORS.right}
                                radius={"10"}
                            />
                            <span className="fs-12 clr-text pt-1 d-block">
                                Deze kaartlaag maakt het techno-economische potentieel inzichtelijk. Aan de basis ervan ligt de verhouding de total cost of ownership (TCO) en de bereikte CO<sub>2</sub>-besparing voor die TCO. De kleurschaal geeft een indicatie van hoe groot de relatieve CO<sub>2</sub>-reductie is in verhouding tot de TCO. Een lichte kleur is interessanter: hier is de CO<sub>2</sub>-reductie goedkoper.
                            </span><br/>
                            <span className='fs-15 bold position-relative d-block mb-2'>
                                Investering
                            </span>
                            <TriColorBar
                                colors={[
                                {
                                    hex: INVESTMENT_COLORS.A,
                                    percentage: 33.3,
                                },
                                {
                                    hex: INVESTMENT_COLORS.B,
                                    percentage: 33.3,
                                },
                                {
                                    hex: INVESTMENT_COLORS.C,
                                    percentage: 33.3,
                                },
                                ]}
                                labels={[">50.000€", "<50.000€", "+100.000€"]}
                            />
                            <span className="fs-12 clr-text pt-2 d-block">
                                De warmtevraag voor de huidige toestand is gebaseerd op de warmtevraagkaart 2019 (energiesparen.be/warmtekaart). Op basis van deze data werd – rekening houdend met bijkomende data over gebouwtypes, bouwjaren, afmetingen van gebouwen, etc. – voor ieder gebouw een maandelijkse warmtevraagberekening gestart. Na kalibratie van dit model met de data uit de warmtekaart 2019, werd voor elk gebouw een renovatiepakket geïmplementeerd bestaande uit isolatie- en ventilatiemaatregelen. De maatregelen werden per gebouwtype gekozen uit renovatiepakketten die ertoe leiden dat de gebouwen een EPC A-label – conform het renovatiescenario – kunnen bekomen. De investering tot A-label die wordt weergegeven is de gemiddelde investering van alle woningen van alle typologieën in het straatsegment of de statistische sector.
                            </span><br/>
                            <span className='fs-15 bold position-relative d-block mb-3'>
                                Zoekrichting duurzame warmteoplossing
                            </span>
                            <MultiStepColorBar
                                colors={[
                                {
                                    hex: SEARCH_DIRECTION_COLORS.B,
                                    percentage: 20,
                                },
                                {
                                    hex: SEARCH_DIRECTION_COLORS.C,
                                    percentage: 20,
                                },
                                {
                                    hex: SEARCH_DIRECTION_COLORS.D,
                                    percentage: 20,
                                },
                                {
                                    hex: SEARCH_DIRECTION_COLORS.E,
                                    percentage: 20,
                                },
                                {
                                    hex: SEARCH_DIRECTION_COLORS.F,
                                    percentage: 20,
                                },
                                ]}
                                labels={["Collectief", "Onbeslist", "Individueel"]}
                            />
                            <span className="fs-12 clr-text pt-2 d-block">
                                Deze kaartlaag toont de aanduiding van de zoekzones zoals bekomen uit het beslissings- en clusteringsalgoritme. De labels worden toegekend aan de hand van de vergelijking tussen de kosten voor individuele en collectieve warmteoplossingen. Die kosten zijn berekend vanuit de warmtevraag en kunnen daardoor als volgt worden geïnterpreteerd:<br/><br/>
                            - ‘Collectief’ : zone van straatsegmenten waarvoor de ingeschatte kosten voor een collectieve warmteoplossing significant lager liggen dan die voor de goedkoopste individuele oplossingen.<br/><br/>
                            - ‘Eerder collectief’: zone van straatsegmenten waarvoor de ingeschatte kosten voor een collectieve warmteoplossing gelijk of lager liggen dan die voor de goedkoopste individuele oplossingen.<br/><br/>
                            - ‘Onzeker’: zone van straatsegmenten waarvoor de ingeschatte kosten voor een collectieve warmteoplossing gelijkaardig zijn als die voor de individuele oplossingen.<br/><br/>
                            - ‘Eerder individueel’: zone van straatsegmenten waarvoor de ingeschatte kosten voor een collectieve warmteoplossing gelijkaardig of hoger zijn als die voor de individuele oplossingen.<br/><br/>
                            - ‘Individueel’: zone van straatsegmenten waarvoor de ingeschatte kosten voor een collectieve warmteoplossing significant hoger zijn als die voor de duurdere individuele oplossingen
                            </span><br/>
                            <span className='fs-15 bold position-relative d-block mb-1'>
                                Socio-economische factoren
                            </span>
                            <span className="fs-12 clr-text pt-1 d-block">
                                Een belangrijke stap is om bij de beleidsvorming uit de enge scope van de techno-economische analyse te treden en ook de socio-economische dimensie aan de analyse toe te voegen. Een wijk met een zeer goed techno-economisch potentieel kan in de praktijk bijvoorbeeld bestaan uit voornamelijk huurwoningen. In andere wijken kan een hoge gemiddelde leeftijd bijvoorbeeld als voorspeller gebruikt worden dat heel wat woningen in de komende 10 tot 15 jaar nieuwe eigenaars zullen krijgen. In dat geval kan er bijvoorbeeld maximaal ingezet worden op die sleutelmomenten bij verkoop.
                            </span>
                            <span className='fs-12 bold pt-2 position-relative d-block mb-3'>
                                Inkomen
                            </span>
                            <GradientBar
                                leftColor={INCOME_GRADIENT_COLORS.left}
                                rightColor={INCOME_GRADIENT_COLORS.right}
                                radius={"10"}
                                labels={['10000€', '50000€']}
                            />
                            <span className='fs-12 bold pt-2 position-relative d-block mb-3'>
                                Leeftijdsverdeling
                            </span>
                            <GradientBar
                                leftColor={AGE_GRADIENT_COLORS.left}
                                rightColor={AGE_GRADIENT_COLORS.right}
                                radius={"10"}
                                labels={[30, 60]}
                            />
                            <span className='fs-12 bold pt-2 position-relative d-block mb-3'>
                                Huurder/Eigenaar
                            </span>
                            <StepGradientBar
                                colors={[
                                {
                                    hex: TENANT_COLORS.A,
                                },
                                {
                                    hex: TENANT_COLORS.B,
                                },
                                {
                                    hex: TENANT_COLORS.C,
                                },
                                {
                                    hex: TENANT_COLORS.D,
                                },
                                {
                                    hex: TENANT_COLORS.E,
                                },
                                ]}
                                radius={"10"}
                            />
                        </div>
                    </Grid>
                </CloseableCard>
            </Grid>
        </Grid>
    )
}

const mapStateToProps = ({content, auth}) => ({content, auth})

export default connect(mapStateToProps)(MapInfoCard);
