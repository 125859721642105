import React from "react"
import * as d3 from "d3"

import { useD3 } from "../../../utils/hooks"
import "./chart.css"
import { BUILDING_TYPES_BORDER_COLORS } from "../../../constants/potentialTab"

const CustomBarChart = ({ data, xAxisValues, yAxisValues }) => {
    const { ref, setData } = useD3(() => {
        var margin = { top: 0, right: 110, bottom: 0, left: 60 }
        var width = document.documentElement.clientWidth * 0.27
        var height = document.documentElement.clientHeight * 0.33

        /*
            margin = 200, 
            width = svg.attr("width") - margin, 
            height = svg.attr("height") - margin;
        */

        var tooltip = {
            mouseover: function (_, d,) {
                let { potential, co2_savings_sum } = d

                potential = Number(potential).toLocaleString("es-ES", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                }) || potential
                co2_savings_sum = Number(co2_savings_sum).toLocaleString("es-ES", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                }) || co2_savings_sum

                const html = `
                        <table>
                            <tbody>
                                <tr>
                                    <td>CO₂ kost (€/ton)<td>
                                    <td>${potential}<td>
                                </tr>
                                <tr>
                                    <td>CO₂ besparing (ton)<td>
                                    <td>${co2_savings_sum}<td>
                                </tr>
                            </tbody>
                        </table>
                        `

                this.el.html(html).transition()
                    .duration(100)
                    // .style("opacity", 0)
                    .style("opacity", 1)
                    .style("z-index", 9999)
                    .style("width", "250px")
            },
            mousemove: function () {
                var pageX = event.pageX || event.clientX
                    , pageY = event.pageY || event.clientY
                return this.el.style("top", (event.offsetY - 75) + "px").style("left", (pageX - 50) + "px");
                // if (pageX < (width / 2) + 100) {
                //     return this.el.style("top", (pageY - 950) + "px").style("left", (pageX - 25) + "px");
                // } else {
                // }
            },
            mouseout: function (d) {
                this.el.transition()
                    .duration(200)
                    .style("opacity", 0);
            }
        }

        function chart(selection) {
            if (selection) {
                selection.each(function (data) {

                    var innerWidth = width - margin.left - margin.right
                        , innerHeight = height - margin.top - margin.bottom

                        // , max = d3.max(aH)
                        // , min = d3.min(aH)
                        // , barsHeight = min < 0 ? Math.abs(min) + max : max
                        // , yHeight = d3.max([max, Math.abs(min)])
                        // , yScale = d3.scaleLinear()

                        // , w = innerWidth / aW.length
                        , wRatio = innerWidth / d3.sum(xAxisValues)
                    // , hRatio = innerHeight / barsHeight
                    // , h = yHeight && hRatio ? yHeight * hRatio : innerHeight / 2

                    // define x-scale
                    const x = d3.scaleBand()
                        .domain(xAxisValues)
                        .range([0, width - margin.left])
                        .padding(0.0)

                    const xAxisPos = g => g
                        .attr("transform", `translate(0, ${height / 2})`)
                        .call(d3.axisBottom(x).tickValues(() => ""))
                    // .call(g => g.select(".domain").remove())

                    // define x-scale - negative
                    const xAxisNeg = g => g
                        .attr("transform", `translate(0, ${height / 2})`)
                        .call(d3.axisTop(x).tickValues(() => ""))

                    // Update the y-scale - rangeRound is used for antialiasing
                    // yScale
                    //     .domain([0, yHeight])
                    //     .rangeRound([0, yHeight])

                    const absMax = d3.max(yAxisValues)

                    var y = d3.scaleLinear()
                        .domain([-absMax, absMax])
                        .nice()
                        .range([height - margin.bottom, margin.top]);

                    var yAxis = g => g
                        .call(d3.axisLeft(y).tickValues(() => ""))

                    // initialize tooltip
                    tooltip.el = d3.select(this).append("div")
                        .attr("class", "tooltip")
                        .style("opacity", 0)

                    // Create the svg element
                    var outerChart = d3.select(this).append("svg")

                    // Update the outer dimensions
                    outerChart.attr("class", "chart")
                        .attr("width", width)
                        .attr("height", height)

                    var innerChart = outerChart.append("g")
                        .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
                        .attr("width", "inherit")

                    innerChart.append("g")
                        .attr("class", "axis x_axis")
                        .call(xAxisPos)

                    innerChart.append("g")
                        .attr("class", "axis x_axis")
                        .call(xAxisNeg)

                    // x-axis titles
                    innerChart.append("g")
                        .attr("class", "axis x_axis")
                        .append("text")
                        .attr("transform", `translate(${width - 70}, ${height / 2 + 15})`)
                        .attr("fill", "black")
                        .attr("text-anchor", "end")
                        .text("CO₂ besparing")

                    innerChart.append("g")
                        .attr("class", "axis x_axis")
                        .append("text")
                        .attr("transform", `translate(${width - 120}, ${height / 2 + 30})`)
                        .attr("fill", "black")
                        .attr("text-anchor", "end")
                        .text("(ton)")

                    // create Y axis
                    innerChart.append("g")
                        .attr("class", "axis y_axis")
                        .call(yAxis)

                    // y-axis titles
                    innerChart.append("g")
                        .attr("class", "axis y_axis")
                        .append("text")
                        .attr("x", -55)
                        .attr("y", 10)
                        .attr("fill", "black")
                        .attr("text-anchor", "start")
                        .text("CO₂ kost")

                    innerChart.append("g")
                        .attr("class", "axis y_axis")
                        .append("text")
                        .attr("x", -50)
                        .attr("y", 25)
                        .attr("fill", "black")
                        .attr("text-anchor", "start")
                        .text("(€/ton)")

                    function graphX(d, i) {
                        if (i > 0) {
                            let start = 0, j
                            for (j = 0; j < i; j++) {
                                start += data[j].co2_savings_sum * wRatio
                            }
                            return start + 15
                        } else {
                            return 0.5 + 15
                        }
                    }

                    function graphWidth(d) {
                        const value = d.co2_savings_sum
                        return (value * wRatio) < 0.5 ? 0.5 : (value * wRatio) - 0.5
                    }

                    function graphFill(d) {
                        const { building_type: buildingType } = d
                        const fillColor = BUILDING_TYPES_BORDER_COLORS[buildingType]
                        return fillColor
                    }

                    const strokeColor = "#ffffff00"

                    // Positive values chart
                    innerChart
                        .append("g")
                        .selectAll("rect")
                        .data(data)
                        .enter()
                        .append("rect")
                        .attr("x", graphX)
                        .attr("width", graphWidth)
                        .attr("y", function (d) {
                            return y(d.potential)
                        })
                        .attr("height", function (d) {
                            return height - y(0) - y(d.potential)
                        })
                        .style("fill", graphFill)
                        .style("stroke", strokeColor)

                    // Negative values chart
                    innerChart
                        .append("g")
                        .selectAll("g")
                        .data(data)
                        .enter()
                        .append("rect")
                        .attr("x", graphX)
                        .attr("width", graphWidth)
                        .attr("y", function (d) {
                            return y(0)
                        })
                        .attr("height", function (d) {
                            return y(0) - y(-d.potential)
                        })
                        .style("fill", graphFill)
                        .style("stroke", strokeColor)

                    // add event handlers
                    innerChart.selectAll("rect")
                        .on("mouseover", function (d, a, b) { return tooltip.mouseover(d, a, b) })
                        .on("mousemove", function () { return tooltip.mousemove() })
                        .on("mouseout", function (d) { return tooltip.mouseout(d) })


                    // create middle line
                    // innerChart.append("g").attr("class", "line")
                    //     .append("line")
                    //     .attr("x1", .5)
                    //     .attr("x2", innerWidth - 1)
                    //     .attr("y1", h - .5)
                    //     .attr("y2", h - .5)
                    //     .style("stroke", "#000")




                })
            }
        }

        // outside chart function
        chart.tooltip = function (_) {
            if (!arguments.length) return tooltip;
            tooltip = _;
            return chart;
        };

        chart.margin = function (_) {
            if (!arguments.length) return margin;
            margin = _;
            return chart;
        };

        chart.width = function (_) {
            if (!arguments.length) return width;
            width = _;
            return chart;
        };

        chart.height = function (_) {
            if (!arguments.length) return height;
            height = _;
            return chart;
        };

        return chart

    })

    React.useEffect(() => {
        setData(data)
    }, [data?.length])

    return (
        <>
            <div
                ref={ref}
                style={{
                    height: "100%",
                    width: "100%",
                    marginRight: "0px",
                    marginLeft: "0px",
                    position: "relative"
                }}
            ></div>

        </>
    )

}

export default CustomBarChart
