import React from "react"
import { Chart, Series, Size, Tooltip, ArgumentAxis, ValueAxis } from 'devextreme-react/chart';
import { Stack } from "@mui/material";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { BUILDING_TYPES_ENG } from "../../constants/simulation";


const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "white",
        color: "black !important",
        fontSize: "12px !important",
        textTransform: "capitalize",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: '12px',
    },
    textAlign: "center !important"
}));

const StyledTableRow = styled(TableRow)(() => ({
    '&:nth-of-type(even)': {
        backgroundColor: "white",
    },
    '&:nth-of-type(odd)': {
        backgroundColor: "#80808038",
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },

}));

const MultipleCategoriesXAxisChart = ({ chartData }) => {

    const TooltipTemplate = (info) => {
        const percentageValue = info.point._dataItem.data.valuePercentage.toFixed(2) || info.point._dataItem.data.valuePercentage
        return <p className="m-0 p-0">{percentageValue}%</p>
    }

    return (
        <Box>
            <Stack direction="row">
                <Typography
                    marginY="auto"
                    marginLeft="-35px"
                    marginRight="-30px"
                    sx={{ transform: "rotate(-90deg)", fontWeight: "bold" }}
                >
                    Bouwperiode
                </Typography>
                <Box>
                    <Stack direction="row" gap={2}>
                        {Object.entries(chartData).map(([dataSourceKey, dataSourceValues], index) => (
                            <Stack direction="column" gap={1} key={dataSourceKey}>
                                <Typography
                                    textAlign="center"
                                    sx={{ textTransform: "capitalize", fontSize: "12px", fontWeight: "bold" }}
                                >
                                    {BUILDING_TYPES_ENG[dataSourceKey]}
                                </Typography>
                                <Chart
                                    id={dataSourceKey}
                                    dataSource={reverserArr(dataSourceValues) || []}
                                    rotated={true}
                                    argumentAxis={{ label: { visible: index===0 } }}
                                >
                                    <Size height={200} width={index === 0 ? 220 : 140} />
                                    <Series
                                        valueField="valuePercentage"
                                        // valueField="value"
                                        argumentField="label"
                                        name={dataSourceKey}
                                        showInLegend={false}
                                        type="bar"
                                        color="#66b554"
                                    />
                                    <ArgumentAxis
                                        position="left"
                                        placeholderSize={index === 0 ? 88: -30}
                                    /> 
                                    <ValueAxis 
                                        position="bottom"
                                        defaultVisualRange={[0, 100]}
                                        aggregatedPointsPosition="crossTicks"
                                    />
                                    <Tooltip
                                        enabled={true}
                                        contentRender={TooltipTemplate}
                                    />
                                </Chart>
                            </Stack>
                        ))}
                    </Stack>
                </Box>
            </Stack>
            {/* middle title */}
            <Typography textAlign="center" sx={{ fontWeight: "bold" }}>Aantal gebouwen</Typography>
            {/* table */}
            <TableContainer>
                <Table size="small" sx={{ minWidth: 100, width: "100%" }}>
                    <TableHead >
                        <TableRow>
                            <StyledTableCell></StyledTableCell>
                            {Object.keys(chartData)?.map(key => (
                                <StyledTableCell key={key} align="right">{BUILDING_TYPES_ENG[key]}</StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {chartData?.["detached_building"]?.map((row, index) => (
                            <StyledTableRow key={index}>
                                <StyledTableCell align="right">{row?.label}</StyledTableCell>
                                {Object.keys(chartData)?.map((key) => (
                                    <StyledTableCell align="right">{chartData?.[key][index]?.value}</StyledTableCell>
                                ))}
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}

function reverserArr(arr){
    return [...arr]?.reverse()
}

export default MultipleCategoriesXAxisChart