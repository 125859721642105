import React from 'react'
import { useSelector } from 'react-redux';
import Grid from "@material-ui/core/Grid";

import chartApiService from '../../services/chartApiService';
import { constructionPeriosDataSkelton } from '../../constants/tabPanel';
import CutomPaper from "../sharedComponents/cutomPaper";
import MultipleCategoriesXAxisChart from '../charts/multipleCategoriesXAxisChart';
import TabPanelContainer from '../sharedComponents/TabPanelContainer';
import TabsInfoMessage from '../sharedComponents/TabsInfoMessage';
import ChartApiService from "../../services/chartApiService";


const ConstructionPeriod = ({ handleTabData, data }) => {
    const { municipalityName = null } = useSelector(state => state?.map?.selectedArea)
    const { areaName: statisticalSectorArea, loading: sectorLoading } = useSelector(state => state?.statisticalSector)
    const [loading, setLoading] = React.useState(false)
    const [sectLoading, setSectLoading] = React.useState(false)

    React.useEffect(async () => {
        if (["", null, undefined].includes(municipalityName)) {
            handleTabData("construction", null)
            return
        }
        if (![null, undefined].includes(data?.municipality) && (statisticalSectorArea === null || data?.sector !== null)) return
        if (municipalityName.length) {
            setLoading(true)

            const finalData = {
                municipality: null,
                sector: null
            }

            const munData = await chartApiService.getConstructionPeriodChartDataOfMunicipality(municipalityName)
            finalData.municipality = calculatePercentages(munData)
            if (statisticalSectorArea) {
                const sectData = await chartApiService.getConstructionPeriodChartDataOfSector(statisticalSectorArea)
                finalData.sector = calculatePercentages(sectData)
            }
            handleTabData("construction", finalData)
            setLoading(false)
        }

    }, [municipalityName, data]);

    React.useEffect(async () => {
        if (municipalityName.length) {
            setSectLoading(true)
            const finalData = {
                municipality: null,
                sector: null
            }
            if (statisticalSectorArea) {
                const sectData = await chartApiService.getConstructionPeriodChartDataOfSector(statisticalSectorArea)
                finalData.sector = calculatePercentages(sectData)
            }
            handleTabData("construction", finalData)
            setSectLoading(false)
        }
    }, [statisticalSectorArea]);

    const NormalGraphs = ({ chartData }) => (
        <Grid container justifyContent='center'>
            <CutomPaper elevation={2}>
                <MultipleCategoriesXAxisChart chartData={chartData} />
            </CutomPaper>
        </Grid>
    )

    const StatisticalGraphs = ({ chartData }) => (
        <Grid container justifyContent='center'>
            <CutomPaper elevation={2}>
                <MultipleCategoriesXAxisChart chartData={chartData} />
            </CutomPaper>
        </Grid>
    )

    return (
        <>
            <TabPanelContainer
                title="GEBOUWBESTAND"
                description="Hieronder wordt de leeftijd van het gebouwbestand van de geselecteerde 
                gemeente weergegeven, opgesplitst per typologie."
                showStatisticalSector={![null, "", undefined].includes(statisticalSectorArea)}
                statisticalSectorAreaName={statisticalSectorArea}
                render={data ? <NormalGraphs chartData={data?.municipality || {}} /> : <TabsInfoMessage {...loading ? { message: "Loading..." } : null} />}
                statisticalSectorRender={data?.sector ? <StatisticalGraphs chartData={data?.sector || {}} /> : <TabsInfoMessage {...sectLoading ? { message: "Loading..." } : null} />}
            />
        </>
    )
}

function calculatePercentages(data) {
    const dataModifled = {}
    Object.entries(data).map(([key, values]) => {
        const valuesSum = values.reduce((acc, o) => acc + parseInt(o.value), 1)

        const valuesModified = values.map((value) => {
            const valuePercentage = value.value / valuesSum * 100
            return {
                ...value,
                valuePercentage
            }
        })
        dataModifled[key] = valuesModified
    })
    return dataModifled
}

export default React.memo(ConstructionPeriod)