import React from 'react'
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CircularProgress from '@mui/material/CircularProgress';

const DownloadButton = ({
    loading,
}) => {
    return (
        <>
            {
                loading ? (
                    <CircularProgress
                        size={25}
                        color="secondary"
                        style={{ position: "absolute" }}
                    />
                ) : (
                    <FileDownloadIcon sx={{ fill: "white", paddingX: "2px", }} />
                )}
        </>
    )
};
export default DownloadButton
