import React from 'react'
import { Box, Stack, Typography, styled } from "@mui/material";

import { SearchBar } from '../../homePage/searchBar';
import { SEARCH_BAR_TYPES } from '../../../constants/map';

const BoxStyled = styled(Box)({
    background: '#43b02a',
    width: "100%",
    height: "min-content",
    paddingTop: "14px",
    paddingBottom: "8px",
    paddingRight: "8px",
    paddingLeft: "8px",
    borderRadius: "3px",
    boxShadow: "0px 0px 10px 0px #00000035"
})

const SearchContainer = () => {
    // const simulationText = "simuleer je renovatiepakket/renovatiemaatregelen"
    const simulationText = "SIMULEER JE RENOVATIE"

    return (
        <BoxStyled id="municipility-search">
            <Stack direction="column" gap={1} alignItems="center">
                <Typography color="white" textTransform="capitalize" variant="h2" fontSize="15px" fontWeight="bold">
                    {simulationText}
                </Typography>
                <SearchBar
                    searchBarType={SEARCH_BAR_TYPES.WITH_SEARCH_ICON}
                    searchBarClickHandler={() => { }}
                    placeHolderText={"Gent"}
                    setActiveView={() => { }}
                    inputStyle={{
                        width: "216px",
                        padding: "2px",
                    }}
                    searchIconStyle={{
                        height: "14px",
                        width: "14px",
                        top: "7px",
                        right: "11px",
                    }}
                />
            </Stack>
        </BoxStyled>
    )
}

export default SearchContainer