import { SET_PRODUCT_DEMO_STATE } from "../constants/constants"

const INIT_STATE = {
    run: false,
    stepIndex: -1,
    steps: []
}

const productDemoReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_PRODUCT_DEMO_STATE:
            const { payload } = action
            return {
                ...state,
                ...payload
            }

        default:
            return state
    }
}

export default productDemoReducer