export const ROUTES = {
  HOME_PAGE: "/",
  MAP_PAGE: "/map-analysis",
  MAP_PAGE_SIMULATION: "/map-simulation",
  SIMULATION_OVERVIEW: "/simulation-overview",
  LOGIN_PAGE: "/login",
  REGISTRATION: "/registration",
  CHANGE_PASS: "/change-password",
  FORGOT_PASS: "/forgot-password",
  FORGOT_PASS_CHANGE: "/forgot-password-change",
  AGIFLY: "https://agifly.be/",
  ABOUT_PAGE: "https://www.vvsg.be/over-ons",
  CONTACT_PAGE: "https://www.vvsg.be/Contacteer-ons",
};
