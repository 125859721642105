import React from 'react';
import * as d3 from 'd3';


export const useD3 = (renderChartFn) => {
    const ref = React.useRef();
    const [data, setData] = React.useState([])

    React.useEffect(() => {
        // renderChartFn(d3.select(ref.current));
        if (ref.current === null) return
        const svg = d3.select(ref.current)
        svg.selectAll("*").remove()

        d3.select(ref.current).data([data]).call(renderChartFn())

        return () => { };
    }, [data]);
    return { ref, setData };
}