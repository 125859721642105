import React, {useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


export const PopupForm = ({
    open=false,
    title="",
    content="",
    fieldNames= [],
    submitText="Bevestig",
    cancelText="Annuleer",
    fieldDefaultValues=[],
    onSubmitHandler= ()=> {},
    onCancelHandler= ()=> {}
                                  }) => {
    const [localValues, setLocalValues] = useState([])

    // useEffect(()=> {
    //     setLocalValues([...fieldDefaultValues])
    // }, [fieldDefaultValues])
    //
    const onValueChangeHandler = (newValue, index)=> {
        let newValues = [...localValues]
        newValues[index] = newValue
        setLocalValues(newValues)
    }

    return (
        <>
            <Dialog open={open} onClose={onCancelHandler}>
                {title.length > 0 && <DialogTitle>{title}</DialogTitle>}
                <DialogContent>
                    { content.length > 0 &&
                        <DialogContentText>
                            {content}
                        </DialogContentText>
                    }
                    {
                        fieldNames.map((field, index)=> {
                            return (
                                <TextField
                                    className="m-0"
                                    key={index}
                                    autoFocus
                                    margin="dense"
                                    defaultValue={fieldDefaultValues[index]}
                                    id={`${field}_${index.toString()}`}
                                    label={field}
                                    type="text"
                                    variant="standard"
                                    onChange={(event)=> onValueChangeHandler(event.target.value, index)}
                                />
                            )
                        })
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancelHandler}>{cancelText}</Button>
                    <Button onClick={()=> onSubmitHandler(localValues)} variant='contained' style={{ backgroundColor: "#42b02b", color: "#fff", borderRadius: '25px' }}>{submitText}</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}