import { Stack, styled, Button } from '@mui/material'
import React from 'react'

const MainButton = styled(Button)({
    borderRadius: 20,
    background: "#43b02a",
    textTransform: 'capitalize',
    boxShadow: "none",
    height: 28,
    marginTop: 6,
    marginBottom: 6,
    marginRight: "auto",
    marginLeft: "auto",
    ":hover": {
        background: "#43b02a",
        boxShadow: "1px 1px solid gray"
    }
})

const SimulationRun = ({ isButtonDisabled, runSimulaitonHandler }) => {
    return (
        <Stack direction="row" alignItems="center" width="100%">
            <MainButton variant="contained" disableRipple onClick={runSimulaitonHandler} disabled={isButtonDisabled}>Resultaten</MainButton>
        </Stack>
    )
}

export default SimulationRun