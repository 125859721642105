import { BASE_URL } from "./constants";

// prod
export const GEOSERVER_URL = "https://geo.production.ikrb.agifly.cloud";
export const GEOSERVER_STORE = "vvsg";
// stage
// export const GEOSERVER_URL = "https://geo.ikwz.agifly.cloud";
// export const GEOSERVER_STORE = "vvsg_stage";

/**
 * User Auth
 */
export const userRegister = `/api/v1/user/auth/register`;
export const login = `/api/v1/user/auth/login`;
export const refresh = `/api/v1/user/auth/token/refresh`;
export const forgotPass = `/api/v1/user/email/forget-password`;
export const changePass = `/api/v1/user/auth/reset-password`;
export const logout = `/api/v1/user/auth/logout`;
export const checkEmail = `api/v1/user/email/exist`;

/**
 * Map
 */
export const mapDetailsOfRequestedArea = `${GEOSERVER_URL}/ows?service=WFS&version=1.0.0&request=GetFeature`;
export const municipalityMapDetails = `${BASE_URL}/api/v1/map/municipality-details`
export const streetSegmentDetails = `${BASE_URL}/api/v1/map/street-details`
export const searchAreas = `${BASE_URL}/api/v1/map/search-area`
export const getStreetInfo = `${BASE_URL}/api/v1/map/street-info`
export const mapDetailsOfClickedArea = `${GEOSERVER_URL}/wms?request=GetFeatureInfo&service=WMS&version=1.1.1&srs=urn:x-ogc:def:crs:EPSG:4326&info_format=application/json`

/**
 * Chart
 */
export const constructioperiosChartDetails = `${BASE_URL}/api/v1/chart/buildings`;
export const constructioperiosChartDetailsSector = `${BASE_URL}/api/v1/chart/buildings/sector`;
export const investmentDataDetails = `${BASE_URL}/api/v1/chart/investment`;
export const potentialDataDetails = `${BASE_URL}/api/v1/chart/potential`;
export const energyChartDetail = `${BASE_URL}/api/v1/chart/energy`;

/**
 * Simulation
 */
export const simulation = `${BASE_URL}/api/v1/simulation`
export const runSimulation = `${BASE_URL}/api/v1/simulation/run`
export const getTypologies = `${BASE_URL}/api/v1/simulation/building_types`
export const getPlans = `${BASE_URL}/api/v1/simulation/renovation_scenarios`
