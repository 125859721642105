import { runSimulation, getTypologies, getPlans } from "../redux/constants/ApiEndPoints"
import { ApiService } from "./ApiService";

class AdvancedMapService extends ApiService {
    runSimulation(payload) {
        return this.apiWrapper("Could not complete simulation.", runSimulation, "post", true, payload)
    }

    async getTypologies(municipalityName) {
        return this.apiWrapper("Could not get Typologies",
            `${getTypologies}/${municipalityName}`, "get", true)
    }

    getPlans() {
        return this.apiWrapper("Could not get renovation plans", getPlans, "get", true)
    }

}

export default new AdvancedMapService()
