import { ApiService } from "./ApiService";
import {
    mapDetailsOfRequestedArea,
    municipalityMapDetails,
    streetSegmentDetails,
    searchAreas, getStreetInfo, GEOSERVER_STORE, mapDetailsOfClickedArea
} from "../redux/constants/ApiEndPoints";
// import L from "leaflet";
// import proj4 from "proj4";

class MapAPIService extends ApiService {
    
    async getSearchedAreas(query) {
        const searchedAreas = await this.apiWrapper("Could not get searched Areas", searchAreas, "post", false, query);
        const arr = [];

        searchedAreas.data[0].areas = [...searchedAreas?.data[0]?.areas.filter(area => {
            const {
                streetName,
                houseNo,
                streetId,
                postCode,
                municipalityName
            } = area;

            const entry =
                `${streetName} ${houseNo} ${streetId} ${postCode} ${municipalityName}`

            const doesNotHave = !arr.includes(entry);

            if (doesNotHave) arr.push(entry)

            return doesNotHave;
        })];

        return searchedAreas;
    }

    async getStreetInfo(streetId) {
        return await this.apiWrapper("Could not get street details", getStreetInfo+'?street_id='+streetId, "get",
            false)
    }

    async getMunicipalityDetails(municipalityId, privateUser = false) {
        return await this.apiWrapper("Could not get municipality details", municipalityMapDetails, "post",
            privateUser === true, {municipalityId: municipalityId})
    }

    async getStreetDetails(street_id, privateUser=false) {
        return await this.apiWrapper("Could not get Street details", streetSegmentDetails,
            "get", privateUser===false, {street_id: street_id})
    }
    
    async getRequestedMapArea(bounds = null, mapLayer = null, geomColumn = "geom", cql = null, type = "geojson", returnUrl = false, epsgCode = 4326) {
        let apiUrl = `${mapDetailsOfRequestedArea}&typeName=${mapLayer}`
        if (type === "geojson") {
            apiUrl += "&outputFormat=application/json"
        } else if (type === "shapefile") {
            apiUrl += "&outputFormat=shape-zip"
        } else if (type === "csv") {
            apiUrl += "&outputFormat=csv"
        }
        let localCql = "&CQL_FILTER=";
        if (bounds) {
            localCql += `BBOX(${geomColumn}, ${bounds._southWest.lat},${bounds._southWest.lng},${bounds._northEast.lat},${bounds._northEast.lng}, 'urn:x-ogc:def:crs:EPSG:${epsgCode}')`;
            if (cql) {
                if (cql.includes("=")) {
                    let name = cql.substring(cql.indexOf('=') + 1);
                    name = name.replace(/[^a-zA-Z -]/g, "")
                    localCql += ` AND municipality = '${name}'`;
                } else if (cql.includes("<>")) {
                    let name = cql.substring(cql.indexOf('>') + 1);
                    name = name.replace(/[^a-zA-Z -]/g, "")
                    localCql += ` AND municipality <> '${name}'`;
                }
            }
        } else {
            if (cql) {
                if (cql.includes("=")) {
                    let name = cql.substring(cql.indexOf('=') + 1);
                    name = name.replace(/[^a-zA-Z -]/g, "");
                    if (type === "shapefile") {
                        apiUrl += `&format_options=filename:${name}.zip`
                        name = name.toLowerCase();
                    } else if (type === "csv") {
                        apiUrl += `&format_options=filename:${name}.csv`
                    }
                    localCql += `municipality='${name}'`;
                } else if (cql.includes("<>")) {
                    let name = cql.substring(cql.indexOf('>') + 1);
                    name = name.replace(/[^a-zA-Z -]/g, "")
                    localCql += `municipality<>'${name}'`;
                }
            }
        }
        apiUrl +=
            (bounds || cql ? localCql : "") +
            `&srsName=urn:x-ogc:def:crs:EPSG:${epsgCode}`;
        if (returnUrl) return apiUrl;

        return await this.apiWrapper(
            "Could not get requested area map",
            apiUrl,
            "get",
            false,
            {},
            true
        );
    }

    async getRequestedMapAreaSimple(bounds = null, mapLayer = null, geomColumn = "geom", cql = null, type = "geojson", returnUrl = false, epsgCode = 4326) {
       let apiUrl = `${mapDetailsOfRequestedArea}&typeName=${mapLayer}`
        if (type === "geojson")
            apiUrl += "&outputFormat=application/json"
        else
            apiUrl += "&outputFormat=shape-zip"
        let localCql = "&CQL_FILTER=";
        if (bounds)
            localCql += `BBOX(${geomColumn}, ${bounds._southWest.lat},${bounds._southWest.lng},${bounds._northEast.lat},${bounds._northEast.lng}, 'urn:x-ogc:def:crs:EPSG:4326')`;
        else if (cql) {
            localCql += cql;
        }
        apiUrl +=
            (bounds || cql ? localCql : "") +
            `&srsName=urn:x-ogc:def:crs:EPSG:${epsgCode}`;

        if (returnUrl) return apiUrl;

        return await this.apiWrapper(
            "Could not get requested area map",
            apiUrl,
            "get",
            false,
            {},
            true
        );
    }

    async getRequestedMuniOutline(bounds = null, mapLayer = null, geomColumn = "geom", cql = null, type = "geojson", returnUrl = false, epsgCode = 4326) {
        let apiUrl = `${mapDetailsOfRequestedArea}&typeName=${mapLayer}`
        if (type === "geojson")
            apiUrl += "&outputFormat=application/json"
        else
            apiUrl += "&outputFormat=shape-zip"
        let localCql = "&CQL_FILTER=";
        if (bounds)
            localCql += `BBOX(${geomColumn}, ${bounds._southWest.lat},${bounds._southWest.lng},${bounds._northEast.lat},${bounds._northEast.lng}, 'urn:x-ogc:def:crs:EPSG:4326')`;
        else if (cql) {
            localCql += cql;
        }
        apiUrl +=
            (bounds || cql ? localCql : "") +
            `&srsName=urn:x-ogc:def:crs:EPSG:${epsgCode}`;

        if (returnUrl) return apiUrl;

        return await this.apiWrapper(
            "Could not get requested area map",
            apiUrl,
            "get",
            false,
            {},
            true
        );
    }
    async getClickedMapArea(x, y, bounds=null, mapLayer=`${GEOSERVER_STORE}:hStreet`, mapWidth=500, mapHeight=500, bufferAroundClick=5, cql=null) {
        let apiUrl = `${mapDetailsOfClickedArea}&width=${mapWidth}&height=${mapHeight}&query_layers=${mapLayer}&layers=${mapLayer}&TYPENAME=${mapLayer}&x=${x}&y=${y}&exceptions=application/json`
        let localCql = ""
        if (bounds)
            apiUrl += `&buffer=${bufferAroundClick}&bbox=${bounds._southWest.lat},${bounds._southWest.lng},${bounds._northEast.lat},${bounds._northEast.lng}`
        if (cql) {
            if (bounds)
                localCql += ";"
            localCql += cql
        }
        return await this.apiWrapper("Could not get requested area map",
            apiUrl + localCql,
            "get", false, {}, true)
    };

}

export default new MapAPIService()