import { Grid } from "@material-ui/core";

export const FillButton = ({
    name,
    onClickHandler = () => { },
    classes = "sidepanel-ful-btn ml-2",
    extra_classes = "",
}) => {
    return (
        <button className={classes + " " + extra_classes} onClick={onClickHandler}>
            {name}
        </button>
    )
};

export const ToggleButton = ({
    name,
    simulationenv,
    onClickHandler = () => { },
    classes = "sidepanel-ful-btn ml-2",
    extra_classes = "",
}) => {
    const buttonHtml = (
        <button className={classes + " " + extra_classes} onClick={onClickHandler} style={{pointerEvents: 'auto', minWidth: '20%', marginTop: '-14px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}}>
            {name}
        </button>
    )
    const ToggleButton = (
        <Grid container className="sidepanel-container" justifyContent="flex-end">
            <Grid container justifyContent="flex-end" item xs={12} sm={12} md={12} lg={12} xl={12} >
                <button className={classes} onClick={onClickHandler} style={{pointerEvents: 'auto', minWidth: '80%', height: '40px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}}>
                    {name}
                </button>
            </Grid>
        </Grid>
    )
    return (
        <>
            {simulationenv ?
                (ToggleButton)
                :
                (buttonHtml)
            }
        </>
    )
};