import { Stack, Typography } from "@mui/material";

export const steps = [
    {
        target: '#municipility-search',
        content: (
            <Stack direction="column" gap={1} alignItems="flex-start">
                <Typography color="#702082" variant="body2" fontSize="16px" fontWeight="bold">1. Kies de gemeente</Typography>
                {/* <Typography color="#5f5f5f" variant="body2" fontSize="12px" textAlign="left">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                    industry's standard dummy text ever since the 1500s, when an unknown printer.</Typography> */}
            </Stack>
        ),
        disableBeacon: true,
        hideFooter: true,
        placement: "right",
    },
    {
        target: '#simulation-typologies',
        content: (
            <Stack direction="column" gap={1} alignItems="flex-start">
                <Typography color="#702082" variant="body2" fontSize="16px" fontWeight="bold">2. Selecteer</Typography>
                <Typography color="#5f5f5f" variant="body2" fontSize="12px" textAlign="left">Selecteer het type woningen waarop je de maatregel wil simuleren</Typography>
            </Stack>
        ),
        disableBeacon: true,
        hideFooter: true,
        placement: "right-end",
    },
    {
        target: '#add-simulation',
        content: (
            <Stack direction="column" gap={1} alignItems="flex-start">
                <Typography color="#702082" variant="body2" fontSize="16px" fontWeight="bold">3. Scenario toevoegen</Typography>
                {/* <Typography color="#5f5f5f" variant="body2" fontSize="12px" textAlign="left">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                    industry's standard dummy text ever since the 1500s, when an unknown printer.</Typography> */}
            </Stack>
        ),
        disableBeacon: true,
        hideFooter: true,
        placement: "right",
    },
    {
        target: '#download-simulation',
        content: (
            <Stack direction="column" gap={1} alignItems="flex-start">
                <Typography color="#702082" variant="body2" fontSize="16px" fontWeight="bold">4. Downloaden</Typography>
                {/* <Typography color="#5f5f5f" variant="body2" fontSize="12px" textAlign="left">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                    industry's standard dummy text ever since the 1500s, when an unknown printer.</Typography> */}
            </Stack>
        ),
        disableBeacon: true,
        hideFooter: true,
        placement: "right",
    },
    {
        target: '#save-simulation',
        content: (
            <Stack direction="column" gap={1} alignItems="flex-start">
                <Typography color="#702082" variant="body2" fontSize="16px" fontWeight="bold">5. Opslaan/Bewaren</Typography>
                {/* <Typography color="#5f5f5f" variant="body2" fontSize="12px" textAlign="left">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                    industry's standard dummy text ever since the 1500s, when an unknown printer.</Typography> */}
            </Stack>
        ),
        disableBeacon: true,
        hideFooter: true,
        placement: "right",
    },
]