import React from 'react';
import {
    Chart, Series, CommonSeriesSettings, Legend, Export, Size, Tooltip, 
    ArgumentAxis, Label, ValueAxis
} from 'devextreme-react/chart';


const MultipleCategoriesHorizontalChart = (
    {
        title,
        data,
        rotated,
        name,
        size,
        valueField,
        argumentField,
        barColor,
    }
) => {

    const TooltipTemplate = (info) =>{
        let { originalValue = null } = info
        originalValue = originalValue?.toFixed(1) || originalValue
        return <>{`${originalValue}%`}</>
    }

    const customizeText = (e) => {
        console.log(e)
        return e.value;
    }

    return (
        <Chart
            id="chart"
            palette="Soft"
            rotated={rotated}
            dataSource={data}
        >
            <Size height={size}/>
            <ArgumentAxis label={{wordWrap: "normal", displayMode: "rotate", rotationAngle: 45}}>
                {/* <Label customizeText={customizeText}/> */}
            </ArgumentAxis>
            <CommonSeriesSettings
                argumentField="label"
                type="bar"
                ignoreEmptyPoints={true}
            />
            <Series valueField="current" name="Huidige toestand" color="#702081" />
            <Series valueField="Goedkoopste" name="Goedkoopste" color="#66b554" />
            <Series valueField='Niet meer dan anders' name="Niet meer dan anders" color="#7ecd6e" />
            <Series valueField='A-label' name="A-label" color="#0A9D35" />
            <Series valueField='MAX CO2' name="MAX CO₂" color="#212752" />
            <Tooltip
                enabled={true}
                contentRender={TooltipTemplate}
            />
            <Legend 
                verticalAlignment="bottom" 
                horizontalAlignment="center"
                columnCount={5}
                font={{size: 12}}
                // rowCount={0}
                columnItemSpacing={10} // 20 - default
                itemTextPosition="right"
                paddingLeftRight={0}
            />
            <ValueAxis 
                label={{font: {size: 12}}}
            />
            {/*<Export enabled={true}/>*/}
        </Chart>
    );
}

export default MultipleCategoriesHorizontalChart