import L from "leaflet";
import {
    EPC_LABEL_TO_DB_MAPPING
} from "../constants/map";
import { INVESTMENT_COLORS, TENANT_COLORS, POTENTIAL_COLORS } from "../constants/sidepanel";
import {
    HEAT_STATES_COLORS,
    COLLECTIVE_HEATING_PROBABILITY_CLUSTERING,
    TENANTS_GRADIENT_COLORS
} from "../constants/sidepanel";
import { incomeColors } from "./incomeColors";
import { ageColors } from "./ageColors";

let filopcity = null;

export const scale = (fromRange, toRange) => {
  const d = (toRange[1] - toRange[0]) / (fromRange[1] - fromRange[0]);
  return (from) => (from - fromRange[0]) * d + toRange[0];
};

export function interpolateColor(color1, color2, factor) {
    if (arguments.length < 3) {
        factor = 0.5;
    }
    var result = color1.slice();
    for (var i = 0; i < 3; i++) {
        result[i] = Math.round(result[i] + factor * (color2[i] - color1[i]));
    }
    // console.log("Hex: ", rgbToHex(result))
    return result;
};

// My function to interpolate between two colors completely, returning an array
export function interpolateColors(color1, color2, steps) {
    var stepFactor = 1 / (steps - 1),
        interpolatedColorArray = [];
    color1 = color1.match(/\d+/g).map(Number);
    color2 = color2.match(/\d+/g).map(Number);

    for (var i = 0; i < steps; i++) {
        interpolatedColorArray.push(interpolateColor(color1, color2, stepFactor * i));
    }

    return interpolatedColorArray;
};

const streetStyleValues = (color) => {
    return {
        color,
        zIndex: 1000,
        weight: 5,
    }
};

const sectStyleValues = (fillColor, filopcity) => {
    return {
        fillColor,
        // fillOpacity: 1,
        fillOpacity: filopcity,
        color: 'gray',
        weight: 2,
        opacity: 1,
        zIndex: 100,
    }
};

const selectedSectStyleValues = () => {
    return {
        color: "#1f0404",
        weight: 3,
        opacity: 1,
        zIndex: 100,
    }
};

export const getExtremesInGeoJSON = (geojson, attr) => {
    let extremes = {
        min: 100000000000000,
        max: -1
    }
    geojson.features.forEach((feature) => {
        if (feature.properties[attr] > extremes.max)
            extremes.max = feature.properties[attr]
        if (feature.properties[attr] < extremes.min)
            extremes.min = feature.properties[attr]
    })
    return extremes
};

export const getMarkerIcon = (iconUrl = "/icons/mapMarker.png", width = 25, height = 25, anchorLeft = 10, anchorRight = 10) => {
    return L.icon({
        iconUrl: iconUrl,
        iconSize: [width, height],
        iconAnchor: [anchorLeft, anchorRight],
        pane: "markerPane"
    })
};

export const getStreetHeatLayer = (
    streetsGeometry
) => {
    return L.geoJSON(streetsGeometry, {
        title: 'streets-heat-layer',
        style: (feature) => {
            if (feature.properties.epc_label < 100) {
                return {
                    color: "#489323",
                    weight: 5,
                }
            } else if (feature.properties.epc_label >= 100 && feature.properties.epc_label < 601) {
                return {
                    color:
                        HEAT_STATES_COLORS[
                            EPC_LABEL_TO_DB_MAPPING[feature.properties.epc_label / 100]
                            ],
                    weight: 5,
                };
            } else {
                return {
                    color: "#ED181B",
                    weight: 5,
                };
            }
        },
    });
};

export const getStreetInvestmentLayer = (
    streetsGeometry,
) => {
    return L.geoJSON(streetsGeometry, {
        title: 'streets-investment-layers',
        style: (feature) => {
            if (feature.properties.total_cost <= 49999) {
                return {
                    ...streetStyleValues(INVESTMENT_COLORS.A),
                };
            } else if (
                feature.properties.total_cost > 49999 &&
                feature.properties.total_cost <= 99999
            ) {
                return {
                    ...streetStyleValues(INVESTMENT_COLORS.B),
                };
            } else if (feature.properties.total_cost > 99999) {
                return {
                    ...streetStyleValues(INVESTMENT_COLORS.C),
                };
            } else {
                return {
                    ...streetStyleValues(INVESTMENT_COLORS.C),
                };
            }
        },
    });
};

export const getStreetPotentialLayer = (streetsGeometry) => {
    const potentialColors = interpolateColors(POTENTIAL_COLORS.leftRgb, POTENTIAL_COLORS.rightRgb, 100)
    return L.geoJSON(streetsGeometry, {
        title: 'streets-potential-layer',
        style: (feature) => {
            if (feature.properties.potential <= -500) {
                return {
                    color: `rgb(${potentialColors[0]})`,
                    weight: 5,
                };
            } else if (
                feature.properties.potential > -500 &&
                feature.properties.potential <= 4500
            ) {
                return {
                    color: `rgb(${potentialColors[Math.round(scale([-500, 4500], [0, 99])(feature.properties.potential))]})`,
                    weight: 5,
                };
            } else {
                return {
                    color: `rgb(${potentialColors[99]})`,
                    weight: 5,
                };
            }
        },
    });
};

export const getStreetSearchDirectionLayer = (streetsGeometry) => {
    return L.geoJSON(streetsGeometry, {
        title: 'streets-collective-heating-layer',
        style: (feature) => {
            return {
                color:
                    COLLECTIVE_HEATING_PROBABILITY_CLUSTERING[
                        feature.properties.cluster_label
                        ],
                weight: 5,
            };
        },
    });
};
// excludeFeatures Structure: { key1: value1, key2: value2 }

export const convertCQLObjToGeoServerCQL = (cqlArray) => {
    let cqlStr = ""
    for (let value of cqlArray) {
        if (value) {
            let subCql = value
            if (typeof value === "object" && value.length) {
                let subQuery = ""
                for (let subValue of value) {
                    if (subValue === "INCLUDE")
                        subQuery = "INCLUDE"
                    else if (subValue !== null) {
                        if (subQuery === "INCLUDE")
                            subQuery = subValue
                        else {
                            if (subQuery !== "")
                                subQuery += "AND"
                            subQuery += subValue
                        }
                    }
                }
                subCql = subQuery
            }
            if (cqlStr !== "")
                cqlStr += ";"
            cqlStr += subCql
        }
    }
    return cqlStr
};

export const getMunicipalityLayer = (municipalityGeometry, wmsInAction) => {
    return L.geoJSON(
        municipalityGeometry,
        {
            title: "municipalityOutline",
            interactive: false,
            style: (feature) => {
                return {
                    "z-index": 1000,
                    // color: `(${!wmsInAction})` ? "gray" : "black",
                    color: "black",
                    dashArray: '9 3',
                    fillOpacity: 0,
                    weight: 5
                }
            },
        }
    );
};

export const getSectorHeatLayer = (
    sectorGeometry,
    selectedStreetLayer
) => {
    if (selectedStreetLayer.length > 0) {
        filopcity = 0.2
    } else {
        filopcity = 1
    }
    return L.geoJSON(
        sectorGeometry,
        {
            title: "Heat Sectors",
            interactive: false,
            style: (feature) => {
                if (feature.properties.epc_label < 100) {
                    return {
                        ...sectStyleValues(HEAT_STATES_COLORS.A, filopcity),
                    };
                } else if (feature.properties.epc_label >= 100 && feature.properties.epc_label < 601) {
                    return {
                        fillColor:
                            HEAT_STATES_COLORS[
                                EPC_LABEL_TO_DB_MAPPING[feature.properties.epc_label / 100]
                                ],
                        // fillOpacity: 1,
                        fillOpacity: filopcity,
                        zIndex: 100,
                        color: 'gray',
                        weight: 2,
                        opacity: 1,
                    };
                } else {
                    return {
                        ...sectStyleValues(HEAT_STATES_COLORS.F, filopcity),
                    };
                }
            },
    });
};

export const getSectorPotentialLayer = (sectorGeometry, selectedStreetLayer) => {
    if (selectedStreetLayer.length > 0) {
        filopcity = 0.2
    } else {
        filopcity = 1
    }
    const potentialColors = interpolateColors(POTENTIAL_COLORS.leftRgb, POTENTIAL_COLORS.rightRgb, 100)
    return L.geoJSON(
        sectorGeometry,
        {
            title: "Potential Sectors",
            interactive: false,
            style: (feature) => {
                if (feature.properties.potential <= -500) {
                    return {
                        fillColor: `rgb(${potentialColors[0]})`,
                        // fillOpacity: 1,
                        fillOpacity: filopcity,
                        zIndex: 100,
                        color: 'gray',
                        weight: 2,
                        opacity: 1,
                    };
                } else if (
                    feature.properties.potential > -500 &&
                    feature.properties.potential <= 4500
                ) {
                    return {
                        fillColor: `rgb(${potentialColors[Math.round(scale([-500, 4500], [0, 99])(feature.properties.potential))]})`,
                        // fillOpacity: 1,
                        fillOpacity: filopcity,
                        zIndex: 100,
                        color: 'gray',
                        weight: 2,
                        opacity: 1,
                    };
                } else {
                    return {
                        fillColor: `rgb(${potentialColors[99]})`,
                        // fillOpacity: 1,
                        fillOpacity: filopcity,
                        zIndex: 100,
                        color: 'gray',
                        weight: 2,
                        opacity: 1,
                    };
                }
            },
    });
};

export const getSectorInvestmentLayer = (
    sectorGeometry,
    selectedStreetLayer
) => {
    if (selectedStreetLayer.length > 0) {
        filopcity = 0.2
    } else {
        filopcity = 1
    }
    return L.geoJSON(
        sectorGeometry,
        {
            title: "Investment Sectors",
            interactive: false,
            style: (feature) => {
                if (feature.properties.total_cost <= 49999) {
                    return {
                        ...sectStyleValues(INVESTMENT_COLORS.A, filopcity),
                    };
                } else if (
                    feature.properties.total_cost > 49999 &&
                    feature.properties.total_cost <= 99999
                ) {
                    return {
                        ...sectStyleValues(INVESTMENT_COLORS.B, filopcity),
                    };
                } else if (feature.properties.total_cost > 99999) {
                    return {
                        ...sectStyleValues(INVESTMENT_COLORS.C, filopcity),
                    };
                } else {
                    return {
                        ...sectStyleValues(INVESTMENT_COLORS.C, filopcity),
                    };
                }
            },
    });
};

export const getSectorIncomeLayer = (sectorGeometry, selectedStreetLayer) => {
    //const incomeColors = interpolateColors(INCOME_GRADIENT_COLORS.leftRgb, INCOME_GRADIENT_COLORS.rightRgb, 100)
    if (selectedStreetLayer.length > 0) {
        filopcity = 0.2
    } else {
        filopcity = 1
    }
    return L.geoJSON(
        sectorGeometry,
        {
            title: "Income Sectors",
            interactive: false,
            style: (feature) => {
                if (feature.properties.avg_net_taxable_income_per_inhabitant <= 10000) {
                    return {
                        fillColor: incomeColors[0],
                        // fillOpacity: 1,
                        fillOpacity: filopcity,
                        zIndex: 100,
                        color: 'gray',
                        weight: 2,
                        opacity: 1,
                    };
                } else if (
                    feature.properties.avg_net_taxable_income_per_inhabitant > 10000 &&
                    feature.properties.avg_net_taxable_income_per_inhabitant <= 50000
                ) {
                    return {
                        fillColor:incomeColors[Math.round(scale([10000, 50000], [0, 39])(feature.properties.avg_net_taxable_income_per_inhabitant))],
                        // fillOpacity: 1,
                        fillOpacity: filopcity,
                        zIndex: 100,
                        color: 'gray',
                        weight: 2,
                        opacity: 1,
                    };
                } else {
                    return {
                        fillColor:incomeColors[39],
                        // fillOpacity: 1,
                        fillOpacity: filopcity,
                        zIndex: 100,
                        color: 'gray',
                        weight: 2,
                        opacity: 1,
                    };
                }
            },
    });
};

export const getSectorAgeLayer = (streetsGeometry, selectedStreetLayer) => {
    // const ageColors = interpolateColors(AGE_GRADIENT_COLORS.leftRgb, AGE_GRADIENT_COLORS.rightRgb, 100)
    if (selectedStreetLayer.length > 0) {
        filopcity = 0.2
    } else {
        filopcity = 1
    }
    return L.geoJSON(
        streetsGeometry,
        {
            title: "Age Sectors",
            interactive: false,
            style: (feature => {
                if (feature.properties.age_groups <= 30) {
                    return {
                        fillColor: ageColors[0],
                        // fillOpacity: 1,
                        fillOpacity: filopcity,
                        zIndex: 100,
                        color: 'gray',
                        weight: 2,
                        opacity: 1,
                    };
                } else if (
                    feature.properties.age_groups > 30 &&
                    feature.properties.age_groups <= 60
                ) {
                    return {
                        fillColor: ageColors[Math.round(scale([30, 60], [0, 31])(feature.properties.age_groups))],
                        // fillOpacity: 1,
                        fillOpacity: filopcity,
                        zIndex: 100,
                        color: 'gray',
                        weight: 2,
                        opacity: 1,
                    };
                } else {
                    return {
                        fillColor: ageColors[31],
                        // fillOpacity: 1,
                        fillOpacity: filopcity,
                        zIndex: 100,
                        color: 'gray',
                        weight: 2,
                        opacity: 1,
                    };
                }
            })
        }
    )
};

export const getSectorTenantLayer = (streetsGeometry, selectedStreetLayer) => {
    const tenantColors = interpolateColors(TENANTS_GRADIENT_COLORS.leftRgb, TENANTS_GRADIENT_COLORS.rightRgb, 100)
    if (selectedStreetLayer.length > 0) {
        filopcity = 0.2
    } else {
        filopcity = 1
    }
    return L.geoJSON(
        streetsGeometry,
        {
            title: "Tenant Sectors",
            interactive: false,
            style: (feature => {
                if (feature.properties.tenants_compared_to_households_with_known_property_title <= 20) {
                    return {
                        ...sectStyleValues(TENANT_COLORS.A, filopcity),
                    };
                } else if (feature.properties.tenants_compared_to_households_with_known_property_title > 20 &&
                    feature.properties.tenants_compared_to_households_with_known_property_title <= 40) {
                        return {
                            ...sectStyleValues(TENANT_COLORS.B, filopcity),
                        };
                } else if (feature.properties.tenants_compared_to_households_with_known_property_title > 40 &&
                    feature.properties.tenants_compared_to_households_with_known_property_title <= 60) {
                        return {
                            ...sectStyleValues(TENANT_COLORS.C, filopcity),
                        };
                } else if (feature.properties.tenants_compared_to_households_with_known_property_title > 60 &&
                    feature.properties.tenants_compared_to_households_with_known_property_title <= 80) {
                        return {
                        ...sectStyleValues(TENANT_COLORS.D, filopcity),
                    };   
                } else {
                    return {
                        ...sectStyleValues(TENANT_COLORS.E, filopcity),
                    };
                }
            })
        }
    )
};

export const getInvestmentLabel = (cost) => {
    if (cost <= 50000) {
        return '<50.000€'
    } else if (cost >= 50000 && cost < 100000) {
        return '>50.000€'
    } else {
        return '+100.000€'
    }
};

export const getInvestmentColor = (cost) => {
    if (cost <= 50000) {
        return '#D9C9DE'
    } else if (cost >= 50000 && cost < 100000) {
        return '#B393BD'
    } else {
        return '#69287E'
    }
};

export const makePayloadForSimulationAPI = (simulations, municipalityName, simulationName = null) => {
    const params = {
        municipality: municipalityName,
        params: []
    }
    simulations.map(simulation => {
        const { param } = simulation
        params.params.push(param)
    })
    if (simulationName)
        params['name'] = simulationName

    return params
};

export const getSectorBareLayer = (
    sectorGeometry,
    title='BareSector'
) => {
    return L.geoJSON(
        sectorGeometry,
        {
            title: title,
            interactive: false,
            style: (feature) => {
                    return {
                        ...selectedSectStyleValues(),
                    };
            },
    });
};
