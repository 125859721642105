import { combineReducers } from "redux";
import content from "./content";
import loader from "./loader";
import map from "./map";
import auth from "./auth";
import zoning from "./zoning";
import economicViability from "./economicViability";
import simulationReducer from "./simulationReducer";
import productDemoReducer from "./productDemoReducer";
import statisticalSectorReducer from "./statisticalSector";

const rootReducer = combineReducers({
  auth: auth,
  content: content,
  loader: loader,
  map: map,
  zoning: zoning,
  economicViability: economicViability,
  simulation: simulationReducer,
  productDemo: productDemoReducer,
  statisticalSector: statisticalSectorReducer
});

export default rootReducer;
