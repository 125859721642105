import { Grid } from "@material-ui/core";

export const MultiColorBar = ({
  name,
  colors = [
    {
      hex: "#000000",
      percentage: 100,
      label: colors.label,
    },
  ],
  labels = ["low", "high"],
}) => {
  return (
    <div>
      <div className="rounded-corners d-inline-flex w-full">
        {colors.map((color, index) => {
          return (
            <div
              key={index}
              style={{
                height: "12%",
                width: "15%",
                background: color.hex,
                margin: "2%",
              }}
            >
              <div
                style={{
                  textAlign: "center",
                  font: "14px",
                  fontFamily: "Barlow",
                  fontWeight: "bold",
                  color: `#FFFFFF`,
                }}
              >
                {labels[index]}
              </div>
              {name == "renovation" && color.label && color.active ? (
                <div className="sidepanel-multi-label">
                  <span className="sidepanel-multi-label-span" />
                  {/* <span className='sidepanel-multi-label-span' style={{border:`1px solid ${color.hex}`}}>{color.label}</span> */}
                </div>
              ) : (
                ""
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const TriColorBar = ({
  name,
  colors = [
    {
      hex: "#000000",
      percentage: 100,
      label: colors.label,
    },
  ],
  labels = ["low", "high"],
}) => {
  return (
    <div>
      <div className="rounded-corners d-inline-flex w-full">
        {colors.map((color, index) => {
          return (
            <div
              key={index}
              style={{
                width: "26%",
                background: color.hex,
                marginRight: "5%",
                marginLeft: "5%",
                padding: '2%',
                textAlign: "center",
                font: "10px",
                fontFamily: "Barlow",
                color: `#FFFFFF`,
              }}
            >
              {labels[index]}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const StepGradientBar = ({
  name,
  radius,
  height = 14,
  colors,
  labels = ["0%", "20%", "40%", "60%", "80%", "100%"],
}) => {
  return (
    <div>
      <div className="rounded-corners d-inline-flex w-full" style={{
        height: `${height}px`,
        width: "98%",
        marginLeft: '1%',
        marginRight: '1%',
      }}>
        {colors.map((color, index) => {
          return (
            <div
              key={index}
              style={{
                width: "100%",
                background: color.hex,
                borderTopLeftRadius: `${index === 0 ? 15 : 0}px`,
                borderBottomLeftRadius: `${index === 0 ? 15 : 0}px`,
                borderTopRightRadius: `${index === colors.length-1 ? 15 : 0}px`,
                borderBottomRightRadius: `${index === colors.length-1 ? 15 : 0}px`,
              }}
            />
          );
        })}
      </div>
      <Grid
        className=""
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        {labels.map((label, index) => {
          return (
            <span key={index} className="small text-grey">
              {label}
            </span>
          );
        })}
      </Grid>
    </div>
  );
};

export const PopupGradientBar = ({
  name,
  radius,
  height = 14,
  colors,
  labels = ["20%", "20%", "20%", "20%", "20%"],
}) => {
  return (
    <div>
      <div className="rounded-corners d-inline-flex w-full" style={{
        height: `${height}px`,
        width: "100%",
      }}>
        {colors.map((color, index) => {
          return (
            <div
              key={index}
              style={{
                width: "100%",
                background: color.hex,
                borderTopLeftRadius: `${index === 0 ? 15 : 0}px`,
                borderBottomLeftRadius: `${index === 0 ? 15 : 0}px`,
                borderTopRightRadius: `${index === colors.length-1 ? 15 : 0}px`,
                borderBottomRightRadius: `${index === colors.length-1 ? 15 : 0}px`,
              }}
            />
          );
        })}
      </div>
      <Grid
        className=""
        container
        direction="row"
        justifyContent="space-around"
        alignItems="center"
      >
        {labels.map((label, index) => {
          return (
            <span key={index} className="small text-grey">
              {label}
            </span>
          );
        })}
      </Grid>
    </div>
  );
};

export const MultiStepColorBar = ({
    name,
    labelPostfix=null,
    colors=[{
        hex: "#000000",
        percentage: 100,
        // label:colors.label
    }],
    barHeight="20px",   // height in pixels
    labels=null
  })=> {
    return (
        <div>
            <div className="rounded-corners d-inline-flex w-full">
              {
                colors.map((color, index) => {
                  let classes = "";
                  if (colors.length === 1) {
                    classes = "rounded-corners";
                  } else if (index === 0 && colors.length > 1) {
                    classes = "rounded-corners-left";
                  } else if (index === colors.length - 1 && colors.length > 1) {
                    classes = "rounded-corners-right";
                  }
                  return (
                      <div
                        className={classes}
                        key={index}
                        style={{
                            height: barHeight,
                            width: `${color.percentage}%`,
                            background: color.hex
                        }}
                      >
                      {name=='renovation' && color.label && color.active?
                      <div className='sidepanel-multi-label'>
                        <span className='sidepanel-multi-label-span'/>
                        {/* <span className='sidepanel-multi-label-span' style={{border:`1px solid ${color.hex}`}}>{color.label}</span> */}
                      </div>
                      :''  }
                        
                      </div>
                  )
            })
                }
            </div>
        {labels ? (
          <>
            <br />
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              {
                labels.map((label, index) => {
                  let labelWidth;
                  if (labels.length === 1) {
                    labelWidth = '90%';
                  } else if (labels.length === 2) {
                    if (label > 25 && label < 50) {
                      labelWidth = label
                    } else
                      labelWidth = '45%';
                  } else if (labels.length === 3) {
                    if (label < 25) {
                      labelWidth = '25%'
                    }
                    if (label > 25 && label < 50) {
                      labelWidth = label
                    } else
                      labelWidth = '50%';
                  }
                  return (
                    <span key={index} className="small text-grey" style={{ width: label, textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{label}</span>
                  )
                })
              }
            </Grid>
          </>
        ) : null}
        </div>
    )
}