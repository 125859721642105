import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect, useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import { Grid, Divider } from "@material-ui/core";
import IconButton from "@mui/material/IconButton";
import { MultiColorBar, TriColorBar, StepGradientBar, MultiStepColorBar } from "../sharedComponents/multiColorBar";
import { GradientBar } from "../sharedComponents/gradientBar";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import eye from "../../assets/used/eye.svg";
import hideEye from "../../assets/used/hideEye.svg";
import close from "../../assets/used/close.svg";
import { MAP_LAYERS } from "../../constants/map";
import {
  setSelectedStreetLayer,
  setSelectedSectorLayer,
} from "../../redux/actions/map";
import { activeView } from "../../redux/actions/auth";
import {
  HEAT_STATES_COLORS,
  POTENTIAL_COLORS,
  INVESTMENT_COLORS,
  SEARCH_DIRECTION_COLORS,
  INCOME_COLORS,
  TENANT_COLORS,
  INCOME_GRADIENT_COLORS,
  AGE_GRADIENT_COLORS,
  TENANTS_GRADIENT_COLORS,
} from "../../constants/sidepanel";

import {styled, ButtonGroup, Button} from "@mui/material";
import {SIMULATION_CLICK_STATE} from "../../constants/simulation";
import {onChangeSimulationVisualizationState} from '../../redux/actions/advancedMap';
import { createDownloadPdfURL, pdfNotAvaialbeMunicipilities } from "../../constants/general";

const ButtonStyled = styled(Button)(({ active }) => ({
    background: "white",
    borderRadius: "40px",
    textTransform: "none",
    color: active ? "#65676a !important" : "#d8d8d8 !important",
    borderColor: active ? "green !important" : "#d8d8d8 !important",
    paddingTop: "1px",
    paddingBottom: "1px",
    boxShadow: "6px 4px 12px rgb(0 0 0 / 15%)",

    // ":hover": {
    //     background: "#f9f9f9",
    //     borderColor: "#d8d8d8",
    //     color: active ? "#fff !important" : "#65676a !important",

    // }
}));

function SidePanel(props) {

  const [activeBefore, setActiveBefore] = useState(false);
  const [activeAfter, setActiveAfter] = useState(false);
  const [beforeStatus, setBeforeStatus] = useState(true);
  const [afterStatus, setAfterStatus] = useState(true);

  const [energy, setEnergy] = useState(false);
  const [energyInfo, setEnergyInfo] = useState(false);
  const [energyStreetLayer, setEnergyStreetLayer] = useState(false);
  const [energySectLayer, setEnergySectLayer] = useState(false);
  
  const [potential, setPotential] = useState(false);
  const [potentialInfo, setPotentialInfo] = useState(false);
  const [potentialStreetLayer, setPotentialStreetLayer] = useState(false);
  const [potentialSectLayer, setPotentialSectLayer] = useState(false);

  const [investment, setInvestment] = useState(false);
  const [investmentInfo, setInvestmentInfo] = useState(false);
  const [investmentStreetLayer, setInvestmentStreetLayer] = useState(false);
  const [investmentSectLayer, setInvestmentSectLayer] = useState(false);

  const [searchDirection, setSearchDirection] = useState(false);
  const [searchDirectionInfo, setSearchDirectionInfo] = useState(false);
  const [searchDirectionStreetLayer, setSearchDirectionStreetLayer] =
    useState(false);

  const [socioDemographic, setSocioDemographic] = useState(false);
  const [socioDemographicInfo, setSocioDemographicInfo] = useState(false);

  const [income, setIncome] = useState(false);
  const [incomeSectLayer, setIncomeSectLayer] = useState(false);

  const [age, setAge] = useState(false);
  const [ageSectLayer, setAgeSectLayer] = useState(false);

  const [tenant, setTenant] = useState(false);
  const [tenantSectLayer, setTenantSectLayer] = useState(false);

  
  const [hidePanel, setHidePanel] = useState(false);
  const [downArrow, setDownArrow] = useState(false);

  const streetLayer = useSelector(
    (state) => state.map.selectedStreetLayer
  );
  const sectorLayer = useSelector(
    (state) => state.map.selectedSectorLayer
  );
  const mapData = useSelector(
    (state) => state.simulation.mapData
  );
  const simulationViewState = useSelector(
    (state) => state.simulation.clickState
  );
  const { municipalityName = null } = useSelector(state => state?.map?.selectedArea)

  const dispatch = useDispatch();

  useEffect(() => {
      if (simulationViewState === SIMULATION_CLICK_STATE.AFTER) {
        setActiveAfter(true);
        setActiveBefore(false);
      }
      else {
        setActiveBefore(true);
        setActiveAfter(false);
      }
  }, [simulationViewState]);

  useEffect(() => {
    if (
        props.map.selectedStreetLayer === MAP_LAYERS.CURRENT_HEAT_STATE_STREETS ||
        props.map.selectedSectorLayer === MAP_LAYERS.CURRENT_HEAT_STATE_SECTORS
      ) {
          setAfterStatus(false);
          setBeforeStatus(false);
    // } else if (
    //     props.map.selectedStreetLayer === MAP_LAYERS.INVESTMENT_STREETS ||
    //     props.map.selectedSectorLayer === MAP_LAYERS.INVESTMENT_SECTORS
    // ) {
    //       setAfterStatus(true);
    //       setBeforeStatus(true);
    // } else if (
    //     props.map.selectedStreetLayer === MAP_LAYERS.POTENTIAL_STREETS ||
    //     props.map.selectedSectorLayer === MAP_LAYERS.POTENTIAL_SECTORS
    // ) {
    //       setAfterStatus(true);
    //       setBeforeStatus(true);
    } else {
          setAfterStatus(true);
          setBeforeStatus(true);
    }
  }, [props.map.selectedStreetLayer, props.map.selectedSectorLayer]);

  useEffect(() => {
    setEnergyStreetLayer(
      props.map.selectedStreetLayer == MAP_LAYERS.CURRENT_HEAT_STATE_STREETS ? true : false
    );
    setEnergySectLayer(
      props.map.selectedSectorLayer == MAP_LAYERS.CURRENT_HEAT_STATE_SECTORS ? true : false
    );
    setPotentialStreetLayer(
      props.map.selectedStreetLayer == MAP_LAYERS.POTENTIAL_STREETS ? true : false
    );
    setPotentialSectLayer(
      props.map.selectedSectorLayer == MAP_LAYERS.POTENTIAL_SECTORS ? true : false
    );
    setInvestmentStreetLayer(
      props.map.selectedStreetLayer == MAP_LAYERS.INVESTMENT_STREETS ? true : false
    );
    setInvestmentSectLayer(
      props.map.selectedSectorLayer == MAP_LAYERS.INVESTMENT_SECTORS ? true : false
    );
    setSearchDirectionStreetLayer(
      props.map.selectedStreetLayer == MAP_LAYERS.DIRECTION_STREETS ? true : false
    );
    setIncomeSectLayer(
      props.map.selectedSectorLayer == MAP_LAYERS.INCOME_SECTORS ? true : false
    );
    setAgeSectLayer(
      props.map.selectedSectorLayer == MAP_LAYERS.AGE_SECTORS ? true : false
    );
    setTenantSectLayer(
      props.map.selectedSectorLayer == MAP_LAYERS.TENANT_SECTORS ? true : false
    );
  }, [props.map.selectedStreetLayer, props.map.selectedSectorLayer]);

  useEffect(() => {
    setEnergy(
      streetLayer == MAP_LAYERS.CURRENT_HEAT_STATE_STREETS || sectorLayer == MAP_LAYERS.CURRENT_HEAT_STATE_SECTORS ? true : false
    );
    setPotential(
      streetLayer == MAP_LAYERS.POTENTIAL_STREETS || sectorLayer == MAP_LAYERS.POTENTIAL_SECTORS ? true : false
    );
    setInvestment(
      streetLayer == MAP_LAYERS.INVESTMENT_STREETS || sectorLayer == MAP_LAYERS.INVESTMENT_SECTORS ? true : false
    );
    setSearchDirection(
      streetLayer == MAP_LAYERS.DIRECTION_STREETS ? true : false
    );
    setIncome(
      sectorLayer == MAP_LAYERS.INCOME_SECTORS ? true : false
    );
    setAge(
      sectorLayer == MAP_LAYERS.AGE_SECTORS ? true : false
    );
    setTenant(
      sectorLayer == MAP_LAYERS.TENANT_SECTORS ? true : false
    );
  }, [props.map.selectedStreetLayer, props.map.selectedSectorLayer]);

  const handleDownloadFile = React.useCallback(() => {
    const downloadURL = createDownloadPdfURL(municipalityName)
    window.open(downloadURL, "_blank")
    // window.open(downloadURL, "_self")
  }, [municipalityName])

  return (
    <>
      <Box
        container
        item
        xs={12}
        sm={10}
        md={8}
        lg={6}
        xl={3}
        className="sidepanel-container"
        justifyContent="flex-end"
        sx={{width: "115% !important"}}
      >
        <div
          className={
            !hidePanel && downArrow
              ? `sidepanel-close-icon sidepanel-with-down`
              : hidePanel && downArrow
              ? `sidepanel-hide-panel sidepanel-with-down`
              : !hidePanel && !downArrow
              ? `sidepanel-close-icon`
              : `sidepanel-hide-panel`
          }
        >
          <img
            className="ml-2 pointer light-grey"
            src={close}
            onClick={() => setHidePanel(!hidePanel)}
            alt="close"
            width="50px"
            height="50px"
          />
        </div>
        {!hidePanel ? (<>
          <div className="sidepanel-panel shadow mb-4">
            <div className="sidepanel-section">
              <div className="pt-4 pb-4" onClick={() => setEnergy(!energy)}>
                <Grid container direction="row">
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    alignContent="center"
                    item
                    xs={12}
                    sm={6}
                  >
                    <div
                      style={{
                        font: "Barlow",
                        // height: "17px"
                      }}
                      className="fs-14 pl-3 bold"
                      onClick={() => setEnergy(!energy)}
                    >
                      Huidige Energieprestatie
                    </div>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    alignContent="center"
                    justifyContent="flex-end"
                    item
                    xs={12}
                    sm={6}
                  >
                    <div
                      container
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center"
                      className="pr-3"
                      style={{
                        display: "flex",
                        font: "Barlow",
                        // height: "12px",
                      }}
                    >
                      <span className="fs-10 text-grey">Straatsegment</span>
                      <img
                        className={
                          streetLayer == MAP_LAYERS.CURRENT_HEAT_STATE_STREETS
                            ? "ml-1"
                            : "ml-1 pointer"
                        }
                        src={energyStreetLayer ? eye : hideEye}
                        onClick={() => {
                          if (sectorLayer === MAP_LAYERS.CURRENT_HEAT_STATE_SECTORS) {
                            setEnergySectLayer(!energySectLayer);
                            setEnergyStreetLayer(!energyStreetLayer);
                            props.setSectorLayers('');
                            props.setStreetLayers(
                              MAP_LAYERS.CURRENT_HEAT_STATE_STREETS
                            );
                          }
                          else if (streetLayer === MAP_LAYERS.CURRENT_HEAT_STATE_STREETS) {
                            setEnergyStreetLayer(!energyStreetLayer);
                            setEnergy(!energy);
                            props.setStreetLayers('');
                          }
                          else {
                            setEnergy(!energy);
                            props.setStreetLayers(
                              MAP_LAYERS.CURRENT_HEAT_STATE_STREETS
                            );
                          }
                        }
                        }
                        alt="eye"
                        width="18px"
                        height="18px"
                      />
                      <span className="ml-3 fs-10 text-grey">
                        Statistische sector
                      </span>
                      <img
                        className={
                          sectorLayer == MAP_LAYERS.CURRENT_HEAT_STATE_SECTORS
                            ? "ml-1"
                            : "ml-1 pointer"
                        }
                        src={energySectLayer ? eye : hideEye}
                        onClick={() => {
                          if (streetLayer === MAP_LAYERS.CURRENT_HEAT_STATE_STREETS) {
                            setEnergySectLayer(!energySectLayer);
                            setEnergyStreetLayer(!energyStreetLayer);
                            props.setStreetLayers('');
                            props.setSectorLayers(
                              MAP_LAYERS.CURRENT_HEAT_STATE_SECTORS
                            );
                          }
                          else if (sectorLayer === MAP_LAYERS.CURRENT_HEAT_STATE_SECTORS) {
                            setEnergySectLayer(!energySectLayer);
                            setEnergy(!energy);
                            props.setSectorLayers('');
                          }
                          else {
                            setEnergy(!energy);
                            props.setSectorLayers(
                              MAP_LAYERS.CURRENT_HEAT_STATE_SECTORS
                            );
                          }
                        }
                        }
                        alt="eye"
                        width="18px"
                        height="18px"
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>
              {energy ? (
                <div className="col-12 pt-2 pb-2">
                  <MultiColorBar
                    colors={[
                      {
                        hex: HEAT_STATES_COLORS.A,
                        percentage: 16.6,
                        label: "A",
                      },
                      {
                        hex: HEAT_STATES_COLORS.B,
                        percentage: 16.6,
                        label: "B",
                      },
                      {
                        hex: HEAT_STATES_COLORS.C,
                        percentage: 16.6,
                        label: "C",
                      },
                      {
                        hex: HEAT_STATES_COLORS.D,
                        percentage: 16.6,
                        label: "D",
                      },
                      {
                        hex: HEAT_STATES_COLORS.E,
                        percentage: 16.6,
                        label: "E",
                      },
                      {
                        hex: HEAT_STATES_COLORS.F,
                        percentage: 16.6,
                        label: "F",
                      },
                    ]}
                    labels={["A", "B", "C", "D", "E", "F"]}
                  />
                  {!["", null, undefined].includes(mapData) && props.simVizBtn ? (
                      <ButtonGroup sx={{
                        display: "flex",
                        justifyContent: "center",
                        pointerEvents: "auto"
                        }}>
                        {/* Before Button */}
                        <ButtonStyled 
                            disableRipple
                            disabled={beforeStatus}
                            active={activeBefore}
                            onClick={() => 
                              dispatch(onChangeSimulationVisualizationState(SIMULATION_CLICK_STATE.BEFORE))
                            }
                        >
                          Voor
                        </ButtonStyled>
                        {/* After button */}
                        <ButtonStyled 
                            disableRipple
                            disabled={afterStatus}
                            active={activeAfter}
                            sx={{ marginLeft: '0px !important' }}
                            onClick={() => 
                              dispatch(onChangeSimulationVisualizationState(SIMULATION_CLICK_STATE.AFTER))
                            }
                        >
                          Na
                        </ButtonStyled>
                    </ButtonGroup>
                    ) : null
                  }
                  <IconButton
                    sx={{
                      position: "relative",
                      padding: "0px",
                      left: "47%",
                      color: "gray",
                    }}
                  >
                    <ExpandMoreIcon
                      onClick={() => setEnergyInfo((energyInfo) => !energyInfo)}
                    />
                  </IconButton>
                  {energyInfo ? (
                    <>
                      <Divider style={{ background: "black" }} />
                      <div className="col-12 pt-3">
                        <div className="fs-11 light-grey">
                          <p>
                            Deze kaartlaag toont het gemiddelde energielabel aan per straatsegment of statistische sector.
                          </p>
                        </div>
                      </div>
                      <Divider className="" />
                    </>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
            <Divider style={{ background: "black" }} />
            <div className="sidepanel-section">
              <div className="pt-4 pb-4" onClick={() => setPotential(!potential)}>
                <Grid container direction="row">
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    item
                    xs={12}
                    sm={6}
                  >
                    <div
                      style={{
                        font: "Barlow",
                        height: "17px",
                      }}
                      className="fs-14 pl-3 bold"
                      onClick={() => setPotential(!potential)}
                    >
                      Potentieel
                    </div>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-end"
                    item
                    xs={12}
                    sm={6}
                  >
                    <div
                      container
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center"
                      className="pr-3"
                      style={{
                        display: "flex",
                        font: "Barlow",
                        height: "12px",
                      }}
                    >
                      <span className="fs-10 text-grey">Straatsegment</span>
                      <img
                        className={
                          streetLayer == MAP_LAYERS.POTENTIAL_STREETS
                            ? "ml-1"
                            : "ml-1 pointer"
                        }
                        src={potentialStreetLayer ? eye : hideEye}
                        onClick={() => {
                          if (sectorLayer === MAP_LAYERS.POTENTIAL_SECTORS) {
                            setPotentialSectLayer(!potentialSectLayer);
                            setPotentialStreetLayer(!potentialStreetLayer);
                            props.setSectorLayers('');
                            props.setStreetLayers(
                              MAP_LAYERS.POTENTIAL_STREETS
                            );
                          }
                          else if (streetLayer === MAP_LAYERS.POTENTIAL_STREETS) {
                            setPotentialStreetLayer(!potentialStreetLayer);
                            setPotential(!potential);
                            props.setStreetLayers('');
                          }
                          else {
                            setPotential(!potential);
                            props.setStreetLayers(
                              MAP_LAYERS.POTENTIAL_STREETS
                            );
                          }
                        }
                        }
                        alt="eye"
                        width="18px"
                        height="18px"
                      />
                      <span className="ml-3 fs-10 text-grey">
                        Statistische sector
                      </span>
                      <img
                        className={
                          sectorLayer == MAP_LAYERS.POTENTIAL_SECTORS
                            ? "ml-1"
                            : "ml-1 pointer"
                        }
                        src={potentialSectLayer ? eye : hideEye}
                        onClick={() => {
                          if (streetLayer === MAP_LAYERS.POTENTIAL_STREETS) {
                            setPotentialSectLayer(!potentialSectLayer);
                            setPotentialStreetLayer(!potentialStreetLayer);
                            props.setStreetLayers('');
                            props.setSectorLayers(
                              MAP_LAYERS.POTENTIAL_SECTORS
                            );
                          }
                          else if (sectorLayer === MAP_LAYERS.POTENTIAL_SECTORS) {
                            setPotentialSectLayer(!potentialSectLayer);
                            setPotential(!potential);
                            props.setSectorLayers('');
                          }
                          else {
                            setPotential(!potential);
                            props.setSectorLayers(
                              MAP_LAYERS.POTENTIAL_SECTORS
                            );
                          }
                        }
                        }
                        alt="eye"
                        width="18px"
                        height="18px"
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>
              {potential ? (
                <div>
                  <span className="pl-3"
                    style={{
                      color: "gray",
                      fontSize: "12px",
                      font: "BarlowMedium"
                    }}
                  >
                    <span>TCO/CO<sub>2</sub> besparingen</span>
                  </span>
                  <div className="col-12 pt-2 pb-2">
                    <GradientBar
                      leftColor={POTENTIAL_COLORS.left}
                      rightColor={POTENTIAL_COLORS.right}
                      radius={"10"}
                    />
                    <IconButton
                      onClick={() => setPotentialInfo(!potentialInfo)}
                      style={{
                        position: "relative",
                        left: "47%",
                        color: "gray",
                        padding: "0px",
                      }}
                    >
                      <ExpandMoreIcon />
                    </IconButton>
                    {potentialInfo ? (
                      <>
                        <Divider className="" />
                        <div className="col-12 pt-3">
                          <div className="fs-11 light-grey">
                            <p>
                              Deze kaartlaag maakt het potentieel inzichtelijk.
                              Aan de basis ervan ligt de verhouding de total cost of ownership (TCO) en de bereikte CO₂-besparing voor die TCO.
                              De kleurschaal geeft een indicatie van hoe groot de relatieve CO₂-reductie is in verhouding tot de TCO.
                              Een lichte kleur is interessanter: hier is de CO₂-reductie goedkoper.
                            </p>
                          </div>
                        </div>
                        <Divider className="" />
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            <Divider style={{ background: "black" }} />
            <div className="sidepanel-section">
              <div className="pt-4 pb-4" onClick={() => setInvestment(!investment)}>
                <Grid container direction="row">
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    item
                    xs={12}
                    sm={6}
                  >
                    <div
                      style={{
                        font: "Barlow",
                        height: "17px",
                      }}
                      className="fs-14 pl-3 bold"
                      onClick={() => setInvestment(!investment)}
                    >
                      Investering
                    </div>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-end"
                    item
                    xs={12}
                    sm={6}
                  >
                    <div
                      container
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center"
                      className="pr-3"
                      style={{
                        display: "flex",
                        font: "Barlow",
                        height: "12px",
                      }}
                    >
                      <span className="fs-10 text-grey">Straatsegment</span>
                      <img
                        className={
                          streetLayer == MAP_LAYERS.INVESTMENT_STREETS
                            ? "ml-1"
                            : "ml-1 pointer"
                        }
                        src={investmentStreetLayer ? eye : hideEye}
                        onClick={() => {
                          if (sectorLayer === MAP_LAYERS.INVESTMENT_SECTORS) {
                            setInvestmentSectLayer(!investmentSectLayer);
                            setInvestmentStreetLayer(!investmentStreetLayer);
                            props.setSectorLayers('');
                            props.setStreetLayers(
                              MAP_LAYERS.INVESTMENT_STREETS
                            );
                          }
                          else if (streetLayer === MAP_LAYERS.INVESTMENT_STREETS) {
                            setInvestmentStreetLayer(!investmentStreetLayer);
                            setInvestment(!investment);
                            props.setStreetLayers('');
                          }
                          else {
                            setInvestment(!investment);
                            props.setStreetLayers(
                              MAP_LAYERS.INVESTMENT_STREETS
                            );
                          }
                        }
                        }
                        alt="eye"
                        width="18px"
                        height="18px"
                      />
                      <span className="ml-3 fs-10 text-grey">
                        Statistische sector
                      </span>
                      <img
                        className={
                          sectorLayer == MAP_LAYERS.INVESTMENT_SECTORS
                            ? "ml-1"
                            : "ml-1 pointer"
                        }
                        src={investmentSectLayer ? eye : hideEye}
                        onClick={() => {
                          if (streetLayer === MAP_LAYERS.INVESTMENT_STREETS) {
                            setInvestmentSectLayer(!investmentSectLayer);
                            setInvestmentStreetLayer(!investmentStreetLayer);
                            props.setStreetLayers('');
                            props.setSectorLayers(
                              MAP_LAYERS.INVESTMENT_SECTORS
                            );
                          }
                          else if (sectorLayer === MAP_LAYERS.INVESTMENT_SECTORS) {
                            setInvestmentSectLayer(!investmentSectLayer);
                            setInvestment(!investment);
                            props.setSectorLayers('');
                          }
                          else {
                            setInvestment(!investment);
                            props.setSectorLayers(
                              MAP_LAYERS.INVESTMENT_SECTORS
                            );
                          }
                        }
                        }
                        alt="eye"
                        width="18px"
                        height="18px"
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>
              {investment ? (
                <div className="col-12 pt-2 pb-2">
                  <TriColorBar
                    colors={[
                      {
                        hex: INVESTMENT_COLORS.A,
                        percentage: 33.3,
                      },
                      {
                        hex: INVESTMENT_COLORS.B,
                        percentage: 33.3,
                      },
                      {
                        hex: INVESTMENT_COLORS.C,
                        percentage: 33.3,
                      },
                    ]}
                    labels={[">50.000€", "<50.000€", "+100.000€"]}
                  />
                  <IconButton
                    onClick={() => setInvestmentInfo(!investmentInfo)}
                    style={{
                      position: "relative",
                      padding: "0px",
                      left: "45%",
                      color: "gray",
                    }}
                  >
                    <ExpandMoreIcon />
                  </IconButton>
                  {investmentInfo ? (
                    <>
                      <Divider className="" />
                      <div className="col-12 pt-3">
                        <div className="fs-11 light-grey">
                          <p>
                            Deze kaartlaag geeft de gemiddelde investering weer per woning, om deze tot een A-label te renoveren.
                          </p>
                        </div>
                      </div>
                      <Divider className="" />
                    </>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
            <Divider style={{ background: "black" }} />
            <div className="sidepanel-section">
              <div className="pt-4 pb-4" onClick={() => setSearchDirection(!searchDirection)}>
                <Grid container direction="row">
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    item
                    xs={12}
                    sm={7}
                  >
                    <div
                      style={{
                        font: "Barlow",
                      }}
                      className="fs-14 pl-3 bold"
                      onClick={() => setSearchDirection(!searchDirection)}
                    >
                      Zoekrichting duurzame warmteoplossing
                    </div>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-start"
                    item
                    xs={12}
                    sm={5}
                  >
                    <div
                      container
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center"

                      style={{
                        display: "flex",
                        font: "Barlow",
                        height: "12px",
                        paddingLeft: "10px"
                      }}
                    >
                      <span className="fs-10 text-grey">Straatsegment</span>
                      <img
                        className={
                          streetLayer == MAP_LAYERS.DIRECTION_STREETS
                            ? "ml-1"
                            : "ml-1 pointer"
                        }
                        src={searchDirectionStreetLayer ? eye : hideEye}
                        onClick={() => {
                          if (streetLayer === MAP_LAYERS.DIRECTION_STREETS) {
                            setSearchDirectionStreetLayer(!searchDirectionStreetLayer);
                            setSearchDirection(!searchDirection);
                            props.setStreetLayers('');
                          }
                          else {
                            setSearchDirection(!searchDirection);
                            props.setStreetLayers(
                              MAP_LAYERS.DIRECTION_STREETS
                            );
                          }
                        }
                        }
                        alt="eye"
                        width="18px"
                        height="18px"
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>
              {searchDirection ? (
                <div className="col-12 pt-2 pb-2">
                  <MultiStepColorBar
                    colors={[
                      {
                        hex: SEARCH_DIRECTION_COLORS.B,
                        percentage: 20,
                      },
                      {
                        hex: SEARCH_DIRECTION_COLORS.C,
                        percentage: 20,
                      },
                      {
                        hex: SEARCH_DIRECTION_COLORS.D,
                        percentage: 20,
                      },
                      {
                        hex: SEARCH_DIRECTION_COLORS.E,
                        percentage: 20,
                      },
                      {
                        hex: SEARCH_DIRECTION_COLORS.F,
                        percentage: 20,
                      },
                    ]}
                    labels={["Collectief", "Onbeslist", "Individueel"]}
                  />
                  <IconButton
                    sx={{
                      position: "relative",
                      padding: "0px",
                      left: "47%",
                      color: "gray",
                    }}
                  >
                    <ExpandMoreIcon
                      onClick={() => setSearchDirectionInfo((searchDirectionInfo) => !searchDirectionInfo)}
                    />
                  </IconButton>
                  {searchDirectionInfo ? (
                    <>
                      <Divider className="" />
                      <div className="col-12 pt-3">
                        <div className="fs-11 light-grey">
                          <p>
                            Deze kaartlaag toont de aanduiding van de zoekzones zoals bekomen uit het beslissings- en clusteringsalgoritme. De labels worden toegekend aan de hand van de vergelijking tussen de kosten voor individuele en collectieve warmteoplossingen. Die kosten zijn berekend vanuit de warmtevraag en kunnen daardoor als volgt worden geïnterpreteerd:<br/><br/>
                          - ‘Collectief’ : zone van straatsegmenten waarvoor de ingeschatte kosten voor een collectieve warmteoplossing significant lager liggen dan die voor de goedkoopste individuele oplossingen.<br/><br/>
                          - ‘Eerder collectief’: zone van straatsegmenten waarvoor de ingeschatte kosten voor een collectieve warmteoplossing gelijk of lager liggen dan die voor de goedkoopste individuele oplossingen.<br/><br/>
                          - ‘Onzeker’: zone van straatsegmenten waarvoor de ingeschatte kosten voor een collectieve warmteoplossing gelijkaardig zijn als die voor de individuele oplossingen.<br/><br/>
                          - ‘Eerder individueel’: zone van straatsegmenten waarvoor de ingeschatte kosten voor een collectieve warmteoplossing gelijkaardig of hoger zijn als die voor de individuele oplossingen.<br/><br/>
                          - ‘Individueel’: zone van straatsegmenten waarvoor de ingeschatte kosten voor een collectieve warmteoplossing significant hoger zijn als die voor de duurdere individuele oplossingen
                          </p>
                        </div>
                      </div>
                      <Divider className="" />
                    </>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
            <Divider style={{ background: "black" }} />
            <div className="sidepanel-section">
              <div className="pt-4 pb-4" onClick={() => setSocioDemographic(!socioDemographic)}>
                <Grid container direction="row">
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    item
                    xs={12}
                    sm={6}
                  >
                    <div
                      style={{
                        font: "Barlow",
                        height: "17px",
                      }}
                      className="fs-14 pl-3 bold"
                    >
                      Socio-economische factoren
                    </div>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    item
                    xs={6}
                    sm={6}
                  >
                    <div
                      className="pr-3"
                      style={{
                        font: "Barlow",
                        height: "18px",
                      }}
                    >
                      <IconButton
                        sx={{
                          padding: "0px",
                          color: "gray",
                        }}
                      >
                        <ExpandMoreIcon
                          onClick={() => setSocioDemographic(!socioDemographic)}
                        />
                      </IconButton>
                    </div>
                  </Grid>
                </Grid>
              </div>
              <Divider />
              {socioDemographic ? (
                <>
                  <div className="pt-2 pb-2">
                    <Grid container direction="row">
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        item
                        xs={12}
                        sm={8}
                      >
                        <div
                          style={{
                            font: "Barlow",
                            height: "17px",
                          }}
                          className="fs-14 pl-3 bold"
                        >
                          Inkomen
                        </div>
                      </Grid>
                      <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        item
                        xs={12}
                        sm={4}
                      >
                        <div
                          className="pr-3"
                          container
                          direction="row"
                          justifyContent="flex-end"
                          alignItems="center"
                          style={{
                            display: "flex",
                            font: "Barlow",
                            height: "12px",
                          }}
                        >
                          <span className="ml-1 fs-10 text-grey">
                            Statistische sector
                          </span>
                          <img
                            className={
                              sectorLayer ==
                              MAP_LAYERS.INCOME_SECTORS
                                ? "ml-1"
                                : "ml-1 pointer"
                            }
                            src={incomeSectLayer ? eye : hideEye}
                            onClick={() => {
                              if (sectorLayer === MAP_LAYERS.INCOME_SECTORS) {
                                setIncomeSectLayer(!incomeSectLayer);
                                setIncome(!income);
                                props.setSectorLayers('');
                              }
                              else {
                                setIncome(!income);
                                props.setSectorLayers(
                                  MAP_LAYERS.INCOME_SECTORS
                                );
                              }
                            }
                          }
                            alt="eye"
                            width="18px"
                            height="18px"
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                  <div className="col-12 pt-2 pb-2">
                    <GradientBar
                      leftColor={INCOME_GRADIENT_COLORS.left}
                      rightColor={INCOME_GRADIENT_COLORS.right}
                      radius={"10"}
                      labels={['10000€', '50000€']}
                    />
                  </div>
                  <Divider style={{ background: "black" }} />
                  <div className="pt-2 pb-2">
                    <Grid container direction="row">
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        item
                        xs={12}
                        sm={8}
                      >
                        <div
                          style={{
                            font: "Barlow",
                            height: "17px",
                          }}
                          className="fs-14 pl-3 bold"
                        >
                          Leeftijdsverdeling
                        </div>
                      </Grid>
                      <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        item
                        xs={12}
                        sm={4}
                      >
                        <div
                          className="pr-3"
                          container
                          direction="row"
                          justifyContent="flex-end"
                          alignItems="center"
                          style={{
                            display: "flex",
                            font: "Barlow",
                            height: "12px",
                          }}
                        >
                          <span className="ml-1 fs-10 text-grey">
                            Statistische sector
                          </span>
                          <img
                            className={
                              sectorLayer ==
                              MAP_LAYERS.AGE_SECTORS
                                ? "ml-1"
                                : "ml-1 pointer"
                            }
                            src={ageSectLayer ? eye : hideEye}
                            onClick={() => {
                              if (sectorLayer === MAP_LAYERS.AGE_SECTORS) {
                                setAgeSectLayer(!ageSectLayer);
                                setAge(!age);
                                props.setSectorLayers('');
                              }
                              else {
                                setAge(!age);
                                props.setSectorLayers(
                                  MAP_LAYERS.AGE_SECTORS
                                );
                              }
                            }
                            }
                            alt="eye"
                            width="18px"
                            height="18px"
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                  <div className="col-12 pt-2 pb-2">
                    <GradientBar
                      leftColor={AGE_GRADIENT_COLORS.left}
                      rightColor={AGE_GRADIENT_COLORS.right}
                      radius={"10"}
                      labels={[30, 60]}
                    />
                  </div>
                  <Divider style={{ background: "black" }} />
                  <div className="pt-2 pb-2">
                    <Grid container direction="row">
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        item
                        xs={12}
                        sm={8}
                      >
                        <div
                          style={{
                            font: "Barlow",
                            height: "17px",
                          }}
                          className="fs-14 pl-3 bold"
                        >
                          Huurder/Eigenaar
                        </div>
                      </Grid>
                      <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        item
                        xs={12}
                        sm={4}
                      >
                        <div
                          className="pr-3"
                          container
                          direction="row"
                          justifyContent="flex-end"
                          alignItems="center"
                          style={{
                            display: "flex",
                            font: "Barlow",
                            height: "12px",
                          }}
                        >
                          <span className="ml-1 fs-10 text-grey">
                            Statistische sector
                          </span>
                          <img
                            className={
                              sectorLayer ==
                              MAP_LAYERS.TENANT_SECTORS
                                ? "ml-1"
                                : "ml-1 pointer"
                            }
                            src={tenantSectLayer ? eye : hideEye}
                            onClick={() => {
                              if (sectorLayer === MAP_LAYERS.TENANT_SECTORS) {
                                setTenantSectLayer(!tenantSectLayer);
                                setTenant(!tenant);
                                props.setSectorLayers('');
                              }
                              else {
                                setTenant(!tenant);
                                props.setSectorLayers(
                                  MAP_LAYERS.TENANT_SECTORS
                                );
                              }
                            }
                            }
                            alt="eye"
                            width="18px"
                            height="18px"
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                  <div className="col-12 pt-2 pb-2">
                    <StepGradientBar
                      colors={[
                      {
                        hex: TENANT_COLORS.A,
                      },
                      {
                        hex: TENANT_COLORS.B,
                      },
                      {
                        hex: TENANT_COLORS.C,
                      },
                      {
                        hex: TENANT_COLORS.D,
                      },
                      {
                        hex: TENANT_COLORS.E,
                      },
                    ]}
                      radius={"10"}
                    />
                    <IconButton
                      sx={{
                        position: "relative",
                        padding: "0px",
                        left: "47%",
                        color: "gray",
                      }}
                    >
                      <ExpandMoreIcon
                        onClick={() => setSocioDemographicInfo((socioDemographicInfo) => !socioDemographicInfo)}
                      />
                    </IconButton>
                    {socioDemographicInfo ? (
                      <>
                        <Divider />
                        <div className="col-12 pt-3">
                          <div className="fs-11 light-grey">
                            <p>
                              Deze kaartlagen laten toe de techno-economische overwegingen te confronteren met verschillende socio-economische aspecten van de inwoners.
                              Krijg een inzicht in het gemiddelde inkomen, de gemiddelde leeftijd en de verhouding huurders ten opzichte van eigenaars per statistische sector.
                            </p>
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
            <Divider style={{ background: "black" }} />
          </div>
          {municipalityName && !pdfNotAvaialbeMunicipilities.includes(municipalityName) && (
            <button className="sidepanel-download-button" onClick={handleDownloadFile}>Download</button>
          )}
          </>
        ) : (
          ""
        )}
      </Box>
    </>
  );
}
const mapStateToProps = ({ auth, content, map, loader }) => ({
  auth,
  content,
  map,
  loader,
});
export default connect(mapStateToProps, {
  setStreetLayers: setSelectedStreetLayer,
  setSectorLayers: setSelectedSectorLayer,
  activeView,
})(SidePanel);
