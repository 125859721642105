import React, {useState, useEffect} from 'react'

import FullWidthTabs from '../tabpanel/tabPanel';
import MapInfoCard from './mapLegend';

const GraphsAndInfoCard = (props) => {
    const [bannerContent, setBannerContent] = useState(undefined)

    useEffect(async() => {
        let { sidePanel, ...cardData } = await props.getContentByModuleLocation("analysisPage");
        setBannerContent(cardData);
    }, []);

    return (
        !props.infoCardOpen ? (
            <div className="graph-container">
                <FullWidthTabs key="graphs" />
            </div>
        ) : (
            <div className="content-below">
                <div className="map-page-content">
                    <MapInfoCard
                        bannerContent={bannerContent}
                        className="info-card-map"
                        width={100}
                        height={50}
                        onCloseClicked={() => props.setInfoCardOpen(false)}
                    />
                </div>
            </div>
        )
    )
}

export default GraphsAndInfoCard