import React from 'react'
import * as d3 from "d3"

import { useD3 } from '../../../utils/hooks'
import "./chart.css"
import { Box, Stack, Typography } from '@mui/material'
import { useSelector } from 'react-redux'

const dataSkelton = {
    muniName: "Wijnegem",
    dataGraph: [
        {
            proviencePercentage: 0,
            muniPercentage: 0,
            epcScore: 50,
        },
        {
            proviencePercentage: 0.25,
            muniPercentage: 0.2,
            epcScore: 100,
        },
        {
            proviencePercentage: 0.7,
            muniPercentage: 0.6,
            epcScore: 150,
        },
        {
            proviencePercentage: 0.6,
            muniPercentage: 0.5,
            epcScore: 200,
        },
        {
            proviencePercentage: 0.5,
            muniPercentage: 0.6,
            epcScore: 250,
        },
        {
            proviencePercentage: 0.7,
            muniPercentage: 0.65,
            epcScore: 300,
        },
        {
            proviencePercentage: 0.66,
            muniPercentage: 0.6,
            epcScore: 350,
        },
        {
            proviencePercentage: 0.7,
            muniPercentage: 0.6,
            epcScore: 450,
        },
        {
            proviencePercentage: 0.75,
            muniPercentage: 0.55,
            epcScore: 500,
        },
        {
            proviencePercentage: 0.7,
            muniPercentage: 0.6,
            epcScore: 550,
        },
        {
            proviencePercentage: 0.8,
            muniPercentage: 0.3,
            epcScore: 600,
        },
        {
            proviencePercentage: 0.5,
            muniPercentage: 0.4,
            epcScore: 650,
        },
    ],
}

export function DoubleBarChart({ chartData = {}, height = 230, sectorName = false }) {
    const { municipalityName = null } = useSelector(state => state?.map?.selectedArea)
    const { areaName: statisticalSectorArea } = useSelector(state => state?.statisticalSector)
    let label = municipalityName
    if (sectorName !== null && sectorName === true) {
        label = statisticalSectorArea
    }

    var tooltip = React.useMemo(() => ({
        mouseover: function (_, d,) {
            const muni = (100 * d.muniPercentage).toFixed(2) || d.muniPercentage
            const provience = (100 * d.proviencePercentage).toFixed(2) || d.proviencePercentage
            const html = `
                <table>
                    <tbody>
                        <tr id='muni'>
                            <td>${label}<td>
                            <td>${muni}%<td>
                        </tr>
                        <tr id='provience'>
                            <td>Vlaanderen<td>
                            <td>${provience}%<td>
                        </tr>
                    </tbody>
                </table>
            `

            this.el.html(html).transition()
                .duration(100)
                // .style("opacity", 0)
                .style("opacity", 1)
                .style("z-index", 9999)
        },
        mousemove: function () {
            var pageX = event.pageX || event.clientX
                , pageY = event.pageY || event.clientY
            return this.el.style("top", (event.layerY) + "px").style("left", (event.layerX - 200) + "px");
            // return this.el.style("top", (event.offsetY) + "px").style("left", (pageX) + "px");
            // if (pageX < (width / 2) + 100) {
            //     return this.el.style("top", (pageY - 950) + "px").style("left", (pageX - 25) + "px");
            // } else {
            // }
        },
        mouseout: function (d) {
            this.el.transition()
                .duration(200)
                .style("opacity", 0);
        }
    }), [municipalityName, statisticalSectorArea])

    const { ref, setData } = useD3(function () {
        const margin = { top: 5, right: 30, bottom: 5, left: 35 }
        const width = 350
        // const width = document.documentElement.clientWidth * 0.24
        // const height = document.documentElement.clientHeight * 0.20

        function chart(selection) {
            if (selection) {

                selection?.each(function (completeData) {
                    let { muniName, dataGraph: data = [] } = completeData
                    // adding additional attribute -> to start x-axis from 0
                    data = [{ epcScore: 0 }, ...data]
                    data = data.sort((a, b) => parseFloat(a.epcScore) - parseFloat(b.epcScore));
                    const x = d3.scaleBand().rangeRound([0, width]).padding(0.1)
                    const y = d3.scaleLinear().range([height, 0,])

                    x.domain(data.map((d) => d.epcScore))
                    y.domain([0, d3.max(data, (d) => Math. max(d.proviencePercentage, d.muniPercentage))])

                    const xAxis = d3.axisBottom(x).tickValues(x.domain().filter((d, i) => !(i % 2)))
                    const yAxis = d3.axisLeft(y).ticks(5, "%")

                    // initialize tooltip
                    tooltip.el = d3.select(this).append("div")
                        .attr("class", "tooltip")
                        .style("opacity", 0)

                    // Create the svg element
                    const svg = d3.select(this).append("svg")
                    svg.attr("class", "double-chart")

                    const g = svg.append("g")
                        .attr("transform", `translate(${margin.left}, ${margin.top})`)
                        .attr("width", "inherit")

                    g.append("g")
                        .attr("class", 'axis x_axis')
                        .attr("transform", `translate(-0, ${height})`)
                        .call(xAxis)

                    g.append("g")
                        .attr("class", 'axis y_axis')
                        .call(yAxis)
                        .append("text")
                        .attr("transform", "rotate(-90)")
                        .attr("y", 6)
                        .attr("x", 0)
                        .attr("text-anchor", "end")
                        .text("Percentage");

                    g.selectAll(".bar")
                        .data(data)
                        .enter()
                        .append("rect")
                        .attr("class", "bar2")
                        .attr("x", (d) => x(d.epcScore))
                        .attr("y", (d) => y(d.proviencePercentage) - 2)
                        .attr("width", x.bandwidth())
                        .attr("height", (d) => (height - y(d.proviencePercentage)))
                        .attr("transform", "translate(-8, 0)")

                    g.selectAll(".bar")
                        .data(data)
                        .enter()
                        .append("rect")
                        .attr("class", "bar1")
                        .attr("x", (d) => x(d.epcScore) + 0)
                        .attr("y", (d) => y(d.muniPercentage) - 2)
                        .attr("width", x.bandwidth() - 0)
                        .attr("height", (d) => (height - y(d.muniPercentage)))
                        .attr("transform", "translate(-8, 0)")

                    // add event handlers
                    g.selectAll("rect")
                        .on("mouseover", function (d, a, b) { return tooltip.mouseover(d, a, b) })
                        .on("mousemove", function () { return tooltip.mousemove() })
                        .on("mouseout", function (d) { return tooltip.mouseout(d) })

                })
            }
        }
        chart.margin = function (_) {
            if (!arguments.length) return margin;
            margin = _;
            return chart;
        };

        chart.width = function (_) {
            if (!arguments.length) return width;
            width = _;
            return chart;
        };

        chart.height = function (_) {
            if (!arguments.length) return height;
            height = _;
            return chart;
        };
        chart.tooltip = function (_) {
            if (!arguments.length) return tooltip;
            tooltip = _;
            return chart;
        }
        return chart
    })

    React.useEffect(() => {
        setData(chartData)
    }, [Object.entries(chartData || {})?.length])


    return (
        // <Box height="100%">
        <Stack direction="column" justifyContent="flex-start" height="100%" width="25rem">
            <div
                ref={ref}
                style={{
                    height: "100%",
                    width: "100%",
                    margin: 0,
                }}
            ></div>
            <Box sx={{ marginX: "auto", marginBottom: "15px" }}>
                <Stack direction="column" alignItems="center">
                    <Typography fontSize="12px" color="#000000DE">EPC score [kWh/m²]</Typography>
                    <Stack direction="row" gap={2}>
                        <Stack direction="row" alignItems="center" gap={1}>
                            <Box sx={{ backgroundColor: "#66B654", height: 3, width: 15 }} />
                            <Typography fontSize="12px" color="#000000DE">Vlaanderen</Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" gap={1}>
                            <Box sx={{ backgroundColor: "#4b853e", height: 3, width: 15 }} />
                            <Typography fontSize="12px" color="#000000DE">{label}</Typography>
                        </Stack>
                    </Stack>
                </Stack>
            </Box>
        </Stack>
        // </Box>
    )
}
