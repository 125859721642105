import { Box, IconButton, Stack, styled } from "@mui/material"
import React from "react"

const IconButtonStyled = styled(IconButton)({
    boxShadow: "1px 1px 3px 0px #6662628a",
    width: "25px",
    height: "25px",
    padding: 0,
    margin: 0,
    borderRadius: 0,
    textAlign: "center",

    ":disabled": {
        background: "gray",
        cursor: "not-allowed",
    },
})

const VerticalRibbon = ({ items }) => {

    return (
        <Box marginTop={"22px"}>
            <Stack direction="column" gap={0.5}>
                {items?.map((item, idx) => (
                    <IconButtonStyled
                        onClick={item.onClick}
                        disabled={item?.disable || false}
                        key={idx} id={item?.target}
                        sx={{ background: item?.background, ":hover": { background: item?.background } }}
                    >
                        {item.render}
                    </IconButtonStyled>
                ))}
            </Stack>
        </Box>
    )

}

export default VerticalRibbon