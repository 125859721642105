import React, { useEffect, useState, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Grid } from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Divider from "@material-ui/core/Divider";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import { ROUTES } from "../../constants/routes";
import user from "../../assets/used/LoggedUser.svg";
import hamberger from "../../assets/used/hamberger.png";
import { activeView, refreshToken, userLogout } from "../../redux/actions/auth";
import { getContentByModuleLocation } from "../../redux/actions/content";
import {MAP_LAYERS} from "../../constants/map";
import {setSelectedStreetLayer} from "../../redux/actions/map";
import {onChangeSimulationVisualizationState} from "../../redux/actions/advancedMap";
import {SIMULATION_CLICK_STATE} from "../../constants/simulation";
// import json2mq from 'json2mq';

function Header(props) {
  const location = useLocation();
  const history = useHistory();
  const highlight = location.pathname.includes("map");
  let checkAuth = document.cookie.indexOf("access_token") == -1 ? false : true;
  const dispatch = useDispatch()
  let cookieValue = document.cookie.replace(
    /(?:(?:^|.*;\s*)refresh_token\s*\=\s*([^;]*).*$)|^.*$/,
    "$1"
  );

  const smallScreen = useMediaQuery("(max-width: 850px)");

  // const smallScreen = useMediaQuery(
  //   json2mq({
  //     maxWidth: '800px',
  //     maxHeight: '500px',
  //   }),
  // );

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const mounted = useRef();
  let path = location.pathname;
  let currentTime = new Date();
  let expireTime = localStorage.getItem("expireTime");
  let refresh =
    localStorage.getItem("refresh") != "null" &&
    localStorage.getItem("refresh") === "true";

  const [active, setActive] = useState(false);
  const [header, setHeader] = useState(undefined);

  const userView = (view) => {
    setActive(true);
    if (
      props.auth.active_view == 1 &&
      view == 0 &&
      props.map.selectedStreetLayer !== MAP_LAYERS.POTENTIAL_STREETS
    ) {
      // props.setSelectedStreetLayer(MAP_LAYERS.CURRENT_HEAT_STATE_STREETS);
      props.activeView(view);
    }
    if (props.auth.active_view != view) props.activeView(view);
    if (checkAuth && view == 1)
      history.push(ROUTES.MAP_PAGE_SIMULATION);
    else {
      history.push(ROUTES.MAP_PAGE);
    }
  };

  const logoutUser = () => {
    // localStorage.clear();
    localStorage.removeItem("expireTime")
    props.userLogout();

    document.cookie.split(";").forEach((c) => {
      document.cookie = c
        .replace(/^ +/, "")
        .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });
    history.push(ROUTES.LOGIN_PAGE);
    window.location.reload(true);
  };

  const clickLogin = () => {
    if (location.pathname == ROUTES.LOGIN_PAGE) console.log("");
    else history.push(ROUTES.LOGIN_PAGE);
  };

  const homepage = () => {
    props.activeView(2);
    history.push(ROUTES.HOME_PAGE);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const redirectToMap = () => {
    if (window.location.pathname === ROUTES.MAP_PAGE) {
      if (localStorage.getItem("userEmail")) {
        props.activeView(1);
      } else {
        props.activeView(0);
      }
    }
    if (window.location.pathname === "/") {
      props.activeView(2);
    }
  };


  if (refresh && currentTime.getTime() + 60000 > expireTime) {
    localStorage.setItem("refresh", false);
    props.refreshToken(cookieValue);
    logoutUser();
  }

  useEffect(() => {
    let view = localStorage.getItem("userView");
    if (view && view != "null") props.activeView(view);

    //Load data in local state
    async function fetchHeader() {
      const headerContent = await props.getContentByModuleLocation("top");
      setHeader({ ...headerContent?.header?.attributes });
    }

    fetchHeader();
  }, []);

  useEffect(() => {
    if (!mounted.current) {
      // do componentDidMount logic
      mounted.current = true;
    } else {
      window.onpopstate = (e) => {
        redirectToMap();
      };
    }
    // redirectToMap();
  }, [props.auth.active_view]);

  return (
    <>
      <Grid container className="header-main pt-2 pb-2">
        <Grid
          container
          item
          xs={10}
          sm={6}
          className={smallScreen ? "pl-1" : "pl-3"}
          justifyContent="flex-start"
          alignItems="center"
        >
          <div
            className={`d-inline-flex ${
                smallScreen ? "header-left-small-screens" : "header-left"
            }`}
            onClick={() => homepage()}
          >
            <span>{header?.headerLeft}</span>&nbsp;<span style={{ color: '#43b02a' }}>{header?.headerLeft2}</span>
          </div>
        </Grid>
        <Grid
          container
          item
          xs={2}
          sm={6}
          className={smallScreen ? "pr-1 header-small-screens" : "pr-3"}
          justifyContent="flex-end"
          alignItems="center"
        >
          <div>
            {!smallScreen && (
              <span
                className={`pr-4 pointer header-second ${
                  props.auth.active_view == 0 && highlight ? "highlight" : ""
                }`}
                onClick={() => userView(0)}
              >
                {header?.headerRightAnalysis}
              </span>
            )}
            <>
              {!smallScreen && checkAuth && (
                <span
                  className={`header-second pointer mr-4 ${
                    props.auth.active_view == 1 && highlight ? "highlight" : ""
                  }`}
                  onClick={() => userView(1)}
                >
                  {header?.headerRightSimulatie}
                </span>
              )}
              <Tooltip title="Account settings">
                <IconButton
                  onClick={handleClick}
                  className="float-right"
                  size="small"
                  sx={{ verticalAlign:"middle", ml: 2 }}
                >
                  {smallScreen && !checkAuth ? (
                    <img src={hamberger} alt="eye" width="25px" height="25px" />
                  ) : (
                    checkAuth && (
                      <img src={user} alt="eye" style={{paddingTop : smallScreen ? "0px" : "10px", width : smallScreen ? "20px" : "25px", height : smallScreen ? "20px" : "25px"}} />
                    )
                  )}
                </IconButton>
              </Tooltip>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 3.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 60,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                {smallScreen && !checkAuth && (
                  <MenuItem onClick={clickLogin}>
                    {header?.headerRightLogin}
                  </MenuItem>
                )}
                {smallScreen && (
                  <div>
                    <MenuItem onClick={() => userView(0)}>
                      {header?.headerLeft}
                    </MenuItem>
                    {checkAuth && (
                      <MenuItem onClick={() => userView(1)}>
                        {header?.headerRightSimulatie}
                      </MenuItem>
                    )}
                  </div>
                )}
                {checkAuth && (
                  <div>
                    <MenuItem
                      onClick={() => history.push(ROUTES.SIMULATION_OVERVIEW)}
                    >
                      Simulatieoverzicht
                    </MenuItem>
                    <MenuItem onClick={() => history.push(ROUTES.CHANGE_PASS)}>
                      Wachtwoord wijzigen
                    </MenuItem>
                    <MenuItem onClick={logoutUser}>Uitloggen</MenuItem>
                  </div>
                )}
                <Divider />
              </Menu>
            </>
            {!checkAuth && !smallScreen && (
              <span
                className="header-login"
                onClick={clickLogin}
                style={{
                  background:
                    props.auth.active_view === 3
                      ? "var(--green)"
                      : "var(--white)",
                  color:
                    props.auth.active_view === 3
                      ? "var(--white)"
                      : "var(--green)",
                }}
              >
                {header?.headerRightLogin}
              </span>
            )}
          </div>
        </Grid>
      </Grid>
    </>
  );
}

const mapStateToProps = ({ auth, content, loader, map }) => ({
  auth,
  content,
  loader,
  map,
});
export default connect(mapStateToProps, {
  refreshToken,
  activeView,
  userLogout,
  setSelectedStreetLayer,
  getContentByModuleLocation,
})(Header);
