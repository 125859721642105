// the dumy data to show a empty graph on initial rendering
export const constructionPeriosDataSkelton = {
    "open": [
        {
            "label": "Voor 1950",
            "value": null
        },
        {
            "label": "1950 - 1970",
            "value": null
        },
        {
            "label": "1971 - 1990",
            "value": null
        },
        {
            "label": "1991 - 2006",
            "value": null
        },
        {
            "label": "Na 2006",
            "value": null
        }
    ],
    "half_open": [
        {
            "label": "Voor 1950",
            "value": null
        },
        {
            "label": "1950 - 1970",
            "value": null
        },
        {
            "label": "1971 - 1990",
            "value": null
        },
        {
            "label": "1991 - 2006",
            "value": null
        },
        {
            "label": "Na 2006",
            "value": null
        }
    ],
    "terraced": [
        {
            "label": "Voor 1950",
            "value": null
        },
        {
            "label": "1950 - 1970",
            "value": null
        },
        {
            "label": "1971 - 1990",
            "value": null
        },
        {
            "label": "1991 - 2006",
            "value": null
        },
        {
            "label": "Na 2006",
            "value": null
        }
    ],
    "apartment": [
        {
            "label": "Voor 1950",
            "value": null
        },
        {
            "label": "1950 - 1970",
            "value": null
        },
        {
            "label": "1971 - 1990",
            "value": null
        },
        {
            "label": "1991 - 2006",
            "value": null
        },
        {
            "label": "Na 2006",
            "value": null
        }
    ]
}