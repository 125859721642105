import React, { useContext } from "react";
import { Box, IconButton, Stack, Typography, styled } from "@mui/material"
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { useDispatch, useSelector } from "react-redux";
import { checkRerunSimulation, deleteSimulation } from "../../redux/actions/advancedMap";
import { BUILDING_TYPES } from "../../constants/simulation";
import { RemoveLastSimulationHandlerContext } from "../MapController/advanced_map";
import Tooltip from '@mui/material/Tooltip';
import { SIMULATION_STATUS } from '../../redux/constants/constants';

const CircleTypography = styled(Typography)({
    borderRadius: '50%',
    width: '22px',
    height: '22px',
    padding: '1px',
    textAlign: 'center',
    border: "2px solid #43b02a",
    margin: 0,
    fontSize: "12px",
    color: "#43b02a",
    display: "flow-root",
    position: "relative",
    padding: '1px',
    paddingLeft: '6px',
    paddingRight: '6px',
})

const TextInCircle = ({ text }) => <CircleTypography variant="body2" >{text}</CircleTypography>


const ResultHeader = ({ typology, renovationPlan, count, showCount = true }) => {
    const dispatch = useDispatch()
    const { simulation: simulationState } = useSelector(s => s)
    const buildType = BUILDING_TYPES[typology] || typology
    const title = buildType + " - " + renovationPlan

    const [showCloseButton, setShowCloseButton] = React.useState(false);

    const removeLastSimulationAfterEffectsHandler = useContext(RemoveLastSimulationHandlerContext)
    // action to cancel simulation
    const closeSimulationHandler = React.useCallback(() => {
        dispatch(checkRerunSimulation(count))
        dispatch(deleteSimulation(count))
        if (count === 0 && simulationState.data.length === 1)
            removeLastSimulationAfterEffectsHandler()
    }, [count, simulationState.data])

    React.useEffect(() => {
        let status = '';
        simulationState?.data?.map((simulation) => {
            status = simulation.status
            // find the simulations with status 'LOADING' and 'COMPLETE'
            if (status === SIMULATION_STATUS.LOADING) {
                setShowCloseButton(true);
            } else {
                setShowCloseButton(false);
            }
        })
        
        // showCloseButton = simulationState?.data?.status === SIMULATION_STATUS.LOADING ? false : true
    }, [simulationState])

    return (
        <Stack direction="row" gap={1} alignItems="center" sx={{
            paddingLeft: "10px",
            paddingRight: "10px",
            paddingY: "4px",
            height: "30px",
        }}>
            {showCount && (
                <>
                    {!(typology && renovationPlan) && <Box sx={{ flex: "1 auto" }} />}
                    <TextInCircle text={count + 1} />
                    <Box sx={{ flex: "1 auto" }} />
                </>
            )}
            {(typology && renovationPlan) ? (
                <>
                    {!showCount && <Box sx={{ flex: "1 auto" }} />}
                    <Tooltip title={title} arrow={true} placement="top">
                        <Typography
                            color="#979696"
                            fontSize="14px"
                            sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
                        >{title}</Typography>
                    </Tooltip>
                    <Box sx={{ flex: "1 auto" }} />
                </>
            ) : null}
                <IconButton disabled={showCloseButton} sx={{ paddingX: 0 }}>
                    <RemoveCircleIcon onClick={() => closeSimulationHandler()} sx={{ height: "15px", width: "15px", fill: "#c5c5c5" }} />
                </IconButton>
        </Stack>
    )
}

export default ResultHeader