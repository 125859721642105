const DEFAULT_BUTTON_STATUS = true // true -> visible | false -> hide

// Min Abatementcost

export const potentialTabButtonList = {
    "max CO2 besparing": {
        value: "Maximale CO₂ besparing",
        active: DEFAULT_BUTTON_STATUS,
        extraText: `Dit scenario kiest voor elk gebouwtype het pakket dat de hoogste CO2-besparing oplevert. Dit is het meest ingrijpende pakket, want het zal ook een koolstofneutraal gebouw nastreven.`
    },
    "meest rendabel": {
        value: "Meest rendabele pakket",
        active: DEFAULT_BUTTON_STATUS,
        extraText: `Dit scenario kiest voor elk gebouwtype het renovatiepakket met de hoogste economische rendabiliteit. Dit scenario kan beschouwd worden als een eerste stap in een langer proces. Let wel op dat het bij gefaseerde renovaties belangrijk is om lock-in te vermijden.`,
    },
    "meest rendabel A-pakket": {
        // value: "Laagste kost van CO2-reductie",
        value: "Goedkoopste A-label pakket",
        active: DEFAULT_BUTTON_STATUS,
        extraText: `Dit scenario kiest voor elk gebouwtype het pakket dat een A-label haalt met een zo laag mogelijke totale actuele kost (TAK).`
    },
    "niet meer dan anders": {
        value: "Niet meer dan anders pakket",
        active: DEFAULT_BUTTON_STATUS,
        extraText: `Dit scenario toont voor elk gebouwtype het renovatiepakket met de hoogste CO2-besparing waarvoor de netto huidige waarde positief is. Gedurende de levensduur compenseren de besparingen op de energiekosten de initiële investering.`,
    },
}
