import React from "react"
import { Box, Stack, styled } from "@mui/material";
import { useSelector } from "react-redux";

import { SIMULATION_STATUS } from "../../../redux/constants/constants";
import TotalResults from "./TotalResults";
import Result from "../../sharedComponents/Result";

const ResultsContainer = styled(Box)({
    position: "absolute",
    bottom: "11vh",
    left: "5vh",
    color: "#43b02a"
})

const Results = ({smallScreen}) => {
    const { simulation: simulationState } = useSelector(state => state)

    return (
        <ResultsContainer>
            <Box className = {
                    smallScreen ? "resultpanel-small-screens" : ""
                }
                sx={{ position: "relative" }}>
                <Stack direction="column" gap={2}>
                    <Stack direction="row" gap={0.5} sx={{ zIndex: 2, width: "100%", overflow: "auto" }}>
                        {simulationState.data?.map((simulation, idx) => (
                            (
                                simulation?.status === SIMULATION_STATUS.COMPLETE ||
                                simulation?.status === SIMULATION_STATUS.ERROR ||
                                simulation?.status === SIMULATION_STATUS.LOADING
                            ) ? (
                                <Result simulation={simulation} key={idx} count={idx} />
                            ) : null
                        ))}
                    </Stack>
                    <TotalResults />
                </Stack>
            </Box>
        </ResultsContainer>
    )
}

export default Results
