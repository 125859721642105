import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Collapse, Grid, IconButton, Stack, Typography, Button, styled } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import MapIcon from '@mui/icons-material/Map';

import { RESET_SECTOR_SELECTOR, SET_SELECTION } from '../../redux/constants/constants';
import { setStatisticalSectorAlert, setStatisticalSectorSelectionState } from "../../redux/actions/map";
import { SELECT_STATISTICAL_SECTOR_STATE } from "../../constants/map";
import { dataNotAvailableMunicipilities } from '../../constants/general';
import TabsInfoMessage from './TabsInfoMessage';
import AlertMessage from '../../helper/otherMessages';

const ButtonStyled = styled(Button)({
    backgroundColor: "#790081",
    borderRadius: "6px",
    border: "1px",
    pointerEvents: "auto",
    minWidth: "20%",
    marginTop: "-14px",

    ":hover": {
        backgroundColor: "#790081",
    }
})

const TabPanelContainer = ({
    title,
    subTitle = null,
    description = null,
    render,
    showStatisticalSector = false,
    statisticalSectorRender = <></>,
    statisticalSectorAreaName = null,
}) => {

    const { municipalityName = null } = useSelector(state => state?.map?.selectedArea)
    const statisticalSectorState = useSelector(state => state?.statisticalSector)
    const [showAlert, setShowAlert] = React.useState(null)
    const [alertMsg, setAlertMsg] = React.useState(null)

    const dispatch = useDispatch()

    const handleStatistical = React.useCallback(() => {
        if (statisticalSectorState.selection) {
            handleCloseArea()
            dispatch(setStatisticalSectorSelectionState(SELECT_STATISTICAL_SECTOR_STATE.IDLE))
        } else {
            dispatch({
                type: SET_SELECTION,
                payload: { selection: true, areaName: null }
            })
            handleAlert("Klik met je muis een straat aan")
            handleStatSectButtonClick()
        }
    }, [statisticalSectorState])

    function handleCloseArea() {
        // close enerygy area from map
        // clear statistical graphs
        dispatch({
            type: RESET_SECTOR_SELECTOR
        })
    }

    const handleStatSectButtonClick = () => {
        dispatch(setStatisticalSectorSelectionState(SELECT_STATISTICAL_SECTOR_STATE.ACTIVE))
    }

    function handleAlert(msg) {
        dispatch(setStatisticalSectorAlert(true, msg, 'info'))
        
        setTimeout(() => {
            dispatch(setStatisticalSectorAlert(false, null, 'info'))
        }, 1500)
    }

    return (
        <>
            <Grid container direction="row">
                <Grid item xs={6} className="justify-content-start">
                    <Stack direction="row" gap={4} alignItems="center">
                        <h2 className="title">{title}</h2>
                        {subTitle && (
                            <Typography variant="caption" color="text.disabled" sx={{ marginTop: "auto", marginBottom: "auto" }}>{subTitle}</Typography>
                        )}
                        {statisticalSectorAreaName ? (
                            <Stack
                                sx={{ backgroundColor: "#702081", textTransform: "none", height: "1.7rem", opacity: 0.5, borderRadius: 1 }}
                                direction="row"
                                alignItems="center"
                            >
                                <Typography fontSize="14px" color="white" paddingLeft={1} paddingRight={3}>{statisticalSectorAreaName}</Typography>
                                <IconButton sx={{ color: "white", }} onClick={handleCloseArea}><CloseIcon sx={{ height: "16px" }} /></IconButton>
                            </Stack>
                        ) : null}
                    </Stack>
                </Grid>
                {!["", null, undefined].includes(municipalityName) && (
                    <Grid item xs={6} className="d-flex justify-content-end">
                        <Stack width="100%" justifyContent="flex-end" direction="row" marginTop="14px" alignItems="center">
                            <ButtonStyled disableRipple onClick={handleStatistical} startIcon={<MapIcon sx={{ fill: "white" }} />}>
                                <Typography color="white" variant="body2" sx={{ textTransform: "none", fontWeight: 600, fontSize: "12px" }}>
                                    {statisticalSectorState.selection ? "Klik om de functionaliteit te deactiveren" : "Vergelijk met een statistische sector"}
                                </Typography>
                            </ButtonStyled>
                        </Stack>
                    </Grid>
                )}
            </Grid>
            {description && <p className="description-text">{description}</p>}
            {/* Normal render */}
            {dataNotAvailableMunicipilities.includes(municipalityName) ?
                <TabsInfoMessage message={`Er is helaas geen data beschikbaar voor ${municipalityName}`} />
                : render
            }
            {/* Statistical Sector render */}
            <Collapse
                in={showStatisticalSector}
            >
                <Typography variant="h3" fontSize="16px" fontWeight="bold" color="#702081" marginTop={3} marginBottom={1}>{statisticalSectorAreaName}</Typography>
                {statisticalSectorRender}
            </Collapse>
        </>
    )
}

export default TabPanelContainer