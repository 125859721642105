import investmentIcon from "../assets/result-icons/investering.svg"
import annualSavingsIcon from "../assets/result-icons/besparing.svg"
import subsidiesIcon from "../assets/result-icons/subsidies.svg"
import tco2Icon from "../assets/result-icons/TC02.svg"
import investmentIconWhite from "../assets/result-icons/investering-white.svg"
import annualSavingsIconWhite from "../assets/result-icons/besparing-white.svg"
import subsidiesIconWhite from "../assets/result-icons/subsidies-white.svg"
import tco2IconWhite from "../assets/result-icons/TC02-white.svg"

import houseIcon from "../assets/houses/house.svg"
import house2Icon from "../assets/houses/2houses.svg"
import house3Icon from "../assets/houses/3houses.svg"
import apartmentIcon from "../assets/houses/appartement.svg"

export const resultContents = {
    "investment": {
        icon: investmentIcon,
        extraText: "Investering",
        whiteIcon: investmentIconWhite,
    },
    "subsidies": {
        icon: subsidiesIcon,
        extraText: "Subsidies",
        whiteIcon: subsidiesIconWhite,
    },
    "annual_savings": {
        icon: annualSavingsIcon,
        extraText: "Jaarlijkse besparing",
        whiteIcon: annualSavingsIconWhite,
    },
    "co2_savings": {
        icon: tco2Icon,
        extraText: "Jaarlijkse CO₂ besparing",
        whiteIcon: tco2IconWhite,
    }
}


export const SIMULATION_CLICK_STATE = {
    BEFORE: 'before',
    AFTER: 'after'
}

export const BUILDING_TYPES = {
    'detached_scen': "Vrijstaande huizen",
    'semi_detached_scen': "Halfopen bebouwing",
    'terraced_scen': "Rijhuizen",
    'apartment_scen': "Appartementen",

    'detached_building': "Vrijstaande huizen",
    'semi_detached_building': "Halfopen bebouwing",
    'terraced_building': "Rijhuizen",
    'apartment': "Appartementen",
    'apartment_building': "Appartementen",
    'APT': "Appartementen",
}

export const BUILDING_TYPES_CONSTRUCTION = {
    'open': "Vrijstaande huizen",
    'half_open': "Halfopen bebouwing",
    'terraced': "Rijhuizen",
    'apartment': "Appartementen",
}

export const BUILDING_TYPES_ENG = {
    'detached_building': "Vrijstaande huizen",
    'semi_detached_building': "Halfopen bebouwing",
    'terraced_building': "Rijhuizen",
    'apartment': "Appartementen",
    'apartment_building': "Appartementen",
    'APT': "Appartementen",
}

export const categoriesIcons = {
    "detached_scen": houseIcon,
    "semi_detached_scen": house2Icon,
    "apartment_scen": apartmentIcon,
    "apartment": apartmentIcon,
    "terraced_scen": house3Icon,
}