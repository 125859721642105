import React, {useEffect, useState} from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { Grid  } from '@material-ui/core';
import parse from 'html-react-parser';
import mask from '../../assets/used/homepage-line.svg';
import sideline from '../../assets/used/trace.svg';
import videoIcon from '../../assets/used/play.svg';
import { SearchBar } from "./searchBar";
import { ROUTES } from "../../constants/routes";
import { activeView } from '../../redux/actions/auth';
import {SEARCH_BAR_TYPES} from "../../constants/map";
import {FillButton} from "../../helper/buttons";
import { Box } from '@mui/material';

 function LandingPage(props) {

   if (props.lpContent){
      var {
         title, 
         text1,
         text2,
         searchBarButtonText,
         placeHolderText,
         videoBtnText
      } = props?.lpContent.attributes;
   }
   const [searchClick, setSearchClick] = useState(false)
   const history = useHistory()

   useEffect(()=>{
    // console.log('content',props.content.all_headings)
   },[props.content.all_headings])

   return (
      <>
      <Grid container className="position-relative landing-page-container">
         <Grid item xs={12} className='landing-image'>
             <div className="landing-image-mask"
             />
             <Box>
             <img className="trace-sideline"
                  src={sideline}
                  alt={'sideline'}
                  />
            </Box>
         <Grid container item xs={12} md={8} lg={6} justifyContent='center'>
              <div className='landing-container'>
                  <span className='text-left landing-container-heading' style={{ padding: 0, marginRight: "auto" }}> {parse(`${title}`)}</span>
                    <span className='landing-font pt-0'>{parse(`${text1}`)}</span>
                    <span className='text-center landing-font pt-0'>{parse(`${text2}`)}</span>
                    <div className="input-group pt-0 pb-0 landing-btn-container flex flex-col gap-2">
                        <SearchBar
                            externalClick={searchClick}
                            setExternalClick={setSearchClick}
                            searchBarType={SEARCH_BAR_TYPES.WITH_SEARCH_ICON}
                            searchBarClickHandler={(destinationPage=ROUTES.MAP_PAGE)=> {
                                history.push(destinationPage)
                            }}
                            placeHolderText={placeHolderText}
                            setActiveView={props.activeView}
                            searchBarBtnText={searchBarButtonText}
                            landing={true}
                        />
                        <div>
                            <FillButton
                                onClickHandler={()=> {
                                    setSearchClick(prevState => !prevState)
                                }}
                                name={searchBarButtonText}
                                classes="bg-dark-green start-button"
                            />
                        </div>
                    </div>
              </div>
            </Grid>
         </Grid>
             {/*<FillButton*/}
             {/*    classes="video-button"*/}
             {/*    name="Bekijk de demo"*/}
             {/*    trailingIcon={videoIcon}*/}
             {/*    trailingIconClasses="video-icon"*/}
             {/*/>*/}
      </Grid>
      </>
   )
}

const mapStateToProps = ({auth, content,loader}) => ({auth, content,loader})
export default connect(mapStateToProps, {activeView}) ( LandingPage );
