import React from "react";
import { useSelector } from "react-redux";

import ResultHeader from '../../sharedComponents/ResultHeader';
import { Box, Stack } from "@mui/material";
import {getKeyAgainstValue} from "../../../helper/generalHelpers";

const SimulationHeaders = () => {
    const { simulation: simulationState } = useSelector(state => state)
    const { renovationPlans } = useSelector(state => state.simulation)
    return (
        <Stack direction="column">{
            simulationState?.data?.map((simulation, idx) => (
                <Box key={idx}>
                    <ResultHeader
                        typology={simulation?.param?.typology || null}
                        renovationPlan={getKeyAgainstValue(simulation?.param?.renovation_plan, renovationPlans) || null}
                        count={idx}
                    />
                    <hr style={{ height: "0.2px", margin: 0 }} />
                </Box>
            ))
        }
        </Stack>
    )
}

export default SimulationHeaders
