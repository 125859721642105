import React from "react"
import { useDispatch, useSelector } from "react-redux";
import { Box, Stack } from "@mui/material"

import TabPanelContainer from "../../sharedComponents/TabPanelContainer";
import TabsInfoMessage from "../../sharedComponents/TabsInfoMessage";
import { potentialTabDummyData } from "./dummyData"
import ToggleButtons from "./ToggleButtons";
import GraphResultContainer from "./GraphResultContainer";
import ChartApiService from "../../../services/chartApiService"
import { createDownloadPdfURL } from "../../../constants/general";


const Potential = ({ handleTabData, data = null, submenuBtnClick, potentialTabBtnsState }) => {
    const { areaName: statisticalSectorArea, loading: sectorLoading } = useSelector(state => state?.statisticalSector)
    const { municipalityName = null } = useSelector(state => state?.map?.selectedArea)

    const [loading, setLoading] = React.useState(false)
    const [sectLoading, setSectLoading] = React.useState(false)
    const dispatch = useDispatch()

    const finalData = {
        municipality: null,
        sector: null
    }
    
    React.useEffect(async () => {
        if (["", null, undefined].includes(municipalityName)) {
            handleTabData("potential", null)
            return
        }
        if (![null, undefined].includes(data?.municipality) && (statisticalSectorArea === null || data?.sector !== null)) return
        if (municipalityName.length) {
            setLoading(true)
            finalData.municipality = await ChartApiService.getPotentialDataOfMunicipality(municipalityName)
            // handleTabData("potential", finalData)
            setLoading(false)
        }
        if (statisticalSectorArea) {
            setSectLoading(true)
            finalData.sector = await ChartApiService.getPotentialDataOfMunicipality(statisticalSectorArea)
            setSectLoading(false)
        }
        handleTabData("potential", finalData)
    }, [municipalityName, data, statisticalSectorArea]);

    React.useEffect(async () => {
        if (statisticalSectorArea) {
                setSectLoading(true)
                finalData.sector = await ChartApiService.getPotentialDataOfMunicipality(statisticalSectorArea)
                handleTabData("potential", finalData)
                setSectLoading(false)
            }
    }, [statisticalSectorArea])

    return (
        <Box>
            <TabPanelContainer
                title="POTENTIEEL"
                // subTitle={<TabsInfoMessage message="Binnenkort beschikbaar..." />}
                // subTitle={`(On cityname/postcode sector)`}
                showStatisticalSector={![null, "", undefined].includes(statisticalSectorArea)}
                statisticalSectorAreaName={statisticalSectorArea}
                description={<p>Op dit tabblad vind je de vier belangrijkste renovatiepistes opgesplitst per gebouwtypologie. Het zijn dezelfde
                renovatiepistes die worden gedetailleerd in <a href={createDownloadPdfURL(municipalityName)} download="PDF" target="_blank">de begeleidende gids.</a> Activeer bovenaan de pagina de renovatiepistes die je wil vergelijken.</p>}
                // render={<DemoImage />}
                // render={<NormalGraphs data={data || potentialTabDummyData} />}
                render={
                    (data?.municipality) ?
                        <NormalGraphs
                            submenuBtnClick={submenuBtnClick}
                            potentialTabBtnsState={potentialTabBtnsState}
                            data={data?.municipality}
                        />
                        :
                        <TabsInfoMessage {...loading ? { message: "Loading..." } : null} />
                    }
                statisticalSectorRender={
                    (data?.sector) ? 
                        <StatisticalGraphs
                            potentialTabBtnsState={potentialTabBtnsState}
                            data={data?.sector}
                        />
                        :
                        <TabsInfoMessage {...sectLoading ? { message: "Loading..." } : null} />

            }
            />
        </Box>
    )
}

export default React.memo(Potential)

function NormalGraphs({ data, submenuBtnClick, potentialTabBtnsState }) {
    return <>
        <Stack direction="column" gap={0}>
            <ToggleButtons submenuBtnClick={submenuBtnClick} potentialTabBtnsState={potentialTabBtnsState} />
            <GraphResultContainer potentialTabState={potentialTabBtnsState} data={data} />
        </Stack>
    </>
}

function StatisticalGraphs({data, potentialTabBtnsState}){
    return (
        <Stack direction="column" gap={0}>
            <GraphResultContainer potentialTabState={potentialTabBtnsState} data={data} />
        </Stack>
    )
}
