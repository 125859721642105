export const DEFAULT_SELECTED_AREA = {
  addressId: "",
  houseNo: "",
  streetId: "",
  streetName: "",
  municipalityName: "",
  municipalityId: "",
  postCode: "",
  level: ""
};

export const MAP_LAYERS = {
  CURRENT_HEAT_STATE_STREETS: "current-hs-street",
  CURRENT_HEAT_STATE_SECTORS: "current-hs-sector",
  SIMULATION_HEAT_STATE_STREETS: "simulation-hs-street",
  SIMULATION_HEAT_STATE_SECTORS: "simulation-hs-sector",
  SIMULATION_INVESTMENT_STREETS: "simulation-investment-street",
  SIMULATION_INVESTMENT_SECTORS: "simulation-investment-sector",
  INVESTMENT_STREETS: "investment-street",
  INVESTMENT_SECTORS: "investment-sector",
  POTENTIAL_STREETS: "potential-street",
  POTENTIAL_SECTORS: "potential-sector",
  DIRECTION_STREETS: "direction-street",
  INCOME_SECTORS: "income-sector",
  AGE_SECTORS: "age-sector",
  TENANT_SECTORS: "tenant-sector",
};

export const SEARCH_BAR_TYPES = {
  WITH_BUTTON: 1,
  WITH_SEARCH_ICON: 2,
};

export const SEARCH_LEVELS = {
  MUNICIPALITY: "municipality",
  HOUSE: 'house'
};

export const SIMULATION_STATUS = {
  IDLE: "idle", // no changes
  CALCULATING: "calculating",
  UPDATING: "updating",
  CALCULATED: "calculated",
  TOUCHED_AFTER_CALCULATION: "touched_after_calculation", // to track immediate saving after calculation => Decision algo is not triggered otherwise
  UPDATED: "updated",
  SAVED: "saved",
  TOUCHED_AFTER_SAVING: "touched_after_saving", // to track immediate changes after saving => Decision algo is not triggered otherwise
};

export const EPC_LABEL_TO_DB_MAPPING = {
  1: "A",
  2: "B",
  3: "C",
  4: "D",
  5: "E",
  6: "F",
};

export const EPC_LABEL_TO_DB_MAPPING_NEW = {
  '100': "A",
  '200': "B",
  '300': "C",
  '400': "D",
  '500': "E",
  '600': "F",
};

export const SELECT_STATISTICAL_SECTOR_STATE = {
  IDLE: 'idle',
  ACTIVE: 'active',
  SELECTING: 'selecting',
  SELECTED: 'selected'
}