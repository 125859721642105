import {
    SET_SELECTION,
    RESET_SECTOR_SELECTOR,
    SET_ALERT,
} from "../constants/constants"

const INIT_STATE = {
    selection: false,
    areaName: null,
    showAlert: null,
    alertMsg: null,
    alertType: 'info',
    loading: null,
}

const statisticalSectorReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_SELECTION:
            const { payload } = action
            return {
                ...state,
                selection: payload?.selection,
                areaName: payload?.areaName,
            }

        case RESET_SECTOR_SELECTOR:
            return {
                ...state,
                ...INIT_STATE,
            }

        case SET_ALERT:
            return {
                ...state,
                ...action.payload
            }

        default:
            return state
    }
}

export default statisticalSectorReducer
