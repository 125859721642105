import React from "react"
import { ReactSVG } from "react-svg"
import { Box, Grid, Stack, Typography, styled } from "@mui/material";
import Collapse from '@mui/material/Collapse';

import { resultContents } from "../../../constants/simulation";
import { useSelector } from "react-redux";
import { SIMULATION_STATUS } from "../../../redux/constants/constants";
import { isCurrencyUnit } from "../../../helper/simulation";

const Contianer = styled(Stack)({
    background: "#54565a",
    paddingTop: "20px",
    paddingBottom: "20px",
    paddingLeft: "2px",
    paddingRight: "2px",
    marginTop: "-25px",
    // width: "758px",
    color: "white",
})

const Item = ({ type, value, unit, isTwoRows }) => {
    const icon = resultContents[type]?.whiteIcon || ""
    const title = resultContents[type]?.extraText || ""
    const formatedValue = Number(value)?.toLocaleString("es-ES", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    }) || null

    const price = isCurrencyUnit(unit) ? [unit, formatedValue].join(" ") : [formatedValue, unit].join(" ")

    return (
        <Stack direction="row" gap={1} alignItems="center" >
            <Box>
                <ReactSVG src={icon} />
                {/* <img src={icon} height="30px" width="30px" alt="" /> */}
            </Box>
            <Stack direction="column" gap={0}>
                <Typography fontSize="14px" variant="body2" minWidth="110px">{price}</Typography>
                <Typography fontSize="12px" variant="body2">{title}</Typography>
            </Stack>
        </Stack>
    )
}

const TotalResults = () => {
    const { totalResult = null, data: simulationState } = useSelector(s => s?.simulation || {})

    const [showTotalResults, setShowTotalResults] = React.useState(false)
    const [isTwoRows, setIsTwoRows] = React.useState(false)

    React.useEffect(() => {
        const inFocusFilters = [SIMULATION_STATUS.COMPLETE]
        const filteredSimulations = simulationState?.filter((simulation) => inFocusFilters.includes(simulation?.status))
        const minimumCompletedSimulations = 2
        setShowTotalResults(filteredSimulations?.length >= minimumCompletedSimulations)
        setIsTwoRows(filteredSimulations?.length > 2)
    }, [simulationState])

    return (
        <Collapse in={totalResult && showTotalResults}>
            <Contianer >
                <Stack direction="column" gap={1} alignItems="center" width="98%" marginLeft="auto" marginRight="auto">
                    <Typography color="#b1b2b3" fontSize="12px" >Combinatie</Typography>
                    <Grid container alignItems="center" rowSpacing={1} maxWidth={isTwoRows ? "640px" : "400px"}>
                    {/* <Grid container rowSpacing={1} maxWidth={isTwoRows ? "640px" : "500px"}> */}
                        {Object.entries(totalResult || {}).map(([key, values]) => (
                            <Grid key={key} item lg={isTwoRows ? 3 : 6}>
                                <Item type={key} value={values?.value} unit={values?.unit} isTwoRows={isTwoRows} />
                            </Grid>
                        ))}
                    </Grid>
                </Stack>
            </Contianer >
        </Collapse >
    )
}

export default TotalResults
