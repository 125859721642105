export const potentialTabDummyData = {
    "max CO2 besparing": [
        {
            "building_type": "detached_building",
            "number_of_buildings": 4671,
            "total_subsidies_sum": 0,
            "total_investment_cost_sum": 873708808,
            "yearly_savings_sum": 7473458.145,
            "co2_savings_sum": 28969.13212,
            "total_co2_sum": -1916463.821,
            "tco_sum": 21650168.79,
            "pv_production_sum": 41007879.36,
            "potential": 747.3530342,
            "delta_tco": 21650168.79,
            "delta_co2": 28969.13212,
            "pack": "window_wallExt_roofL_HPAW_PVmax"
        },
        {
            "building_type": "terraced_building",
            "number_of_buildings": 567,
            "total_subsidies_sum": 0,
            "total_investment_cost_sum": 68243054.34,
            "yearly_savings_sum": 988660.2918,
            "co2_savings_sum": 2358.518922,
            "total_co2_sum": -45740.52176,
            "tco_sum": 1286108.186,
            "pv_production_sum": 3662034.768,
            "potential": 545.3033148,
            "delta_tco": 1286108.186,
            "delta_co2": 2358.518922,
            "pack": "window_wallExt_roofL_HPAW_PVmax"
        },
        {
            "building_type": "semi_detached_building",
            "number_of_buildings": 1425,
            "total_subsidies_sum": 0,
            "total_investment_cost_sum": 216979583.6,
            "yearly_savings_sum": 2641736.819,
            "co2_savings_sum": 6972.001147,
            "total_co2_sum": -311132.0466,
            "tco_sum": 4590915.968,
            "pv_production_sum": 10178798.11,
            "potential": 658.4789462,
            "delta_tco": 4590915.968,
            "delta_co2": 6972.001147,
            "pack": "window_wallExt_roofL_HPAW_PVmax"
        },
        {
            "building_type": "APT",
            "number_of_buildings": 200,
            "total_subsidies_sum": 0,
            "total_investment_cost_sum": 109871510.1,
            "yearly_savings_sum": 964923.4453,
            "co2_savings_sum": 4947.136548,
            "total_co2_sum": -180178.3478,
            "tco_sum": 2697460.224,
            "pv_production_sum": 6432625.08,
            "potential": 545.256877,
            "delta_tco": 2697460.224,
            "delta_co2": 4947.136548,
            "pack": "window_wallExt_roofL_HPAW_PVmax"
        },
    ],
    "meest rendabel": [
        {
            "building_type": "detached_building",
            "number_of_buildings": 4671,
            "total_subsidies_sum": 0,
            "total_investment_cost_sum": 84367043.14,
            "yearly_savings_sum": 11971316.69,
            "co2_savings_sum": 11040.06806,
            "total_co2_sum": 14827431.74,
            "tco_sum": -9159081.915,
            "pv_production_sum": 38335465.37,
            "potential": -829.6218705,
            "delta_tco": -9159081.915,
            "delta_co2": 11040.06806,
            "pack": "PVA"
        },
        {
            "building_type": "terraced_building",
            "number_of_buildings": 567,
            "total_subsidies_sum": 0,
            "total_investment_cost_sum": 6422125.559,
            "yearly_savings_sum": 941927.9594,
            "co2_savings_sum": 872.5959798,
            "total_co2_sum": 1238811.22,
            "tco_sum": -727857.1074,
            "pv_production_sum": 2832110.761,
            "potential": -834.1284217,
            "delta_tco": -727857.1074,
            "delta_co2": 872.5959798,
            "pack": "PVA"
        },
        {
            "building_type": "semi_detached_building",
            "number_of_buildings": 1425,
            "total_subsidies_sum": 0,
            "total_investment_cost_sum": 20178586.09,
            "yearly_savings_sum": 2903124.468,
            "co2_savings_sum": 2682.650603,
            "total_co2_sum": 3613260.697,
            "tco_sum": -2230504.932,
            "pv_production_sum": 9062447.904,
            "potential": -831.4556243,
            "delta_tco": -2230504.932,
            "delta_co2": 2682.650603,
            "pack": "PVA"
        },
        {
            "building_type": "APT",
            "number_of_buildings": 200,
            "total_subsidies_sum": 0,
            "total_investment_cost_sum": 12746781.88,
            "yearly_savings_sum": 1808097.868,
            "co2_savings_sum": 1669.921779,
            "total_co2_sum": 3085149.721,
            "tco_sum": -1383205.139,
            "pv_production_sum": 5791106.945,
            "potential": -828.305347,
            "delta_tco": -1383205.139,
            "delta_co2": 1669.921779,
            "pack": "PVA"
        },
    ],
    "meest rendabel A-pakket": [
        {
            "building_type": "detached_building",
            "number_of_buildings": 4671,
            "total_subsidies_sum": 0,
            "total_investment_cost_sum": 653036039.5,
            "yearly_savings_sum": 5174087.862,
            "co2_savings_sum": 24247.17064,
            "total_co2_sum": 171551.3606,
            "tco_sum": 16593780.12,
            "pv_production_sum": 34326384.73,
            "potential": 684.35944,
            "delta_tco": 16593780.12,
            "delta_co2": 24247.17064,
            "pack": "window_wallExt_roofL_HPAW_PVmax"
        },
        {
            "building_type": "terraced_building",
            "number_of_buildings": 567,
            "total_subsidies_sum": 0,
            "total_investment_cost_sum": 45449842.41,
            "yearly_savings_sum": 871509.6735,
            "co2_savings_sum": 2147.421783,
            "total_co2_sum": 140537.2174,
            "tco_sum": 643485.0734,
            "pv_production_sum": 3388664.104,
            "potential": 299.6547202,
            "delta_tco": 643485.0734,
            "delta_co2": 2147.421783,
            "pack": "window_wallExt_roofL_HPAW_PVA"
        },
        {
            "building_type": "semi_detached_building",
            "number_of_buildings": 1425,
            "total_subsidies_sum": 0,
            "total_investment_cost_sum": 164733221,
            "yearly_savings_sum": 2214239.552,
            "co2_savings_sum": 6222.813687,
            "total_co2_sum": 129200.9132,
            "tco_sum": 3276867.814,
            "pv_production_sum": 9023221.496,
            "potential": 526.5894143,
            "delta_tco": 3276867.814,
            "delta_co2": 6222.813687,
            "pack": "window_wallExt_roofL_HPAW_PVA"
        },
        {
            "building_type": "APT",
            "number_of_buildings": 200,
            "total_subsidies_sum": 0,
            "total_investment_cost_sum": 45474823.15,
            "yearly_savings_sum": 1440989.581,
            "co2_savings_sum": 3606.057153,
            "total_co2_sum": 1160901.047,
            "tco_sum": 74837.85748,
            "pv_production_sum": 5926761.589,
            "potential": 20.75337531,
            "delta_tco": 74837.85748,
            "delta_co2": 3606.057153,
            "pack": "PVA"
        },
    ],
    "niet meer dan anders": [
        {
            "building_type": "detached_building",
            "number_of_buildings": 4671,
            "total_subsidies_sum": 0,
            "total_investment_cost_sum": 360447994.8,
            "yearly_savings_sum": 15622693.19,
            "co2_savings_sum": 19715.99813,
            "total_co2_sum": 7144341.873,
            "tco_sum": -3607760.031,
            "pv_production_sum": 40926622.12,
            "potential": -182.9864259,
            "delta_tco": -3607760.031,
            "delta_co2": 19715.99813,
            "pack": "wallExt_PVA"
        },
        {
            "building_type": "terraced_building",
            "number_of_buildings": 567,
            "total_subsidies_sum": 0,
            "total_investment_cost_sum": 29508868.21,
            "yearly_savings_sum": 1537321.038,
            "co2_savings_sum": 1933.759456,
            "total_co2_sum": 379018.9443,
            "tco_sum": -553692.098,
            "pv_production_sum": 3652499.799,
            "potential": -286.3293552,
            "delta_tco": -553692.098,
            "delta_co2": 1933.759456,
            "pack": "wallExt_PVA"
        },
        {
            "building_type": "semi_detached_building",
            "number_of_buildings": 1425,
            "total_subsidies_sum": 0,
            "total_investment_cost_sum": 92014796.82,
            "yearly_savings_sum": 4411003.383,
            "co2_savings_sum": 5340.135617,
            "total_co2_sum": 1311158.783,
            "tco_sum": -1343843.489,
            "pv_production_sum": 10143743.27,
            "potential": -251.649693,
            "delta_tco": -1343843.489,
            "delta_co2": 5340.135617,
            "pack": "wallExt_PVA"
        },
        {
            "building_type": "APT",
            "number_of_buildings": 200,
            "total_subsidies_sum": 0,
            "total_investment_cost_sum": 48155919.86,
            "yearly_savings_sum": 2409775.394,
            "co2_savings_sum": 3566.695231,
            "total_co2_sum": 1200262.969,
            "tco_sum": -804578.0651,
            "pv_production_sum": 6432127.827,
            "potential": -225.5808285,
            "delta_tco": -804578.0651,
            "delta_co2": 3566.695231,
            "pack": "wallExt_PVA"
        },
    ],
}