import investmentIcon from "../assets/result-icons/investering.svg"
import annualSavingsIcon from "../assets/result-icons/besparing.svg"
import subsidiesIcon from "../assets/result-icons/subsidies.svg"
import tco2Icon from "../assets/result-icons/TC02.svg"


export const BUILDING_TYPES_BORDER_COLORS = {
    'detached_building': "#1C98C6",
    'semi_detached_building': "#F88018",
    'terraced_building': "#66B654",
    'apartment': "#E20000",
    'apartment_building': "#E20000",
    'APT': "#E20000",
}

// order of keys matters
export const BUILDING_TYPES_POTENTIAL = {
    'detached_scen': "Vrijstaande huizen",
    'terraced_scen': "Rijhuizen",
    'semi_detached_scen': "Halfopen bebouwing",
    'apartment_scen': "Appartementen",

    'detached_building': "Vrijstaande huizen",
    'terraced_building': "Rijhuizen",
    'semi_detached_building': "Halfopen bebouwing",
    'apartment': "Appartementen",
    'apartment_building': "Appartementen",
    'APT': "Appartementen",
}

export const potentialTabConfig = {
    'total_investment_cost_sum': {
        icon: investmentIcon,
        extraText: "Investering",
        unit: '€',
        price: true
    },
    'total_subsidies_sum': {
        icon: subsidiesIcon,
        extraText: "Subsidies",
        unit: '€',
        price: true
    },
    'yearly_savings_sum': {
        icon: annualSavingsIcon,
        extraText: "Jaarlijkse besparing",
        unit: '€',
        price: true
    },
    'co2_savings_sum': {
        icon: tco2Icon,
        extraText: "CO₂ besparing",
        unit: 'Ton',
        price: false
    },
}