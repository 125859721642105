import React from "react"
import { Box, Stack, Typography } from "@mui/material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useSelector } from "react-redux";
import HtmlTooltip from "../../sharedComponents/HtmlTooltip";
import { TooltipTitles } from "./TooltipsText";

const SimulationPackages = ({ plans = [], selectPlanHandler, selectedPlan }) => {
    // const { disableSimulationRerun } = useSelector(s => s.simulation)

    return <>
        <Stack direction="row" gap={1} paddingX={2} >
            <Typography color="gray" fontWeight="bold" variant="body2" fontSize="12px">{"Selecteer het scenario"}</Typography>
            <HtmlTooltip title={TooltipTitles["selectPackage"]} placement="right">
                <InfoOutlinedIcon fontSize='small' sx={{ fill: "#84c2d9" }} />
            </HtmlTooltip>
        </Stack>
        <Box paddingX={2}>
            <select value={selectedPlan || ""} onChange={selectPlanHandler} class="border-1 bg-red-500 py-1 px-2 text-[4px] text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-none block w-full">
                <option selected value="" >Selecteer renovatie</option>
                {Object.entries(plans)?.map(([name, value]) => (
                    <option key={value} name={name} value={value}>{name}</option>
                ))}
            </select>
        </Box>
    </>
}

export default SimulationPackages