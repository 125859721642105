        export const ageColors = ["#f6eff7",
        "#eeebf2",
        "#e6e6ec",
        "#dee2e7",
        "#d5dee2",
        "#cdd9dd",
        "#c5d5d7",
        "#bdd0d2",
        "#b5cccd",
        "#adc8c8",
        "#a4c3c2",
        "#9cbfbd",
        "#94bbb8",
        "#8cb6b3",
        "#84b2ad",
        "#7caea8",
        "#73a9a3",
        "#6ba59d",
        "#63a098",
        "#5b9c93",
        "#53988e",
        "#4b9388",
        "#428f83",
        "#3a8b7e",
        "#328679",
        "#2a8273",
        "#227d6e",
        "#1a7969",
        "#117564",
        "#09705e",
        "#016c59",
        "#016E5E"]