import React from 'react'
import { potentialTabConfig, BUILDING_TYPES_BORDER_COLORS } from "../../constants/potentialTab"
import { ContentRow } from './Result'
import { Box, Stack, Typography, styled } from '@mui/material'
import { RenovationKeyToValue } from '../../constants/renovation'
import { BUILDING_TYPES } from '../../constants/simulation'
import Tooltip from '@mui/material/Tooltip';

const Container = styled(Stack)(({ borderTopColor }) => ({
    borderTop: `4px solid ${borderTopColor}`,
    background: "white",
    boxShadow: "0px 2px 5px 0px #00000035",
    height: "100%",
}))


const PotentialTabResult = ({ contentObject }) => {
    const {
        building_type: buildingType,
        pack,
    } = contentObject

    const borderTopColor = BUILDING_TYPES_BORDER_COLORS[buildingType]
    const renovationPlan = RenovationKeyToValue(pack) || pack

    return (
        <Container direction="column" borderTopColor={borderTopColor}>
            <Header typology={buildingType} renovationPlan={renovationPlan} />
            <Box sx={{ borderBottom: "1px solid #c5c5c5", marginY: "3px" }} />
            <Rows contentObject={contentObject} />
        </Container>
    )
}

export {
    PotentialTabResult,
}

function Header({ typology, renovationPlan }) {
    const buildType = BUILDING_TYPES[typology] || typology
    const title = buildType + " - " + renovationPlan

    return (
        <Stack direction="row" alignItems="center" justifyContent="center" sx={{
            paddingLeft: "20px",
            paddingRight: "20px",
            paddingY: "4px",
            height: "30px",
        }}>
            <Tooltip title={title} arrow={true} placement="top">
                <Typography
                    color="#979696"
                    fontSize="14px"
                    sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
                >{title}</Typography>
            </Tooltip>
        </Stack>
    )
}

function Rows({ contentObject }) {
    return (
        <Stack gap={0.5} flexGrow={1} justifyContent="space-between" sx={{ paddingBottom: "6px", paddingX: "10px", minHeight: "100px" }}>{
            Object.entries(potentialTabConfig).map(([key, { icon, extraText, unit, price }]) => {
                const value = Number(contentObject[key])?.toLocaleString("es-ES", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                }) || contentObject[key]

                const text = price ? [unit, value, extraText].join(" ") : [value, unit, extraText].join(" ")

                return (
                    <ContentRow key={key} icon={icon} text={text} unit={unit} />
                )
            })}
        </Stack>
    )
}