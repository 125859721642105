
export const TooltipTitles = {
    "typologies": <>Je kan per gebouwtype een renovatiescenario selecteren. Hiervoor kan je kiezen uit de individuele maatregelen alsook 4 pakketten.</>,
    "selectPackage": <>
        <p>De 4 pakketten zijn geselecteerd op basis van het renovatiepotentieel en kunnen geïnterpretteerd worden als</p>
        <ul>
            <li>Meest rendabele pakket  voor individuele eigenaars</li>
            <li>Niet meer dan anders pakket</li>
            <li>Goedkoopste A-label pakket</li>
            <li>Maximale CO₂ besparing</li>
        </ul>
        <p>Meer details over de inhoud van de pakketten en de aanpak rond de selectie, vind je terug in de begeleidende gids voor je gemeente.</p>
    </>
}
