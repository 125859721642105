export const HEAT_STATES_COLORS = {
  A: "#489323",
  B: "#bdd70e",
  C: "#eddd03",
  D: "#feb027",
  E: "#fe610f",
  F: "#ED181B",
};

export const POTENTIAL_MAP_COLORS = {
  A: "#e3c1dc",
  B: "#da8dbb",
  C: "#ca5a9a",
  D: "#ba2679",
};

export const INVESTMENT_COLORS = {
  A: "#D9C9DE",
  B: "#B393BD",
  C: "#69287E",
};

export const POTENTIAL_COLORS = {
  left: "#EAC1DC",
  leftRgb: "rgb(234, 193, 220)",
  right: "#BA2679",
  rightRgb: "rgb(186, 38, 121)",
};

export const SEARCH_DIRECTION_COLORS = {
  A: "#908c39",
  B: "#702082",
  C: "#B78FC0",
  D: "#97999B",
  E: "#A0D794",
  F: "#43B02A",
};
export const COLLECTIVE_HEATING_PROBABILITY_CLUSTERING = {
    0: "#908c39",
    "Collectief": "#702082",
    "Eerder Collectief": "#B78FC0",
    "Onzeker": "#dedede",
    "Eerder individueel": "#A0D794",
    "Individueel": "#43B02A",
    "SelectedCluster": "#f57623"
}
export const INCOME_COLORS = {
  A: "#C1E1ED",
  B: "#66d2e3e6",
  C: "#4bc9dd",
  D: "#43ADD5",
  E: "#1C98C6",
};

export const AGE_COLORS = {
  A: "#f0e888",
  B: "#f7eb58",
  C: "#F5E51F",
  D: "#E8D816",
  E: "#D1C207",
};

export const TENANT_COLORS = {
  A: "#fcbba1",
  B: "#fc9272",
  C: "#ef3b2c",
  D: "#cb181d",
  E: "#b30000",
};

export const BUILDING_TYPES_COLORS = {
  A: "#E03C31",
  B: "#E08B86",
  C: "#707070",
  D: "#83CC76",
  E: "#43B02A",
};

export const INCOME_GRADIENT_COLORS = {
  left: "#C1E1ED",
  leftRgb: "rgb(193, 225, 237)",
  right: "#1C98C6",
  rightRgb: "rgb(28, 152, 198)",
};

export const AGE_GRADIENT_COLORS = {
  left: "#f6eff7",
  leftRgb: "rgb(246, 239, 247)",
  right: "#016c59",
  rightRgb: "rgb(1, 108, 89)",
};

export const TENANTS_GRADIENT_COLORS = {
  left: "#fef0d9",
  leftRgb: "rgb(254, 240, 217)",
  right: "#b30000",
  rightRgb: "rgb(179, 0, 0)",
};