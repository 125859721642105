import React,{useState,useEffect} from "react"
import {connect} from 'react-redux';
import { Link, useHistory } from "react-router-dom";
import { Row, Col, CardBody, Card, Container } from "reactstrap";
import '../../scss/ikrb/login.scss';
import { AvForm } from "availity-reactstrap-validation";
import profile from "../../assets/used/profile-img.png";
import {userRegistration, changePassword} from '../../redux/actions/auth';
import SuccessMessage  from '../../helper/successMessage';
import {activeView} from '../../redux/actions/auth';
import {passwordList} from '../../constants/auth'
import { useQuery } from "../../utils/hooks";
import { getCookieValue } from "../../helper/generalHelpers";

const ChangePassword = (props) => {

  const history = useHistory()
  const [passwords, setPasswords] = useState(passwordList)
  const [mismatch, setMismatch] = useState(false)
  const [passwordValidateText, setPasswordValidateText] = useState('');
  const [isPasswordNotValid, setIsPasswordNotValid]= useState(false);
  const [tokenInvalid, setTokenInvalid] = useState(null)

  const query = useQuery()

  useEffect(()=>
  {
    props.activeView(4);
  },[passwords])

  // check token existance
  React.useEffect( () => {
    let checkAuth = document.cookie.indexOf("access_token") == -1 ? false : true;
    const token = query.get("token") || null

    if(!token && !checkAuth){
      // redirect to login page
      history.push("/login")
    }
  }, [])


  function validatePassword(password){
    
    let errors = [];

    if (password.length < 8)  errors.push(' 8 tekens');

    // if (!/[A-Z]/. test(password)) errors.push(' 1 hoofdletter');   

    // if (!/[a-z]/. test(password)) errors.push(' 1 kleine letter');
    
    // if (!/\d/. test(password)) errors.push(' 1 cijfer en');

    // if (!/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/. test(password)) errors.push(' 1 speciaal teken bestaan')

    let txt = `Uw wachtwoord moet uit ten minste ${errors.join(',')}`;

    if (errors[errors.length - 1] !== '8 character long') txt = `${txt} character`     

    txt = `${txt}.`

    
    const re = /^.{8,}$/
    // const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
    const status = re.test(String(password))
    if(!status) {
      setPasswordValidateText(txt);
    }

    return status
  }

  const handleChange = ({target}) =>
  {
    let { value, id } = target;
    let items = passwords;
    items[id].value = value;
    setPasswords([...passwords])
  }

  const handleEye = (index) => {
    let items = passwords;
    items[index].eye = !items[index].eye;
    setPasswords([...passwords])
  }

  const validation = () => {
    passwords.map((pass)=> {
      if (!validatePassword(pass.value)) {
        pass.error = true;
        setIsPasswordNotValid(true)
      } 
      else {
        pass.error = false
        setIsPasswordNotValid(false)
      }
    })
    setPasswords([...passwords])
    setMismatch(passwords[0].value !== passwords[1].value)
  }

  const [isSubmitClickedOnce, changeSubmitClickedOnce] = useState(false);
  const handleSubmit = async (event, errors, values) =>{
    validation();
    changeSubmitClickedOnce(true);
    let check = false;
    passwords.map((pass)=>{
      if(pass.error == true) check = true;
    })
    if(passwords[0].value != passwords[1].value) check = true ;
    if(check) console.log('submitError')
    else {
      const fromEmail = query.get("token") ? 1 : 0
      const token = query.get("token") || getCookieValue('access_token')
      const pwsrd = passwords[0].value

      try {
        const doesRegistered = (await props.changePassword(pwsrd, token, fromEmail)).message.includes('Successfully updated Password');
        setTimeout(function(){
          if (doesRegistered) history.push('/')
        }, 2000);
        
      } catch (error) {
        const errorMsg = error.response.data.message || "Something went wrong!"

        if(errorMsg.includes("Token")){
          setTokenInvalid(true)
          setTimeout(function(){
            setTokenInvalid(null)
          }, 1500);
        }
      }
    }
}

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>
      <div className="login-height my-5 pt-sm-5 bggg-bg">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="login-bg bg-soft">
                  <Row>
                    <Col className="col-7">
                      <div className="login-text p-4">
                        <h5 className="login-text">Verander wachtwoord</h5>
                        {/* <p>Sign in to continue to Skote.</p> */}
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="auth-logo">
                  </div>
                  <div className="p-2 pt-4">
                    <AvForm className="form-horizontal" onSubmit={handleSubmit}>
                      {passwords.map((pass, index)=>(
                         <div className="mb-3 position-relative">
                         <label
                           style={{color: pass.error? '#dc3545':'#212529'}}
                         >
                          {pass.label}
                         </label>
                           <input
                             id={index}
                             name="password"
                             label="Wachtwoord"
                             value={pass.value}
                             className='form-control shadow-none'
                             onChange={handleChange}
                             type={pass.eye?'text':'password'}
                             style={{borderColor: pass.error? '#dc3545':'rgb(206, 212, 218)'}}
                             required
                             placeholder="Wachtwoord"
                           />
                             <i 
                               className={`far ${pass.eye?'fa-eye-slash':'fa-eye'} icon-auth password-icon-register`}
                               onClick={()=>handleEye(index)}
                               style={{color: pass.error? "#dc3545":"#43B02A"}}
                             />
                           
                           {pass.error &&  
                             <div>
                               {/* <Validation_Icon /> */}
                               <div className="invalid-field-label">
                                  U hebt een ongeldige waarde ingevoerd voor dit veld
                                </div>
                             </div>
                           }
                         </div>   
                      ))}
                      {mismatch && !isPasswordNotValid &&
                        <div className="invalid-field-label">
                          De door u ingevulde wachtwoorden komen niet overeen
                        </div>
                      }
                      {tokenInvalid && (
                        <div className="invalid-field-label">
                          Token is niet correct of verlopen
                        </div>
                        )
                      }
                      {isPasswordNotValid &&  
                      <div>
                        {/* <Validation_Icon /> */}
                        <div className="invalid-field-label">
                          {passwordValidateText}
                        </div>
                      </div>
                    }

                    <div className="mt-3 d-grid">
                      <button
                        className="btn btn-block login-btn shadow-none"
                        type="submit"
                      >
                        Indienen
                      </button>
                    </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <SuccessMessage  success = {props.loader.showSuccessMsg} msg={props.loader.successMsg} />
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = ({auth,loader}) => ({auth,loader})
export default connect(mapStateToProps, {userRegistration, activeView, changePassword}) ( ChangePassword );
