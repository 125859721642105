import React, { useState, useEffect } from 'react';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";
import simulationService from "../../services/simulationService";
import { activeView } from '../../redux/actions/auth';

import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { ROUTES } from "../../constants/routes";
import { getRenovationPlans, setSimulationParams } from "../../redux/actions/simulation";
import { FillButton } from "../../helper/buttons";
import { getContentByModuleLocation } from '../../redux/actions/content'
import AlertMessage from "../../helper/otherMessages";
import {
    addSimulation,
    deleteSimulation,
    removeAllSimulations,
    updateNewSimulationParam,
    setIsDisableSimulationRerun,
    updateSimulationId,
    updateSimulationName
} from "../../redux/actions/advancedMap";
import { setSearchLevel, setSelectedArea } from "../../redux/actions/map";
import { SIMULATION_STATUS } from "../../redux/constants/constants";
import { SEARCH_LEVELS } from "../../constants/map";
import { BUILDING_TYPES } from '../../constants/simulation';
import { getKeyAgainstValue } from '../../helper/generalHelpers';

const ITEM_HEIGHT = 48;

let simulationHeader = []

const SimulationOverview = (props) => {
    const [simulations, setSimulations] = useState([])
    const [anchorEls, setAnchorEls] = useState({})
    const [headerContent, setHeaderContent] = useState(undefined);
    const [simulationAction, setSimulationAction] = useState(undefined);
    const [showMapPagePopup, setShowMapPagePopup] = useState(false);
    const [mapPagePopupMessage, setMapPagePopupMessage] = useState('');
    const [mapPagePopupType, setMapPagePopupType] = useState('info');

    const history = useHistory()
    const dispatch = useDispatch()

    const { renovationPlans, id: simulationId } = useSelector(state => state.simulation)

    const handleDeleteClick = React.useCallback( async (row) => {
        await handleDeleteSimulation(row.id)
        await gettingAllSimulations();
        handleClose()

        // when active simulation (present in simulation page) is deleted
        if(simulationId === row.id){    
            dispatch(updateSimulationId(null))
            dispatch(updateSimulationName(null))
            dispatch(removeAllSimulations())
            // dispatch(setSearchLevel(SEARCH_LEVELS.MUNICIPALITY))
            dispatch(setSelectedArea({
                municipalityName: '',
                municipalityId: ''
            }))
            dispatch(setIsDisableSimulationRerun(true))
        }
    }, [simulationId, dispatch])

    const options = [
        {
            name: "Edit",
            handler: (row) => {
                handleClose()
                dispatch(removeAllSimulations())
                props.activeView(1)
                dispatch(updateSimulationId(row.id))
                dispatch(updateSimulationName(row.name))
                row.params.forEach(param => {
                    dispatch(addSimulation({
                        param: {
                            typology: param.building_typology,
                            renovation_plan: param.renovation_plan,
                        },
                        status: SIMULATION_STATUS.PARAM_ADDED
                    }))
                })
                dispatch(setSearchLevel(SEARCH_LEVELS.MUNICIPALITY))
                dispatch(setSelectedArea({
                    municipalityName: row.municipality,
                    municipalityId: row.municipality_id
                }))
                dispatch(setIsDisableSimulationRerun(false))
                history.push(ROUTES.MAP_PAGE_SIMULATION)
            }
        },
        {
            name: "Delete",
            handler: async (row) => {
                if (window.confirm('Are you sure you wish to delete this item?')) handleDeleteClick(row)
            }
        }
    ];

    const showPopupOnMap = (text, type = "info") => {
        setMapPagePopupMessage(text);
        setMapPagePopupType(type);
        setShowMapPagePopup(true);
        setTimeout(() => {
            setShowMapPagePopup(false);
        }, 2500);
    };

    const handleDeleteSimulation = async (simulationId) => {
        const deleteRes = await simulationService.deleteSimulation(simulationId)
        if (!deleteRes?.errors?.length) {
            showPopupOnMap('Simulation deleted successfully', 'success')
        }
        else {
            showPopupOnMap(deleteRes?.errors[0], 'error')
        }
    }

    const handleEditSimulation = async (simulationId) => {
        const deleteRes = await simulationService.deleteSimulation(simulationId)
        if (!deleteRes?.errors?.length) {
            showPopupOnMap('Simulation deleted successfully', 'success')
        }
        else {
            showPopupOnMap(deleteRes?.errors[0], 'error')
        }
    }

    async function gettingAllSimulations() {
        try {
            const allSimulationsRes = await simulationService.getUserSimulations()
            if (!allSimulationsRes.errors.length) {
                setSimulations(allSimulationsRes.data)
                makeAnchorEls(allSimulationsRes.data)
            }
        } catch (err) {
            console.log(err)
        }
    }
    useEffect(async () => {
        await gettingAllSimulations();
        let data = await props.getContentByModuleLocation('simulations');
        setHeaderContent(data?.tableHeadings?.attributes);
        setSimulationAction(data?.simulationActions?.attributes)

        // fetching renovation plans
        if (renovationPlans?.length === 0) {
            dispatch(getRenovationPlans())
        }
    }, [])

    // let simulationHeader = ["Naam van de simulatie", "Creatiedatum", "Datum van de laatste wijziging", "Status", "Acties"]

    useEffect(() => {
        simulationHeader = [
            headerContent?.simulationName,
            headerContent?.simulationMunicipality || 'Gemeente',
            headerContent?.simulationParams || 'Parameters',
            headerContent?.simulationCreatedDate,
            headerContent?.simulationModificationDate,
            headerContent?.simulationAction
        ];
    }, [headerContent])
    const makeAnchorEls = (sims) => {
        let localAnchorEls = {}
        for (let sim of sims) {
            localAnchorEls[sim.id] = { open: false, el: null }
        }
        setAnchorEls(localAnchorEls)
    }

    const handleClick = (event, id) => {
        let localAnchorEls = { ...anchorEls }
        localAnchorEls[id] = { el: event.currentTarget, open: Boolean(event.currentTarget) }
        setAnchorEls(localAnchorEls)
    };

    const handleClose = () => {
        let localAnchorEls = { ...anchorEls }
        for (let key of Object.keys(anchorEls)) {
            localAnchorEls[key].el = null
            localAnchorEls[key].open = false
        }
        setAnchorEls(localAnchorEls)
    };

    return (
        <div className='overview-container'>
            <div className='bg-images'>
                <div style={{ opacity: 1 }}>
                    <div className="simulation-page">
                        <h2>Simulatieoverzicht</h2>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        {simulationHeader.map(item => {
                                            return (<TableCell key={item} align="center">
                                                {item}
                                            </TableCell>)
                                        })}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {(simulations.length && Object.keys(anchorEls).length) ? simulations.map((simRow) => (
                                        <TableRow
                                            key={simRow.id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            {/* <TableCell align="center">
                    {simRow.id}
                  </TableCell> */}
                                            <TableCell align="center">{simRow.name}</TableCell>
                                            <TableCell align="center">{simRow.municipality}</TableCell>
                                            <TableCell align="center">
                                                {
                                                    simRow.params.map(param => {
                                                        return (
                                                            <p
                                                                key={param.building_typology}
                                                            >
                                                                <strong>
                                                                    {BUILDING_TYPES[param.building_typology]}
                                                                </strong>: {
                                                                        getKeyAgainstValue(param.renovation_plan, renovationPlans || {})
                                                                        || param.renovation_plan
                                                                    }
                                                            </p>
                                                        )
                                                    })
                                                }
                                            </TableCell>
                                            <TableCell align="center">{new Date(simRow.created_at).toLocaleString("es-ES")}</TableCell>
                                            <TableCell align="center">{new Date(simRow.updated_at).toLocaleString("es-ES")}</TableCell>
                                            <TableCell align="center">
                                                <IconButton
                                                    aria-label="more"
                                                    id="long-button"
                                                    aria-controls="long-menu"
                                                    aria-expanded={open ? 'true' : undefined}
                                                    aria-haspopup="true"
                                                    onClick={(event) => handleClick(event, simRow.id)}
                                                >
                                                    <MoreVertIcon />
                                                </IconButton>
                                                <Menu
                                                    id="long-menu"
                                                    MenuListProps={{
                                                        'aria-labelledby': 'long-button',
                                                    }}
                                                    anchorEl={anchorEls[simRow.id].el}
                                                    open={anchorEls[simRow.id].open}
                                                    onClose={handleClose}
                                                    PaperProps={{
                                                        style: {
                                                            maxHeight: ITEM_HEIGHT * 4.5,
                                                            width: '20ch',
                                                        },
                                                    }}
                                                >
                                                    {options.map((option) => (
                                                        <MenuItem key={option.name} onClick={() => option.handler(simRow)}>
                                                            {option.name}
                                                        </MenuItem>
                                                    ))}
                                                </Menu>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                        :
                                        (<div>
                                        </div>)}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        {!simulations.length &&
                            <div className="bg-orange rounded d-flex justify-content-center align-content-center">
                                <span className="text-primary text-small">
                                    {simulationAction?.noSavedSimulationText}
                                </span>
                            </div>}
                        <div className="mt-4 d-flex justify-content-center align-content-center">
                            <FillButton
                                name={simulationAction?.newSimulationButton}
                                extra_classes='new-sim-button'
                                onClickHandler={() => {
                                    dispatch(removeAllSimulations())
                                    dispatch(setSelectedArea({
                                        addressId: "",
                                        houseNo: "",
                                        streetId: "",
                                        streetName: "",
                                        municipalityName: "",
                                        municipalityId: "",
                                        postCode: "",
                                        level: "",
                                    }))
                                    props.activeView(1)
                                    history.push(ROUTES.MAP_PAGE_SIMULATION)
                                }}
                            />
                        </div>
                        <AlertMessage
                            show={showMapPagePopup}
                            message={mapPagePopupMessage}
                            type={mapPagePopupType}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ main, loader }) => ({ main, loader })
export default connect(mapStateToProps, { activeView, getContentByModuleLocation })(withRouter(SimulationOverview));