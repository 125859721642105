import { Box, Stack, Typography, styled } from "@mui/material";

import { resultContents } from "../../constants/simulation";
import ResultHeader from "./ResultHeader";
import { SIMULATION_STATUS } from "../../redux/constants/constants";
import { dutchFormatNumber, getKeyAgainstValue } from "../../helper/generalHelpers";
import { useSelector } from "react-redux";
import { isCurrencyUnit } from "../../helper/simulation";

const commonStyles = {
    background: "white",
    boxShadow: "0px 2px 5px 0px #00000035",
}

const ResultContainer = styled(Box)({
    width: "250px",
})

const ContentRow = ({ icon, unit, text }) => (
    <Stack gap={1} direction="row" alignItems="center">
        <img height={25} width={25} src={icon} alt={unit} />
        <Typography fontSize="11px" color="#66b654" fontWeight="bold">{text}</Typography>
    </Stack>
)

const ResultContentRow = ({ type, text: price, unit }) => {
    const icon = resultContents[type]?.icon || ""
    const extraText = resultContents[type]?.extraText || null

    const formattedPrice = Number(price)?.toLocaleString("es-ES", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    }) || null

    const fullText = isCurrencyUnit(unit) ?
        [unit, formattedPrice, extraText].join(" ")
        :
        [formattedPrice, unit, extraText].join(" ")

    return <ContentRow icon={icon} unit={unit} text={fullText} />
}

const ResultLoading = () => <Box>Running Simulation...</Box>
const ErrorInSimulation = ({ error = "Something went wrong." }) => <Typography color="red" fontSize="12px">{error}</Typography>

const ResultContent = ({ simulation, count = null, showCount = true, showCloseButton = true, sx = {} }) => {
    const {
        result = null,
        error = null,
        status = null,
        param = {},
    } = simulation

    let {
        typology = null,
        renovation_plan: renovationPlan = null
    } = param || {}

    const { renovationPlans } = useSelector(state => state.simulation)
    renovationPlan = getKeyAgainstValue(renovationPlan, renovationPlans)

    return (
        <Box height="100%" sx={{ ...commonStyles, ...sx }}>
            <ResultHeader typology={typology} renovationPlan={renovationPlan} count={count} showCloseButton={showCloseButton} showCount={showCount} />
            <Box sx={{ borderBottom: "1px solid #c5c5c5", marginY: "3px" }} />
            <Stack gap={0.5} sx={{ paddingBottom: "6px", paddingX: "10px", minHeight: "100px" }}>
                {status === SIMULATION_STATUS.LOADING ? <ResultLoading /> : null}
                {(error && status === SIMULATION_STATUS.ERROR) ? <ErrorInSimulation error={error} /> : null}
                {(result && status === SIMULATION_STATUS.COMPLETE) ? (
                    Object.keys(resultContents).map((key) => {
                        const _result = result[key]
                        return <ResultContentRow type={key} key={key} text={_result?.value} unit={_result?.unit} />
                    }))
                    : null}
            </Stack>
        </Box>
    )
}

const Result = (props) => <ResultContainer className="sidepanel-panel"><ResultContent {...props} /></ResultContainer>

export default Result
export {
    ResultContent,
    ContentRow,
}
