import closeIcon from "../../assets/used/closeIcon.png"


export const CloseableCard = ({
    onClose= ()=> {},
    classes="",
    headerClasses= "",
    title= "",
    width="60",
    height="80",
    children
                              })=> {
    return (
        <div
            className={`${classes} bg-white p-3`}
            style={{width: `${width}%`, height: `${height}%`,borderRadius:10}}
        >
            <div className={`${headerClasses} d-flex`}>
                <h4 className="width-90pc">
                    {title}
                </h4>
                <div
                    className="width-10pc d-inline-flex justify-content-end"
                >
                    <button className="bg-transparent border-0">
                        <img
                            className='ml-5'
                            src={closeIcon}
                            onClick={onClose}
                            alt="CloseIcon"
                            width="25px"
                            height="25px"
                        >
                        </img>
                    </button>
                </div>
            </div>
            {children}
        </div>
    )
}