import {
    ADD_SIMULATION,
    DELETE_SIMULATION,
    SIMULATION_STATUS,
    UPDATE_SIMULATION_STATUS,
    RUN_SIMULATIONS,
    UPDATE_NEW_SIMULATION_PARAM,
    UPDATE_SIMULATIONS_STATUS,
    ON_SUCCESS_SIMULATION_API,
    ON_ERROR_SIMULATION_API,
    ON_CHANGE_SIMULATION_STATE,
    ON_CHANGE_MUNICIPALITY_WFS_DATA,
    UPDATE_SIMULATIONS_NAME,
    ON_CHANGE_SIMULATION_ID,
    REMOVE_ALL_SIMULATIONS,
    CHECK_RERUN_SIMULATION,
    SET_IS_DISABLE_SIMULATION_RERUN
} from "../constants/constants"
import AdvancedMapService from "../../services/advancedMap"
import { makePayloadForSimulationAPI } from "../../helper/mapHelpers";


export const addSimulation = (params= {}) => {
    const defaultValuesForNewSimulation = {
        loading: null,
        error: null,
        param: null,
        result: null,
        status: SIMULATION_STATUS.NEW,
    }

    return {
        type: ADD_SIMULATION,
        payload: {
            ...defaultValuesForNewSimulation,
            ...params
        }
    }
}

export const updateSimulationName = (name)=> {
    return {
        type: UPDATE_SIMULATIONS_NAME,
        payload: {
            name
        }
    }
}

export const updateSimulationId = (id)=> {
    return {
        type: ON_CHANGE_SIMULATION_ID,
        payload: {
            id
        }
    }
}

export const removeAllSimulations = () => {
    return {
        type: REMOVE_ALL_SIMULATIONS,
    }
}

export const deleteSimulation = (simulationIndex) => {
    return {
        type: DELETE_SIMULATION,
        payload: simulationIndex
    }
}

export const checkRerunSimulation = (simulationIndex) => { 
    return {
        type: CHECK_RERUN_SIMULATION,
        payload: { currentDeletedSimulation: simulationIndex }
    }
}

export const setIsDisableSimulationRerun = (value) => {
    return {
        type: SET_IS_DISABLE_SIMULATION_RERUN,
        payload: { value }
    }
}

export const updateSimulationStatus = (status) => {
    if (![Object.values(SIMULATION_STATUS)].includes(status)) {
        console.error("----Wrong simulation status provided.--------")
        return {}
    }

    return {
        type: UPDATE_SIMULATION_STATUS,
        payload: status
    }
}

export const updateSimulationsStatus = (params, newStatus) => {
    return {
        type: UPDATE_SIMULATIONS_STATUS,
        payload: {
            params: params,
            newStatus
        }
    }
}

export const updateNewSimulationParam = (activeCategoryTitles, selectedPlan) => {

    return {
        type: UPDATE_NEW_SIMULATION_PARAM,
        payload: {
            types: activeCategoryTitles,
            plan: selectedPlan
        }
    }
}

const onSuccessSimulationAPI = (data) => {
    return {
        type: ON_SUCCESS_SIMULATION_API,
        payload: { data },
    }
}

const onErrorSimulationAPI = (onlyParams, response) => {
    return {
        type: ON_ERROR_SIMULATION_API,
        payload: { onlyParams, response },
    }
}

export const onChangeSimulationVisualizationState = (clickState) => {
    return {
        type: ON_CHANGE_SIMULATION_STATE,
        payload: { clickState },
    }
}

export const onChangeMunicipalityWFSData = (wfsData) => {
    return {
        type: ON_CHANGE_MUNICIPALITY_WFS_DATA,
        payload: { wfsData },
    }
}

const timeout = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms))
}

export const runSimulations = (payload) => {
    const { runSimulationCallBack } = payload

    return async (dispatch, getState) => {
        const { simulation: simulations, map: { selectedArea: { municipalityName } } } = getState()

        const filterStatus = [
            SIMULATION_STATUS.PARAM_ADDED,
            SIMULATION_STATUS.COMPLETE,
            SIMULATION_STATUS.ERROR,
            SIMULATION_STATUS.LOADING,
        ]

        const paramUpdatedSimulations = simulations?.data?.filter((simulation) => filterStatus.includes(simulation?.status))
        const payload = makePayloadForSimulationAPI(paramUpdatedSimulations, municipalityName)

        const onlyParams = []
        paramUpdatedSimulations.map((simulation) => {
            onlyParams.push(simulation?.param)
        })

        // while loading
        dispatch(updateSimulationsStatus(onlyParams, SIMULATION_STATUS.LOADING))

        try {
            const { data } = await AdvancedMapService.runSimulation(payload)
            const { errors = [] } = data
            runSimulationCallBack()
            if (errors && errors?.length > 0) {
                dispatch(onErrorSimulationAPI(onlyParams, data))
            } else {
                dispatch(onSuccessSimulationAPI(data))
                dispatch(updateSimulationsStatus(onlyParams, SIMULATION_STATUS.COMPLETE))
                dispatch(setIsDisableSimulationRerun(false))
            }
        } catch {
            dispatch(updateSimulationsStatus(onlyParams, SIMULATION_STATUS.ERROR))
        }
    }
}

