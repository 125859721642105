import React from "react";
import Joyride from "react-joyride";
import { useDispatch, useSelector } from "react-redux";
import { useMount } from "react-use"
import { addSimulation, removeAllSimulations } from "../../redux/actions/advancedMap";
import { setStoreState } from "../../redux/actions/productDemo";
import { steps } from "./steps";

const ProductDemo = () => {
    const { productDemo: productDemoState } = useSelector(s => s)
    const { municipalityName = null } = useSelector(state => state?.map?.selectedArea)
    const dispatch = useDispatch()

    const isMunicipility = React.useCallback(() => {
        return !["", null, undefined].includes(municipalityName)
    }, [municipalityName])

    const callbackHandler = (data) => {
        const { action, index, type, step, status } = data

        // console.log("isMunicipility", isMunicipility())
        // console.log(data)

        if (type === "tour:end" && (action === "next" || action === "close") && status === "finished") {
            dispatch(setStoreState({
                run: false,
                stepIndex: -1,
            }))
            // remove all simulations
            if(!isMunicipility()){
                dispatch(removeAllSimulations())
            }
        } else if (type === "step:after" && step.target === "#municipility-search") {
            // add new simulation
            if(!isMunicipility()){
                dispatch(addSimulation())
            }
            setTimeout(() => {
                dispatch(setStoreState({
                    stepIndex: 1,
                }))
            }, 0)
            // assuming there is no previous button
        } else if (type === "step:after" && index > 0) {
            // console.log("HERE!!!", data)
            dispatch(setStoreState({
                stepIndex: index + 1,
            }))
            localStorage.setItem("showProductDemo", 0)
        }
    }

    useMount(() => {
        dispatch(setStoreState({ steps }))

        const defaultBehavour = 1
        const showAutoDemo = localStorage.getItem("showProductDemo") || defaultBehavour

        if (parseInt(showAutoDemo)) {
            dispatch(setStoreState({
                run: true,
                stepIndex: 0,
            }))
        }
    })

    return (
        <Joyride
            callback={callbackHandler}
            continuous={true}
            steps={productDemoState?.steps || []}
            run={productDemoState?.run || false}
            stepIndex={productDemoState?.stepIndex}
            hideCloseButton={true}
            showProgress={false}
            showSkipButton={false}
            disableScrolling={true}
            styles={{
                buttonNext: {
                    display: "none",
                },
                buttonBack: {
                    display: "none",
                },
                tooltip: {
                    padding: 0,
                },
                options: {
                    arrowColor: "transparent",
                    backgroundColor: "white",
                    primaryColor: "#44b02b",
                    width: "30rem",
                },
                spotlight: {
                    borderRadius: "8px",
                    height: "min-content"
                },
                tooltipContent: {
                    padding: "10px",
                }
            }}
        />
    )
}

export default ProductDemo
